import React, { useState, useEffect, useMemo, useCallback } from "react";
import { ArrowDown, Star, Search, ArrowUpDown } from "lucide-react";
import ReactGA from "react-ga4";
import ShareModal from "./ShareModal.js";
import BehaviorItem from "./BehaviorItem";
import axios from "axios";
import HeroSection from "./HeroSection.js";
import { allBehaviors } from "../data/all-behaviors.js";

const BadBehaviorsList = () => {
  const [behaviors, setBehaviors] = useState([]);
  const [displayedCount, setDisplayedCount] = useState(75);
  const [shuffledBehaviors, setShuffledBehaviors] = useState([]);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [shareError, setShareError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [currentBehavior, setCurrentBehavior] = useState("");
  const [activeCategory, setActiveCategory] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const [sortOption, setSortOption] = useState("top_upvotes");
  const [votingStates, setVotingStates] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [favorites, setFavorites] = useState(() => {
    try {
      const savedFavorites = localStorage.getItem("favoriteBehaviors");
      console.log(savedFavorites);
      return savedFavorites ? JSON.parse(savedFavorites) : [];
    } catch (error) {
      console.error("Error reading favorites from localStorage:", error);
      return [];
    }
  });
  const [checkedItems, setCheckedItems] = useState(() => {
    try {
      const savedChecked = localStorage.getItem("checkedBehaviors");
      return savedChecked ? JSON.parse(savedChecked) : {};
    } catch (error) {
      console.error("Error reading from localStorage:", error);
      return {};
    }
  });

  const fetchBehaviors = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        "https://hargawiki-backend.onrender.com/api/behaviors"
      );
      // setBehaviors(response.data);
      // setShuffledBehaviors([...response.data].sort(() => Math.random() - 0.5));
    } catch (error) {
      console.error("Error fetching behaviors:", error);
      // setError("Failed to load behaviors. Please try again later.");
    } finally {
      setBehaviors(allBehaviors);
      // setShuffledBehaviors(allBehaviors.sort(() => Math.random() - 0.5));
      setLoading(false);
    }
  }, []);

  const generateShareImage = useCallback(
    async (text) => {
      try {
        setIsGeneratingImage(true);
        setShareError("");

        const notoKufiFont = new FontFace(
          "Noto Kufi Arabic",
          "url(https://fonts.gstatic.com/s/notokufiarabic/v21/CSRk4ydQnPyaDxEXLFF6LZVLKrodrOYFFlKp.woff2)",
          { weight: "400" }
        );

        const notoKufiBoldFont = new FontFace(
          "Noto Kufi Arabic",
          "url(https://fonts.gstatic.com/s/notokufiarabic/v21/CSRk4ydQnPyaDxEXLFF6LZVLKrodrOYFFlKp.woff2)",
          { weight: "700" }
        );

        await Promise.all([notoKufiFont.load(), notoKufiBoldFont.load()]);

        document.fonts.add(notoKufiFont);
        document.fonts.add(notoKufiBoldFont);

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 1080;
        canvas.height = 1080;

        const gradient = ctx.createLinearGradient(
          0,
          0,
          canvas.width,
          canvas.height
        );
        gradient.addColorStop(0, "#000000");
        gradient.addColorStop(0.5, "#1a1a1a");
        gradient.addColorStop(1, "#000000");
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < canvas.width; i += 40) {
          for (let j = 0; j < canvas.height; j += 40) {
            if (Math.random() > 0.5) {
              ctx.fillStyle = "rgba(255, 215, 0, 0.02)";
              ctx.fillRect(i, j, 2, 2);
            }
          }
        }

        const cornerSize = 150;

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(cornerSize, 0);
        ctx.lineTo(0, cornerSize);
        ctx.closePath();
        ctx.fillStyle = "rgba(255, 215, 0, 0.1)";
        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(canvas.width, canvas.height);
        ctx.lineTo(canvas.width - cornerSize, canvas.height);
        ctx.lineTo(canvas.width, canvas.height - cornerSize);
        ctx.closePath();
        ctx.fillStyle = "rgba(255, 215, 0, 0.1)";
        ctx.fill();

        const contentPadding = 60;
        ctx.strokeStyle = "#FFD700";
        ctx.lineWidth = 3;
        ctx.strokeRect(
          contentPadding,
          contentPadding,
          canvas.width - contentPadding * 2,
          canvas.height - contentPadding * 2
        );

        const dotRadius = 12;
        const cornerOffset = 60;
        const corners = [
          [cornerOffset, cornerOffset],
          [canvas.width - cornerOffset, cornerOffset],
          [cornerOffset, canvas.height - cornerOffset],
          [canvas.width - cornerOffset, canvas.height - cornerOffset],
        ];

        corners.forEach(([x, y]) => {
          ctx.fillStyle = "#FFD700";
          ctx.beginPath();
          ctx.arc(x, y, dotRadius, 0, Math.PI * 2);
          ctx.fill();
        });

        ctx.fillStyle = "#FFD700";
        ctx.textAlign = "center";
        ctx.direction = "rtl";
        ctx.textBaseline = "middle";

        const wrapText = (context, text, maxWidth) => {
          const words = text.split(" ").reverse();
          const lines = [];
          let currentLine = words[0];

          for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const width = context.measureText(word + " " + currentLine).width;

            if (width < maxWidth) {
              currentLine = word + " " + currentLine;
            } else {
              lines.push(currentLine);
              currentLine = word;
            }
          }
          lines.push(currentLine);
          return lines.reverse();
        };

        ctx.font = 'bold 180px "Noto Kufi Arabic"';
        ctx.fillStyle = "rgba(255, 215, 0, 0.15)";
        // Using Arabic quotation marks
        ctx.fillText("❝", 180, canvas.height / 2 - 180);
        ctx.fillText("❞", canvas.width - 180, canvas.height / 2 + 300);

        ctx.font = 'bold 56px "Noto Kufi Arabic"';
        ctx.fillStyle = "#FFD700";
        const maxWidth = canvas.width - contentPadding * 4;
        const lines = wrapText(ctx, text, maxWidth);
        const lineHeight = 90;
        const totalTextHeight = lines.length * lineHeight;
        const startY = (canvas.height - totalTextHeight) / 2;

        ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
        ctx.shadowBlur = 15;
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;

        lines.forEach((line, index) => {
          ctx.fillText(line, canvas.width / 2, startY + index * lineHeight);
        });

        ctx.shadowColor = "transparent";
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;

        const bottomBarHeight = 80;
        const bottomBarY = canvas.height - bottomBarHeight - contentPadding;

        ctx.fillStyle = "rgba(255, 215, 0, 0.1)";
        ctx.fillRect(
          contentPadding,
          bottomBarY,
          canvas.width - contentPadding * 2,
          bottomBarHeight
        );

        ctx.font = 'bold 32px "Noto Kufi Arabic"';
        ctx.fillStyle = "#FFD700";
        ctx.fillText(
          // "www.HargaWiki.ma   |   #ماتـكونـش_هرڭاوي",
          "#ماتـكونـش_هرڭاوي   |   www.HargaWiki.ma",
          canvas.width / 2,
          bottomBarY + bottomBarHeight / 2
        );

        const blob = await new Promise((resolve) => {
          canvas.toBlob(resolve, "image/png");
        });

        return blob;
      } catch (error) {
        console.error("Image generation failed:", error);
        throw new Error("Failed to generate shareable image");
      } finally {
        setIsGeneratingImage(false);
      }
    },
    [setIsGeneratingImage]
  );

  const filteredBehaviors = useMemo(() => {
    let result = shuffledBehaviors.filter((item) => {
      if (showOnlyFavorites && !favorites.includes(item.id)) return false;
      if (
        !showOnlyFavorites &&
        activeCategory !== "all" &&
        !item.category.includes(activeCategory)
      )
        return false;
      if (
        searchQuery &&
        !item.behavior.toLowerCase().includes(searchQuery.toLowerCase().trim())
      )
        return false;
      return true;
    });

    switch (sortOption) {
      case "old":
        result.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      case "top_upvotes":
        result.sort((a, b) => {
          const aUpvotes = votingStates[a.id]?.upvotes || a.upvotes || 0;
          const bUpvotes = votingStates[b.id]?.upvotes || b.upvotes || 0;
          return bUpvotes - aUpvotes;
        });
        break;
      case "top_downvotes":
        result.sort((a, b) => {
          const aDownvotes = votingStates[a.id]?.downvotes || a.downvotes || 0;
          const bDownvotes = votingStates[b.id]?.downvotes || b.downvotes || 0;
          return bDownvotes - aDownvotes;
        });
        break;
      default:
        result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    return result;
  }, [
    shuffledBehaviors,
    showOnlyFavorites,
    activeCategory,
    searchQuery,
    sortOption,
    votingStates,
    favorites,
  ]);

  useEffect(() => {
    try {
      localStorage.setItem("favoriteBehaviors", JSON.stringify(favorites));
    } catch (error) {
      console.error("Error writing favorites to localStorage:", error);
    }
  }, [favorites]);

  useEffect(() => {
    fetchBehaviors();
  }, [fetchBehaviors]);

  useEffect(() => {
    if (behaviors.length > 0) {
      const shuffled = [...behaviors].sort(() => Math.random() - 0.5);
      setShuffledBehaviors(shuffled);
    }
  }, [behaviors]);

  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!showOnlyFavorites) {
      setActiveCategory("all");
    }
  }, [showOnlyFavorites]);

  useEffect(() => {
    try {
      localStorage.setItem("checkedBehaviors", JSON.stringify(checkedItems));
    } catch (error) {
      console.error("Error writing to localStorage:", error);
    }
  }, [checkedItems]);

  const trackCheckClicks = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Bulbe Button",
      label: "Bulbe Button Click",
    });
  };

  const handleCheck = (id) => {
    trackCheckClicks();
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleFavorite = (id) => {
    handleFavoriteClick();
    setFavorites((prev) => {
      if (prev.includes(id)) {
        return prev.filter((favId) => favId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setShowOnlyFavorites(false);
  };

  const handleFavoritesToggle = () => {
    setShowOnlyFavorites(!showOnlyFavorites);
    setActiveCategory(null);
  };

  const handleLoadMore = () => {
    setDisplayedCount((prev) => Math.min(prev + 20, filteredBehaviors.length));
  };

  const trackPreviewClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Preview Button",
      label: "Preview Button Click",
    });
  };

  const handleShare = async (behavior) => {
    trackPreviewClick();
    try {
      const imageBlob = await generateShareImage(`"${behavior}"`);

      if (!imageBlob) {
        throw new Error("Failed to generate image");
      }

      const imageUrl = URL.createObjectURL(imageBlob);
      setCurrentImageUrl(imageUrl);
      setCurrentBehavior(behavior);
      setIsModalOpen(true);

      return () => URL.revokeObjectURL(imageUrl);
    } catch (err) {
      console.error("Image generation failed:", err);
      setShareError("Failed to generate image. Please try again.");
      setTimeout(() => setShareError(""), 3000);
    }
  };

  const handleFavoriteClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Favorite Bad Behavior Button",
      label: "Favorite Bad Behavior",
    });
  };

  if (error) {
    return (
      <div className="text-center py-8 text-red-500">
        <p>{error}</p>
        <button
          onClick={fetchBehaviors}
          className="mt-4 px-4 py-2 bg-yellow-400 text-black rounded"
        >
          Retry Loading
        </button>
      </div>
    );
  }

  return (
    <div>

      {/* Hero Section */}
      <HeroSection />
      <div className="max-w-3xl mx-auto">

        {/* Category Filter Buttons */}
        <div className="flex flex-wrap gap-2 justify-center mb-4">
          <button
            onClick={handleFavoritesToggle}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm flex items-center gap-2 ${
              showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            <Star
              className={`w-5 h-5 ${showOnlyFavorites ? "fill-black" : ""}`}
            />
          </button>
          <button
            onClick={() => handleCategoryChange("all")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "all" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            الكل
          </button>
          <button
            onClick={() => handleCategoryChange("street")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "street" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            الشارع
          </button>
          <button
            onClick={() => handleCategoryChange("public")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "public" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            الأماكن العامة
          </button>
          <button
            onClick={() => handleCategoryChange("driving")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "driving" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            قانون السير
          </button>
          <button
            onClick={() => handleCategoryChange("social")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "social" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            المجتمع
          </button>
          <button
            onClick={() => handleCategoryChange("digital")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "digital" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            الأنترنيت
          </button>
          <button
            onClick={() => handleCategoryChange("work")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "work" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            العمل
          </button>
          <button
            onClick={() => handleCategoryChange("school")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "school" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            المدرسة
          </button>
          <button
            onClick={() => handleCategoryChange("environment")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "environment" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            البيئة
          </button>
          <button
            onClick={() => handleCategoryChange("home")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "home" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            البيت
          </button>
          <button
            onClick={() => handleCategoryChange("health")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "health" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            الصحة
          </button>
          {/* <button
            onClick={() => handleCategoryChange("cleanness")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "cleanness" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            النظافة
          </button> */}
          {/* <button
            onClick={() => handleCategoryChange("religion")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "religion" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            الدين
          </button> */}
          <button
            onClick={() => handleCategoryChange("children")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "children" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            التربية
          </button>
          <button
            onClick={() => handleCategoryChange("animals")}
            className={`px-4 py-2 rounded-full border border-yellow-400 transition-colors text-sm ${
              activeCategory === "animals" && !showOnlyFavorites
                ? "bg-yellow-400 text-black"
                : "hover:bg-yellow-400 hover:text-black"
            }`}
          >
            الحيوانات
          </button>
        </div>

        {/* Search Bar */}
        <div className="max-w-3xl mx-auto mb-4">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="ابحث عن سلوك معين..."
              className="w-full bg-black border border-yellow-400 rounded-lg p-4 pl-12 text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400 placeholder-yellow-400/50"
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-yellow-400" />
          </div>
        </div>

        {/* New Sorting Dropdown */}
        <div className="relative mb-4">
          <select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            className="w-full bg-black border border-yellow-400 rounded-lg p-2 pl-12 text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400 appearance-none"
          >
            {/* <option value="random">عشوائي</option> */}
            <option value="new">الأحدث</option>
            <option value="old">الأقدم</option>
            <option value="top_upvotes">أعلى الإعجابات</option>
            <option value="top_downvotes">أعلى عدم الإعجاب</option>
          </select>
          <ArrowUpDown className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-yellow-400" />
        </div>

        {/* Show search results count if searching */}
        {searchQuery && (
          <div className="text-center mb-6 text-yellow-400/75">
            {filteredBehaviors.length === 0
              ? ""
              : `تم العثور على ${filteredBehaviors.length} ${
                  filteredBehaviors.length === 1 ? "سلوك" : "سلوكيات"
                }`}
          </div>
        )}

        {/* Show empty state for no results */}
        {filteredBehaviors.length === 0 && (
          <div className="text-center py-8">
            {searchQuery ? (
              <>
                <Search className="w-12 h-12 mx-auto mb-4 opacity-50" />
                <p className="text-lg opacity-75">لا توجد نتائج تطابق بحثك</p>
              </>
            ) : showOnlyFavorites ? (
              <>
                <Star className="w-12 h-12 mx-auto mb-4 opacity-50" />
                <p className="text-lg opacity-75">لا توجد سلوكيات في المفضلة</p>
              </>
            ) : null}
          </div>
        )}

        {loading && (
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-yellow-400"></div>
          </div>
        )}

        {/* Behavior List */}
        <ul className="space-y-4">
          {filteredBehaviors.slice(0, displayedCount).map((item) => (
            <BehaviorItem
            // todo
              key={item.id}
              item={item}
              isChecked={checkedItems[item.id] || false}
              onCheck={() => handleCheck(item.id)}
              onShare={() => handleShare(item.behavior)}
              onFavorite={handleFavorite}
              isFavorite={favorites.includes(item.id)}
              solution={item.solution}
            />
          ))}
        </ul>

        {shareError && (
          <div className="fixed top-4 right-4 bg-red-500 text-white p-4 rounded-lg shadow-lg">
            {shareError}
          </div>
        )}

        {displayedCount < filteredBehaviors.length && (
          <button
            onClick={handleLoadMore}
            className="w-full mt-4 p-4 bg-yellow-400 text-black rounded-lg flex items-center justify-center gap-1 hover:bg-yellow-500"
          >
            <span>عرض المزيد</span>
            <ArrowDown className="w-5 h-5" />
          </button>
        )}
      </div>

      <ShareModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setCurrentImageUrl("");
        }}
        imageUrl={currentImageUrl}
        behavior={currentBehavior}
      />

    </div>
  );
};

export default BadBehaviorsList;
