export const allBehaviors = [
  {
    id: 1,
    behavior: "الهرڭاوي هو لي كيسوق الموطور بلا كاصك و كيعرض حياتو للخطر.",
    solution: `
    <p>قيادة الدراجة النارية بدون خوذة تعرض حياتك لخطر كبير. الحوادث قد تكون مميتة، والخوذة تلعب دورًا حاسمًا في حماية رأسك وتقليل احتمالية الإصابات الخطيرة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li>- تأكد دائمًا من ارتداء خوذة مناسبة ومطابقة لمعايير السلامة قبل القيادة.</li>
      <li>- استثمر في خوذة مريحة وعالية الجودة لأن سلامتك تستحق الأفضل.</li>
      <li>- اجعل ارتداء الخوذة عادة يومية حتى أثناء القيادة لمسافات قصيرة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>سلامتك أولوية. ارتداء الخوذة ليس مجرد التزام قانوني، بل هو تعبير عن حبك للحياة واحترامك لعائلتك التي تريدك آمنًا وسالمًا.</p>
  `,
    category: ["driving"],
  },
  {
    id: 2,
    behavior:
      "الهرڭاوي هو لي مكيحتارمش الصف (الطوبيس، الإدارات، السبيطارات، الأبناك...).",
    solution: `
    <p>لمعالجة ظاهرة عدم احترام الصفوف في الأماكن العامة مثل الحافلات، الإدارات، المستشفيات، والبنوك، يجب تعزيز ثقافة الاحترام والنظام بين المواطنين. يمكن تحقيق ذلك من خلال حملات توعية تشرح أهمية احترام الدور وكيفية تأثير ذلك على سير الأمور بشكل منظم وفعّال.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li>- احترم الدور في الأماكن العامة، فكل شخص يستحق أن يُعامل بنفس الاحترام.</li>
      <li>- في حال كنت في عجلة من أمرك، حاول طلب المساعدة من موظفي المكان لتمكينك من الحصول على الخدمة بأسرع وقت.</li>
      <li>- مشاركة هذا المبدأ مع الآخرين، خاصةً الأجيال الشابة، لتحفيزهم على احترام النظام.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام المتبادل أساس التعايش. احترام الصفوف يساهم في سير الأمور بشكل أسرع وأكثر عدلاً للجميع.</p>
    `,
    category: ["public", "social"],
  },
  {
    id: 3,
    behavior: "الهرڭاوي هو لي مكيستعملش ممر الراجلين.",
    solution: `
  <p>لمعالجة ظاهرة عدم استخدام ممرات المشاة، يجب تعزيز الوعي بأهمية هذه الممرات في ضمان سلامة الجميع. يمكن ذلك من خلال حملات توعية تشرح للمواطنين مخاطر تجاوز الممرات المخصصة للمشاة وتوضح فوائد استخدامها في الحفاظ على النظام والحد من الحوادث.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- استخدم ممرات المشاة دائمًا، فهي تمثل الأمان لك وللسائقين.</li>
    <li>- أثناء عبور الطريق، تأكد من أن ممر المشاة قريب منك ولا تستسهل العبور من مكان غير مخصص.</li>
    <li>- ساهم في نشر هذه الثقافة بين أفراد عائلتك وأصدقائك لضمان احترام ممرات المشاة.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>سلامتك أولوية. احترام ممرات المشاة يعني حماية الأرواح وتفادي الحوادث التي يمكن تجنبها.</p>
  `,
    category: ["driving"],
  },
  {
    id: 4,
    behavior: "الهرڭاوي هو السائق لي مكيحتارمش ممر الراجلين.",
    solution: `
    <p>لمعالجة ظاهرة عدم احترام ممرات المشاة، يجب تعزيز الوعي بأهمية احترام حقوق المشاة في الشوارع. يمكن تحقيق ذلك من خلال حملات توعية تعليمية موجهة للسائقين والمواطنين بشكل عام، تشرح مخاطر التجاهل لهذه الممرات وتأثيرها على سلامة الناس.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li>- احترم ممرات المشاة دائماً، فهي تساهم في حماية الأرواح وتجنب الحوادث.</li>
      <li>- عندما ترى ممر مشاة، خفف السرعة وتوقف إذا كان هناك شخص يريد العبور.</li>
      <li>- ساهم في نشر الوعي بين السائقين من خلال التحدث عن أهمية احترام هذه الممرات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>سلامة المشاة هي مسؤوليتك. بتوقفك عند ممرات المشاة، تساهم في جعل الطرق أكثر أماناً للجميع.</p>
    `,
    category: ["driving"],
  },
  {
    id: 6,
    behavior:
      "الهرڭاوي هو البوليسي أو الجدارمي لي مكيطبقش القانون بصرامة و كيدير الرشوة فجيبو.",
    solution: `
    <p>إن عدم تطبيق القانون بصرامة من قبل بعض رجال الأمن أو ممارسة الرشوة يؤثر سلباً على الثقة بين المواطنين والأجهزة الأمنية، ويقوض العدالة والمساواة. هذا النوع من السلوك يمكن أن يؤدي إلى تفشي الفساد ويزيد من استياء الناس تجاه النظام القانوني.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li>- تعزيز ثقافة النزاهة والشفافية داخل المؤسسات الأمنية من خلال تدريب رجال الأمن على القيم الأخلاقية والمهنية.</li>
      <li>- تشجيع رجال الأمن على الالتزام بتطبيق القانون بإنصاف، مع فرض عقوبات صارمة بحق المخالفين.</li>
      <li>- توفير آليات للرقابة المستقلة للتحقيق في أي شكاوى تتعلق بالرشوة أو سوء السلوك.</li>
      <li>- تقديم الدعم والحماية للمواطنين الذين يبلغون عن الفساد، لضمان عدم تعرضهم لأي انتقام.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العدالة تبدأ بالنزاهة. بتطبيق القوانين بصرامة وشفافية، نبني جهازًا أمنيًا يحترم حقوق المواطنين ويعزز الثقة في المؤسسات.</p>
    `,
    category: ["work"],
  },
  {
    id: 7,
    behavior: "الهرڭاوي هو لي كيلوح الزبل فالزنقة.",
    solution: `
  <p>إلقاء النفايات في الشوارع يسبب تلوث البيئة، ويؤثر على صحة المجتمع، ويجعل الأماكن العامة غير جذابة. هذه الممارسات يمكن أن تؤدي إلى انتشار الأمراض وتدمير المناظر الطبيعية في مدننا.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- قم دائمًا بإلقاء النفايات في الحاويات المخصصة لذلك. إذا كنت تجد الحاويات بعيدة، احمل القمامة معك حتى تجد أقرب نقطة رمي.</li>
    <li>- شارك في حملات التنظيف المحلية، فهي فرصة لإحداث تغيير ملموس في المجتمع ولتشجيع الآخرين على الحفاظ على البيئة.</li>
    <li>- إذا كنت ترى شخصًا يلقى النفايات في الشارع، يمكنك بلطف تذكيره بأهمية الحفاظ على النظافة العامة، أو الإبلاغ عن الحادثة إن لزم الأمر.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>نظافة الشوارع هي مسؤولية الجميع. لنبدأ من أنفسنا ونحافظ على بيئة نظيفة وصحية للجميع!</p>
  `,
    category: ["public", "environment", "health"],
  },
  {
    id: 8,
    behavior: "الهرڭاوي هو المسؤول لي موفرش أكياس القمامة.",
    solution:
      "لمعالجة ظاهرة عدم توفير أكياس القمامة من قبل المسؤولين، يجب أن تتحمل الجهات المعنية مسؤولياتها في تحسين البنية التحتية الخاصة بالتخلص من النفايات. ينبغي على المسؤولين توفير أكياس القمامة في الأماكن العامة والأحياء السكنية بشكل منتظم، لضمان الحفاظ على النظافة وحماية البيئة. من المهم أيضًا إنشاء حملات توعية للمواطنين حول أهمية التخلص السليم من النفايات واستخدام أكياس القمامة بشكل صحيح. إضافة إلى ذلك، يجب أن يتم فرض رقابة صارمة على المسؤولين الذين يتقاعسون عن توفير هذه الخدمات، مع معاقبتهم في حال عدم الامتثال، وذلك لضمان بيئة نظيفة وصحية للجميع.",
    category: ["public", "environment", "health"],
  },
  {
    id: 8,
    behavior: "الهرڭاوي هو المسؤول لي موفرش أكياس القمامة.",
    solution: `
    <p>عدم توفير أكياس القمامة في الأماكن العامة من قبل المسؤولين يعرض النظافة العامة للخطر ويؤدي إلى تراكم النفايات في الشوارع، مما يؤثر سلبًا على الصحة العامة ويزيد من التلوث البيئي. غياب هذه الخدمة الأساسية يعوق المجتمع عن المساهمة في الحفاظ على بيئة نظيفة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li>- يجب على المسؤولين توفير أكياس القمامة في الأماكن العامة والأحياء السكنية بشكل دوري، وضمان وصولها إلى كل نقطة يحتاج إليها المواطن.</li>
      <li>- إطلاق حملات توعية تشجع المواطنين على استخدام أكياس القمامة بشكل صحيح والالتزام بالتخلص السليم من النفايات.</li>
      <li>- فرض رقابة صارمة على تنفيذ هذه الخدمات، وتطبيق عقوبات رادعة على المسؤولين الذين يتقاعسون عن توفيرها.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التخلص السليم من النفايات يبدأ بتوفير الأدوات المناسبة. فلنكن مسؤولين جميعًا في الحفاظ على بيئة نظيفة وصحية للجميع.</p>
    `,
    category: ["public", "environment", "health"],
  },
  {
    id: 10,
    behavior: "الهرڭاوي هو مسؤول الأمن الفاشل فالتنظيم.",
    solution: `
  <p>فشل بعض مسؤولي الأمن في التنظيم يؤدي إلى الفوضى في الفعاليات والأماكن العامة، مما يشكل خطرًا على السلامة العامة ويؤثر سلبًا على سمعة الأجهزة الأمنية. هذا النوع من التقصير يعطل سير الفعاليات ويزيد من احتمالية وقوع الحوادث.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- يجب تعزيز مهارات المسؤولين من خلال برامج تدريبية مستمرة على التنظيم والإدارة الفعالة، مع التركيز على كيفية التعامل مع الأزمات والفعاليات الكبيرة.</li>
    <li>- تحديد المسؤوليات بوضوح وتوفير الموارد اللازمة، مثل التكنولوجيا والموظفين المدربين، لضمان تنظيم فعال وآمن.</li>
    <li>- إنشاء آليات مساءلة صارمة لضمان محاسبة المسؤولين في حال حدوث فشل في التنظيم، بالإضافة إلى وجود خطط طوارئ قابلة للتنفيذ لضمان التعامل السريع والفعّال مع أي طارئ.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>التنظيم الجيد هو الأساس لضمان الأمن والسلامة. بتعزيز مهارات المسؤولين وتوفير الموارد اللازمة، يمكننا تحقيق بيئة آمنة ومرتبة للجميع.</p>
  `,
    category: ["social", "work"],
  },
  {
    id: 11,
    behavior: "الهرڭاوي هو لي كيخسر الهضرة فمكان عمومي.",
    solution: `
  <p>إزعاج الآخرين في الأماكن العامة بالكلام المرتفع أو غير المناسب يمكن أن يؤدي إلى خلق بيئة غير مريحة ويؤثر على الراحة النفسية للآخرين. في الأماكن العامة، يتوقع الجميع أن يحترم الآخرون خصوصياتهم وراحتهم.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تجنب التحدث بصوت مرتفع في الأماكن العامة، خاصة في الأماكن التي تتطلب الهدوء مثل المكتبات أو وسائل النقل العامة.</li>
    <li>- احترم الآخرين من خلال استخدام نغمة هادئة ولائقة أثناء المحادثات في الأماكن المشتركة.</li>
    <li>- في حالة وجود نقاش مهم أو حساس، يمكن اختيار مكان خاص أو بعيد عن الآخرين لإجراء الحديث.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الاحترام المتبادل يبدأ من مراعاة احتياجات الآخرين في الأماكن العامة. لنجعل الأماكن العامة أكثر راحة لكل فرد في المجتمع.</p>
  `,
    category: ["public", "social"],
  },
  {
    id: 12,
    behavior:
      "الهرڭاوي هو مول الكرويلة لي ممهليش فالحمار أو الحصان ديالو و كيعنفو من الفوق.",
    solution: `
  <p>تعنيف الحيوانات مثل الحمير أو الخيول يؤثر سلبًا على صحتها ورفاهيتها. الحيوانات التي لا تحظى بالعناية المناسبة قد تعاني من إصابات جسدية ومشاكل نفسية، مما يجعل من الضروري توفير بيئة صحية وآمنة لها.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- يجب على الملاك توفير العناية الصحية المناسبة للحيوانات، بما في ذلك التغذية الجيدة والمأوى الآمن.</li>
    <li>- تأكد من أن الحيوانات تكون مجهزة بشكل صحيح وراحة تامة، مثل استخدام معدات لائقة لركوب الحيوانات وعدم تحميلها بأثقال ثقيلة قد تؤذيها.</li>
    <li>- يجب تعليم ومعرفة كيفية التعامل مع الحيوانات برفق، وتجنب أي شكل من أشكال العنف أو المعاملة القاسية تجاهها.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الحيوانات هي كائنات حساسة وتستحق معاملة رقيقة وعناية. من خلال توفير بيئة مريحة وآمنة لهم، نساعدهم على العيش بشكل أفضل مما يساهم في تحقيق مجتمع إنساني ومتحضر.</p>
  `,
    category: ["public", "animals", "work"],
  },
  {
    id: 13,
    behavior: "الهرڭاوي هو لي مكيرفقش بالحيوانات الضالة.",
    solution: `
  <p>إهمال الحيوانات الضالة يمكن أن يؤدي إلى معاناتها، سواء من حيث الجوع أو الأمراض أو المعاملة السيئة. هذه الحيوانات تحتاج إلى الرعاية والعناية لتجنب المعاناة التي قد تكون نتيجة للإهمال.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- إذا كنت ترى حيوانًا ضالًا في الشارع، حاول التواصل مع الجمعيات المحلية المختصة برعاية الحيوانات أو مع البلديات لمساعدتها.</li>
    <li>- تجنب ترك الحيوانات الضالة في حالة من الجوع أو المرض. يمكنك تقديم الطعام أو الماء لهم إذا كان ذلك ممكنًا.</li>
    <li>- دعم برامج التبني والتعقيم للمساعدة في تقليل أعداد الحيوانات الضالة وحمايتها من الممارسات السلبية.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>كل حيوان يستحق العيش بكرامة وراحة. من خلال الرعاية والاهتمام، يمكننا خلق مجتمع إنساني يولي اهتمامًا للحيوانات كما يولي لأفراده.</p>
  `,
    category: ["public", "animals"],
  },
  {
    id: 14,
    behavior: "الهرڭاوي هو رجل السلطة المغرور.",
    solution: `
  <p>التكبر والغرور من قبل رجال السلطة يمكن أن يؤدي إلى تدهور العلاقات مع المواطنين وفقدان الثقة في المؤسسات. عندما يظن الأشخاص الذين في مواقع السلطة أنهم فوق القوانين أو لا يحتاجون إلى احترام الآخرين، فإنهم يساهمون في خلق بيئة من الفساد وعدم التعاون.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- يجب على رجال السلطة أن يتذكروا دائمًا أن المسؤولية تأتي مع احترام الآخرين وأنهم هنا لخدمة المواطنين وليس للتعالي عليهم.</li>
    <li>- يجب تحسين ثقافة القيادة وتعزيز قيم التواضع والمساواة في المؤسسات الحكومية من خلال التدريب المستمر والتوجيه الفعّال.</li>
    <li>- فتح قنوات للتواصل مع المواطنين والاستماع إلى شكاواهم وملاحظاتهم يساعد على بناء الثقة وتحسين مستوى الخدمة.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>القيادة الحقيقية هي التي تجمع بين القوة والتواضع. عندما يحترم رجل السلطة من حوله، يعكس بذلك قيم العدالة والمساواة التي يطمح الجميع لتحقيقها.</p>
  `,
    category: ["work", "social"],
  },
  {
    id: 15,
    behavior: "الهرڭاوي هو رئيس الجماعة لي مكيوفيش بالوعود الإنتخابية ديالو.",
    solution: `
  <p>عدم الوفاء بالوعود الانتخابية من قبل المسؤولين يؤدي إلى تراجع الثقة بين المواطنين والسلطات المحلية، مما يضر بالعملية الديمقراطية ويعزز الشعور بالإحباط بين المواطنين. عندما يُخلف المسؤولون وعودهم، يفقد المواطنون الأمل في أن صوتهم سيؤثر في التغيير الذي يتمنونه.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- يجب على رؤساء الجماعات أن يكونوا أكثر شفافية في ما يتعلق بالوعود التي يقطعونها خلال حملاتهم الانتخابية.</li>
    <li>- يجب تنفيذ نظام للمحاسبة والتقييم المستمر لضمان أن المشاريع والوعود الانتخابية يتم الوفاء بها في الوقت المحدد.</li>
    <li>- تشجيع الحوار المستمر بين المواطنين والمسؤولين، من خلال اجتماعات دورية، لمتابعة تقدم الوعود وفتح المجال للاستماع للمشاكل والاقتراحات.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الصدق في الوعود يبني الثقة والاحترام بين المواطنين والمسؤولين. الالتزام بما تم وعد به يجعل المجتمع أكثر وحدة ويساهم في بناء مستقبل أفضل للجميع.</p>
  `,
    category: ["social", "work"],
  },
  {
    id: 16,
    behavior: "الهرڭاوي هو لي كيلقا تيليفون أو بزطام طايح و مكيرجعوش لمولاه.",
    solution: `
  <p>عند العثور على ممتلكات شخص آخر مثل الهاتف أو الملابس المفقودة وعدم إعادتها، فإن هذا الفعل يساهم في انتشار قلة الأمانة ويقوض القيم الاجتماعية من الثقة والاحترام المتبادل بين الأفراد. مثل هذه التصرفات تخلق بيئة من الأنانية وتزيد من القلق بين الأفراد في المجتمع.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- عند العثور على شيء مفقود، من الأفضل دائمًا إعادته إلى صاحبه أو تسليمه إلى أقرب نقطة خدمة أو شرطة لضمان وصوله إلى المالك.</li>
    <li>- إذا لم تتمكن من العثور على المالك مباشرة، حاول نشر منشورات أو إعلام الأصدقاء والمجتمع المحلي للمساعدة في استرجاع المفقودات.</li>
    <li>- عزز ثقافة الأمانة من خلال تعليم الأطفال والمجتمع أهمية التصرف بأمانة والاحترام لممتلكات الآخرين.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>إعادة المفقودات ليست فقط تصرفًا قانونيًا، بل هي علامة على الأمانة والاحترام. عندما نساعد الآخرين، نساهم في بناء مجتمع قائم على الثقة والتعاون.</p>
  `,
    category: ["social"],
  },
  {
    id: 17,
    behavior: "الهرڭاوي هو لي كيمارس المحسوبية و الزبونية.",
    solution: `
  <p>المحسوبية والزبونية تعني منح الفرص أو المزايا لأشخاص بناءً على العلاقات الشخصية بدلاً من الكفاءة أو الجدارة. هذا السلوك يضر بالعدالة ويقلل من فرص النجاح للجميع. عندما يتم تجاهل القيم الأساسية مثل النزاهة والكفاءة، يتعرض المجتمع لمشاكل مثل الفساد وتدني المعايير المهنية.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تطبيق مبدأ العدالة والمساواة في جميع القرارات، بحيث يتم تكليف المهام أو تقديم الفرص بناءً على الكفاءة والجدارة فقط.</li>
    <li>- تشجيع ثقافة الشفافية والمحاسبة داخل المؤسسات من خلال وضع سياسات واضحة لضمان أن القرارات تُتخذ بطريقة عادلة.</li>
    <li>- مكافحة الفساد من خلال تعزيز الرقابة الداخلية وتقديم آليات للتبليغ عن أي تجاوزات من هذا النوع.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>العدالة والمساواة هما أساس نجاح أي مجتمع. بتطبيق قيم النزاهة وتجنب المحسوبية، نبني بيئة عادلة تعطي كل فرد حقه بناءً على عمله وجدارته.</p>
  `,
    category: ["work", "social"],
  },
  {
    id: 18,
    behavior: "الهرڭاوي هو شخص أناني كيفكر غير فالمصلحة ديالو.",
    solution: `
  <p>الأنانية تؤدي إلى تجاهل احتياجات الآخرين والمجتمع بشكل عام. عندما يركز الفرد فقط على مصالحه الشخصية، يعمق الفجوات بين الأفراد ويقلل من فرص التعاون والمشاركة المجتمعية. هذا السلوك يضر بالعلاقات الإنسانية ويسهم في خلق بيئة من التوتر والصراع.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- اعتماد التفكير الجماعي: تذكر أن مصلحة المجتمع تتطلب تعاون الجميع. ابحث عن طرق لدعم الآخرين والتعاون لتحقيق الأهداف المشتركة.</li>
    <li>- ممارسة التوازن بين المصالح الشخصية والمصالح العامة: يمكن تحقيق النجاح الشخصي مع الحفاظ على الاهتمام بالآخرين والعمل من أجل المصلحة العامة.</li>
    <li>- تشجيع ثقافة العطاء والمساعدة: حاول تقديم المساعدة للآخرين عندما تستطيع، واعلم أن العطاء يعود عليك بالراحة النفسية والمجتمع المتعاون.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>العطاء والتعاون يعززان الروابط بيننا ويخلقون مجتمعًا أكثر انسجامًا وسعادة. عندما نضع الآخرين في اعتبارنا، فإننا نساهم في بناء عالم أفضل للجميع.</p>
  `,
    category: ["social"],
  },
  // { id: 19, behavior: "", solution: "---", category: "" },
  {
    id: 20,
    behavior: "الهرڭاوي هو لي كيعطي أو كيشد الرشوة.",
    solution: `
    <p>الرشوة تضعف المؤسسات وتعرقل العدالة، حيث تمنح الامتيازات لمن لا يستحق وتظلم الآخرين. قبول أو تقديم الرشوة يساهم في انتشار الفساد ويؤثر على تطور المجتمع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li>- التزام النزاهة ورفض تقديم أو قبول الرشوة مهما كانت الظروف.</li>
      <li>- التبليغ عن حالات الرشوة للجهات المختصة لضمان محاسبة المسؤولين عنها.</li>
      <li>- تعزيز ثقافة الشفافية والمسؤولية في بيئة العمل وبين أفراد المجتمع.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الرشوة ليست فقط جريمة، بل هي خيانة لقيم العدالة والمساواة. بمقاومتنا لهذا السلوك، نبني مجتمعًا أكثر نزاهة واحترامًا للجميع.</p>
    `,
    category: ["work", "social"],
  },
  {
    id: 21,
    behavior: "الهرڭاوي هو الموظف لي كيدخل للخدمة معطل و كيخرج قبل الوقت.",
    solution: `
  <p>عدم الالتزام بمواعيد العمل يؤثر سلبًا على الإنتاجية ويخلق بيئة عمل غير متوازنة. كما أنه يقلل من احترام الزملاء ويعطل سير العمل الجماعي.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- التخطيط المسبق لليوم لضمان الوصول إلى العمل في الوقت المحدد.</li>
    <li>- الالتزام بساعات العمل الرسمية كجزء من تحمل المسؤولية المهنية.</li>
    <li>- استخدام أدوات تنظيم الوقت لتجنب التأخير وإنجاز المهام بكفاءة.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الانضباط في العمل يعكس احترافك واحترامك للزملاء وللمؤسسة. تذكر أن كل دقيقة تقضيها بصدق وإخلاص تضيف قيمة حقيقية لعملك.</p>
  `,
    category: ["work", "social"],
  },
  {
    id: 22,
    behavior: "الهرڭاوي هو رجل التعليم لي كيعنف التلاميذ ديالو.",
    solution: `
  <p>تعنيف التلاميذ يؤدي إلى تأثيرات سلبية على صحتهم النفسية، ثقتهم بأنفسهم، وعلاقتهم بالتعلم. هذه الممارسات تتعارض مع القيم التربوية ومعايير التعليم الحديث.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- استخدام أساليب تعليمية إيجابية تعتمد على الحوار والتفاهم لتحفيز الطلاب بدلاً من تخويفهم.</li>
    <li>- الاستفادة من برامج تدريبية حول مهارات التواصل وحل النزاعات داخل الفصل بطريقة بناءة.</li>
    <li>- إنشاء قنوات دعم نفسي للمعلمين تساعدهم في التعامل مع ضغوط العمل بطريقة صحية.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>التعليم هو بناء للأجيال، واستخدام الاحترام والتفهم يعزز بيئة تعلم إيجابية تلهم الطلاب للنجاح والابتكار.</p>
  `,
    category: ["work", "school", "social", "children"],
  },
  {
    id: 23,
    behavior:
      "الهرڭاوي هو مول الطاكسي للي مكيخليكش دير حزام السلامة. (و الله حتا كاينين!).",
    solution: `
  <p>عدم السماح للركاب باستخدام حزام السلامة يعرض حياتهم للخطر ويخالف القوانين المرورية التي تهدف إلى حماية الجميع.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- توعية السائقين بأهمية حزام السلامة في تقليل الإصابات الناجمة عن الحوادث.</li>
    <li>- تطبيق القوانين التي تفرض ارتداء حزام السلامة، سواء من قبل السائق أو الركاب، مع فرض غرامات على المخالفين.</li>
    <li>- تشجيع الركاب على التمسك بحقهم في ارتداء حزام السلامة لضمان سلامتهم الشخصية.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>السلامة على الطريق مسؤولية مشتركة. ارتداء حزام السلامة هو خطوة صغيرة تنقذ حياتك وحياة من تحب.</p>
  `,
    category: ["driving", "social"],
  },
  {
    id: 24,
    behavior: "الهرڭاوي هو الطبيب أو الممرض لي مكيرعاش المرضى ديالو.",
    solution: `
  <p>إهمال المرضى من قبل الأطباء أو الممرضين يمكن أن يؤدي إلى تدهور حالتهم الصحية ويقوض الثقة في النظام الصحي.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تعزيز أخلاقيات المهنة من خلال التدريب المستمر وبرامج التوعية التي تركز على أهمية الرحمة والالتزام برعاية المرضى.</li>
    <li>- تطبيق آليات رقابية فعالة لضمان تقديم خدمات صحية عالية الجودة ومعاقبة أي إهمال متعمد.</li>
    <li>- توفير بيئة عمل داعمة للعاملين في القطاع الصحي لتقليل الإرهاق وضمان قدرتهم على تقديم أفضل رعاية.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>رعاية المرضى ليست مجرد وظيفة، بل هي رسالة إنسانية. تقديم الاهتمام والاحترام يغير حياة المرضى ويعيد لهم الأمل.</p>
  `,
    category: ["work", "health", "social"],
  },
  {
    id: 25,
    behavior:
      "الهرڭاوي هو لي مباغيش يبدا التغيير من راسو و كيتسنا الدولة تتدخل.",
    solution: `
  <p>انتظار التغيير من الآخرين دون بذل الجهد الفردي يعطل التطور ويؤخر التقدم المجتمعي.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- ابدأ بالتغيير داخل نفسك ومحيطك المباشر، فالتغيير الكبير يبدأ بخطوات صغيرة.</li>
    <li>- اعمل على تعزيز القيم الإيجابية مثل الالتزام، المسؤولية، والمشاركة في المبادرات المجتمعية.</li>
    <li>- شارك في حملات تطوعية أو مشاريع محلية لتحسين الظروف في حيك أو مجتمعك.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>التغيير يبدأ بك. حينما نتخذ المبادرة ونكون مثالاً يحتذى به، نلهم الآخرين ونحقق مجتمعًا أفضل.</p>
  `,
    category: ["social"],
  },
  {
    id: 26,
    behavior: "الهرڭاوي هو لي مكيحافضش على المرافق العمومية.",
    solution: `
  <p>عدم المحافظة على المرافق العمومية يضر بالمجتمع بأكمله، حيث تُهدر الموارد ويُحرم الآخرون من الاستفادة منها.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تعامل مع المرافق العمومية كأنها ممتلكاتك الشخصية، واحرص على نظافتها وصيانتها.</li>
    <li>- قم بالتبليغ عن أي تلف أو تخريب تلاحظه في هذه المرافق.</li>
    <li>- شارك في حملات توعية أو تطوع لتنظيف أو إصلاح المرافق العامة في منطقتك.</li>
    <li>- علم الأطفال أهمية احترام الممتلكات العامة منذ الصغر.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>المرافق العمومية أمانة في أيدينا، والحفاظ عليها يعكس وعي المجتمع واحترامه للموارد المشتركة. لنكن قدوة في العناية بها!</p>
  `,
    category: ["social", "public", "school", "street", "environment"],
  },
  {
    id: 27,
    behavior: "الهرڭاوي هو لي كيقضي حاجتو فالزنقة.",
    solution: `
  <p>قضاء الحاجة في الشارع يسبب أضراراً بيئية وصحية كبيرة، كما يؤثر سلباً على نظافة الأماكن العامة واحترامها.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- استخدم المرافق الصحية العامة المخصصة كلما دعت الحاجة.</li>
    <li>- طالب السلطات المحلية بزيادة عدد المراحيض العمومية وصيانتها بشكل منتظم.</li>
    <li>- ساهم في نشر الوعي بين أفراد المجتمع حول أضرار هذا السلوك على البيئة والصحة.</li>
    <li>- شجع الأطفال والمراهقين على التصرف بشكل مسؤول في الأماكن العامة.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>نظافة شوارعنا هي انعكاس لوعينا واحترامنا لأنفسنا وللآخرين. استخدام المرافق الصحية يعزز صحة البيئة والمجتمع.</p>
  `,
    category: ["street", "social", "public", "environment", "health"],
  },
  {
    id: 28,
    behavior: "الهرڭاوي هو المسؤول لي موفرش مراحيض عمومية.",
    solution: `
  <p>غياب المراحيض العمومية يؤثر بشكل كبير على راحة المواطنين وصحتهم العامة، كما يسهم في تلويث البيئة والإضرار بجمالية الشوارع.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تخصيص ميزانية لإنشاء مراحيض عمومية في المناطق ذات الكثافة السكانية العالية والأماكن الحيوية مثل الأسواق والمحطات.</li>
    <li>- ضمان صيانة وتنظيف المراحيض العمومية بشكل دوري للحفاظ على جودتها وجعلها مريحة للمستخدمين.</li>
    <li>- إطلاق حملات توعية تشجع المواطنين على استخدام هذه المرافق والحفاظ على نظافتها.</li>
    <li>- تشجيع الشراكة مع القطاع الخاص لتمويل وصيانة المراحيض العمومية.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>توفير مراحيض عمومية هو خطوة أساسية نحو مدن أنظف وأكثر تحضراً. لنكن جزءاً من الحل، ونطالب بتحسين البنية التحتية لخدمة الجميع.</p>
  `,
    category: ["work", "environment", "health", "street"],
  },
  {
    id: 29,
    behavior: "الهرڭاوي هو لي كتسمح ليه نفسو يبيع ماكلة مغشوشة للناس.",
    solution: `
  <p>بيع الطعام المغشوش يشكل تهديداً مباشراً على صحة المواطنين، ويفقد المجتمع الثقة في الأسواق المحلية.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تكثيف حملات المراقبة من قبل الجهات المسؤولة للتأكد من جودة المنتجات الغذائية المطروحة في الأسواق.</li>
    <li>- فرض عقوبات صارمة على المخالفين، بما في ذلك الغرامات المالية وسحب التراخيص.</li>
    <li>- تعزيز الوعي لدى المواطنين حول كيفية التعرف على الأطعمة المغشوشة والإبلاغ عن المخالفات.</li>
    <li>- تشجيع التجار على الالتزام بمعايير الجودة والنظافة من خلال حملات توعية وتحفيز إيجابي.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>صحة المجتمع أمانة بين أيدينا جميعاً. بالتزامنا بالنزاهة في بيع الطعام، نضمن حياة أفضل للجميع ونبني ثقة لا تهتز.</p>
  `,
    category: ["social", "health", "work"],
  },
  {
    id: 30,
    behavior: "الهرڭاوي هو لي كيتجمع على شي حادثة غير باش يتفرج.",
    solution: `
  <p>التجمع حول الحوادث يمكن أن يعيق حركة الإنقاذ ويزيد من التوتر والضغط على فرق الطوارئ، كما أن الفضول يؤدي إلى تعطيل الطريق وتعرض الأشخاص لمخاطر إضافية.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- زيادة الوعي حول أهمية احترام خصوصية الحوادث وتجنب التجمعات غير الضرورية.</li>
    <li>- تشجيع الناس على إخلاء المنطقة سريعًا بدلاً من الوقوف للمشاهدة، لضمان سرعة وصول فرق الطوارئ.</li>
    <li>- تفعيل دور الشرطة لتوجيه الأشخاص بعيدًا عن مكان الحادث لتسهيل عمليات الإنقاذ.</li>
    <li>- تركيز حملات توعية على التأثير السلبي للتجمعات في الحوادث وضرورة الحفاظ على النظام والأمان العام.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>كل دقيقة مهمة في الحوادث. لنكن مسؤولين وندع فرق الطوارئ تؤدي عملها بدون عوائق.</p>
  `,
    category: ["social", "public", "driving"],
  },
  {
    id: 31,
    behavior: "الهرڭاوي هو لي كيدخل لمرحاض عمومي و كيخلي بلاصتو موسخة.",
    solution: `
  <p>ترك المرحاض العام غير نظيف يؤثر سلبًا على الآخرين ويجعل المكان غير مريح للاستخدام. النظافة في الأماكن العامة مهمة لضمان بيئة صحية وآمنة للجميع.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- الاهتمام بالنظافة الشخصية والحرص على الحفاظ على نظافة المرحاض بعد الاستخدام.</li>
    <li>- التأكد من أن أي مخلفات يتم التخلص منها بشكل صحيح في الأماكن المخصصة لذلك.</li>
    <li>- في حالة وجود أي مشاكل أو نقص في الخدمات (مثل عدم وجود صابون أو ورق تواليت)، يجب الإبلاغ عن ذلك للجهات المختصة لتحسين الخدمات.</li>
    <li>- مشاركة الوعي المجتمعي عن أهمية الحفاظ على نظافة الأماكن العامة من خلال حملات توعية في المدارس والمجتمعات.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>نظافة المرحاض العام تعكس احترامنا لبعضنا البعض. لنحافظ على بيئة نظيفة وصحية للجميع.</p>
  `,
    category: ["social", "health", "public", "school"],
  },
  {
    id: 32,
    behavior:
      "الهرڭاوي هو لي فاش كيضربو البرد مكيديرش كمامة و فاش كيعطس مكيستعملش كلينيكس باش مايعاديش الناس.",
    solution: `
  <p>عدم أخذ الاحتياطات الصحية في الأماكن العامة يعرض الآخرين للإصابة بالعدوى، خاصة في فصل الشتاء أو في حالات انتشار الأمراض التنفسية. الالتزام باستخدام الكمامة والمناديل الورقية يساعد في حماية الصحة العامة.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- استخدم الكمامة عندما تشعر بالبرد أو في الأماكن العامة المزدحمة لحماية نفسك والآخرين من العدوى.</li>
    <li>- عند العطس أو السعال، استخدم مناديل ورقية للتغطية، ثم تخلص منها بشكل آمن. إذا لم يكن هناك مناديل، حاول العطس في المرفق لتجنب انتقال الجراثيم.</li>
    <li>- غسل اليدين بانتظام بالصابون والماء، خاصة بعد العطس أو السعال.</li>
    <li>- إذا كنت مصابًا بأي أعراض مرضية، من الأفضل تجنب الأماكن العامة لتقليل خطر نقل العدوى.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>صحتنا مسؤوليتنا. لنحمي أنفسنا والآخرين باتباع الإجراءات الوقائية البسيطة مثل استخدام الكمامة والمناديل.</p>
  `,
    category: ["social", "health", "public"],
  },
  {
    id: 33,
    behavior:
      "الهرڭاوي هو البوليسي أو مول الإسعاف لي فاش كتصوني ليه مكيجاوبش أو كيجي معطل.",
    solution: `
  <p>التقصير من قبل رجال الشرطة أو فرق الإسعاف في الاستجابة السريعة يشكل تهديداً لسلامة الأفراد ويزيد من المخاطر في الحالات الطارئة. قد يكون التأخير في تقديم المساعدة أو عدم الاستجابة في الوقت المناسب السبب في تدهور الحالة الصحية للمتضرر أو زيادة خطر الحوادث.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- على رجال الأمن وفرق الإسعاف أن يكونوا على استعداد دائم لتقديم المساعدة الفورية في حالات الطوارئ. الالتزام بالاستجابة السريعة يسهم في تقليل الأضرار.</li>
    <li>- يجب على المؤسسات الأمنية والصحية توفير التدريب المستمر لفرقها في كيفية التعامل مع الحالات الطارئة بفعالية، بالإضافة إلى تعزيز مهارات التواصل والاستجابة.</li>
    <li>- من الضروري أن تكون هناك آليات واضحة للتعامل مع الشكاوى المتعلقة بالتأخير أو الإهمال في الاستجابة للطوارئ، مع ضرورة معاقبة المتسببين في هذا التقصير.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الاستجابة السريعة والفعالة في حالات الطوارئ يمكن أن تنقذ الأرواح. كل دقيقة مهمة في مثل هذه الظروف، ولنكن جميعاً مستعدين للمساعدة.</p>
  `,
    category: ["social", "work"],
  },
  {
    id: 34,
    behavior:
      "الهرڭاوي هو هداك لي مكيعتانيش بالنظافة ديال الجسم ديالو و كيخلي ريحتو عاطية.",
    solution: `
  <p>عدم الاهتمام بالنظافة الشخصية يمكن أن يؤثر بشكل كبير على صحة الفرد ومحيطه. الروائح الكريهة تؤثر على بيئة العمل، الدراسة، أو الأماكن العامة، مما يسبب إزعاجاً للآخرين ويمكن أن يؤدي إلى مشكلات صحية مثل التهابات الجلد أو أمراض أخرى.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- من المهم أن يتبنى كل شخص روتيناً يومياً للنظافة الشخصية يشمل الاستحمام المنتظم، استخدام معطرات الجسم، وتغيير الملابس بشكل مستمر.</li>
    <li>- يجب على الأفراد الحفاظ على نظافة الأسنان والشعر، لأن هذه العادات تمنع تراكم الروائح الكريهة وتحسن مظهر الشخص بشكل عام.</li>
    <li>- كما يُنصح باستخدام منتجات العناية الشخصية مثل الشامبو، الصابون، ومعطرات الجسم التي تساهم في التخلص من الروائح غير المستحبة وتساعد في الحفاظ على صحة الجلد.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>النظافة الشخصية ليست فقط عن المظهر، بل هي جزء من العناية بصحتك وصحة الآخرين. بيئة نظيفة تبدأ من كل فرد.</p>
  `,
    category: ["social", "public", "health"],
  },
  {
    id: 35,
    behavior:
      "الهرڭاوي هو هداك لي كيخلي ضفارو موسخين، مكيغسلش سنانو، كيدوش مرة فالسيمانة.",
    solution: `
  <p>الإهمال في النظافة الشخصية مثل عدم غسل الأسنان أو قص الأظافر يمكن أن يؤدي إلى العديد من المشاكل الصحية، بما في ذلك التهابات اللثة، تسوس الأسنان، أو تكاثر الجراثيم على الأظافر. نظافة الجسم ضرورية لصحة جيدة وللتفاعل الاجتماعي بشكل مريح.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- يجب غسل الأسنان بانتظام على الأقل مرتين في اليوم باستخدام معجون أسنان يحتوي على الفلورايد، وذلك للحفاظ على صحة الفم والتقليل من تسوس الأسنان.</li>
    <li>- قص الأظافر بشكل منتظم وحرص على تنظيفها من الأوساخ يقلل من احتمالية الإصابة بالعدوى ويساهم في نظافة اليدين.</li>
    <li>- من الأفضل الاستحمام بشكل يومي أو على الأقل بانتظام، للتخلص من الأوساخ والعرق المتراكم.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>النظافة هي أساس الصحة والراحة النفسية. الاهتمام بتفاصيل صغيرة مثل الأسنان والأظافر يمكن أن يجعل يومك أكثر إشراقًا ويساهم في زيادة الثقة بالنفس.</p>
  `,
    category: ["social", "health"],
  },
  {
    id: 36,
    behavior:
      "الهرڭاوي هو داك مول الحانوت لي عندو موس واحد كيدير بيه كلشي و مكيهتمش للصحة ديال الناس.",
    solution: `
  <p>استخدام أداة واحدة بشكل غير صحي قد يؤدي إلى نقل الجراثيم أو البكتيريا من شخص لآخر، مما يشكل خطرًا على صحة الجميع. الاهتمام بالنظافة الشخصية والمهنية ضروري للحفاظ على صحة الزبائن والموظفين على حد سواء.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- يجب على صاحب المحل استخدام أدوات نظيفة ومعقمة لكل عملية، خاصة الأدوات التي تلامس الطعام أو الجلد.</li>
    <li>- من المهم تدريب الموظفين على ممارسات النظافة الأساسية، مثل غسل اليدين بانتظام واستخدام أدوات مختلفة لكل نوع من المنتجات.</li>
    <li>- يجب توفير أدوات قابلة للتنظيف بسهولة أو أدوات جديدة لكل استعمال لضمان عدم انتقال أي عدوى.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الحفاظ على نظافة الأدوات والأيدي ليس فقط مسؤولية صحية، بل يعكس احترامًا للزبائن ويعزز ثقتهم في المحل. الصحة العامة تبدأ من احترام المبادئ الصحية البسيطة.</p>
  `,
    category: ["work", "health", "social"],
  },
  {
    id: 37,
    behavior:
      "الهرڭاوي هو لي كيسمع الموسيقى بصوت عالي فالطوبيس أو الزنقة بلا ما يحترم الناس.",
    solution: `
  <p>تشغيل الموسيقى بصوت عالٍ في الأماكن العامة يزعج الآخرين ويقلل من الراحة والهدوء الذي يحتاجه الجميع، خاصة في وسائل النقل أو الشوارع المكتظة.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- استخدام سماعات الأذن عند الرغبة في الاستماع إلى الموسيقى في الأماكن العامة.</li>
    <li>- الحفاظ على مستوى صوت معتدل يحترم خصوصية وراحة الآخرين.</li>
    <li>- الوعي بأن الأماكن العامة مشتركة وتتطلب احترام احتياجات الجميع للهدوء.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>هدوءك في الأماكن العامة يعكس احترامك للآخرين ويخلق بيئة مريحة للجميع. كن قدوة في التصرف الحضاري.</p>
  `,
    category: ["public", "social", "street"],
  },
  {
    id: 38,
    behavior: "الهرڭاوي هو لي كيكمي وسط بلاصة عامرة بالناس و مكيباليش.",
    solution: `
  <p>التدخين في الأماكن العامة المكتظة يعرض الآخرين، بمن فيهم الأطفال وكبار السن، للدخان السلبي الذي يضر بالصحة ويسبب الانزعاج. كما يؤثر سلباً على جودة الهواء في المساحات المشتركة.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تجنب التدخين في الأماكن العامة، خاصة المغلقة أو المزدحمة.</li>
    <li>- استخدام المناطق المخصصة للتدخين إذا كانت متوفرة.</li>
    <li>- الوعي بتأثير الدخان السلبي على صحة الآخرين واحترام حقهم في الهواء النظيف.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>صحتك وصحة الآخرين تستحقان الاحترام. باختيارك مكاناً مناسباً للتدخين، تساهم في خلق بيئة صحية ومريحة للجميع.</p>
  `,
    category: ["social", "public", "street", "environment", "health"],
  },
  {
    id: 39,
    behavior:
      "الهرڭاوي هو لي كيبيع مواد منتهية الصلاحية و كيقلب غير على الربح.",
    solution: `
  <p>بيع المواد منتهية الصلاحية يشكل خطراً كبيراً على صحة المستهلكين، حيث يمكن أن يسبب التسمم الغذائي أو مشاكل صحية خطيرة. كما يعكس غياب الأخلاق المهنية وضعف احترام الأمانة.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- التأكد من تاريخ الصلاحية قبل عرض المنتجات للبيع أو شرائها.</li>
    <li>- التخلص من المواد الغذائية أو السلع المنتهية صلاحيتها بطرق آمنة ومسؤولة.</li>
    <li>- إبلاغ السلطات المختصة عند اكتشاف مواد منتهية الصلاحية معروضة للبيع.</li>
    <li>- التوعية بأهمية الثقة والمصداقية في العمل التجاري وأثرها على سمعة التاجر وعلاقته مع الزبائن.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الربح السريع على حساب صحة الآخرين خسارة على المدى البعيد. كن تاجراً أميناً، تسهم في بناء مجتمع صحي وموثوق.</p>
  `,
    category: ["social", "health", "work"],
  },
  {
    id: 40,
    behavior: "الهرڭاوي هو المسؤول لي كيتهرب من واجباتو و كيغطيو عليه صحابو.",
    solution: `
  <p>تهرب المسؤولين من أداء واجباتهم يعطل سير العمل، يضر بالمواطنين، ويهدم ثقة الناس في المؤسسات. تغطية الزملاء على هذا السلوك تزيد من تفاقم المشكلة بدلاً من حلها.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- تطبيق مبدأ المحاسبة لضمان التزام المسؤولين بواجباتهم.</li>
    <li>- تشجيع ثقافة النزاهة داخل المؤسسات من خلال ورشات تدريبية وحملات توعية.</li>
    <li>- إنشاء آليات شفافة للتبليغ عن المسؤولين المتهاونين دون خوف من التبعات.</li>
    <li>- تشديد الرقابة وتفعيل القوانين الرادعة ضد التهرب الوظيفي والتواطؤ.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>المسؤولية أمانة وليست امتيازاً. أداء الواجب بصدق يحقق العدالة ويكسبك احترام الجميع.</p>
  `,
    category: ["social", "work"],
  },
  {
    id: 41,
    behavior:
      "الهرڭاوي هو لي كيبيع صوتو فالإنتخابات و كيساهم فصعود منتَخَبين فاسدين.",
    solution: `
  <p>بيع الأصوات في الانتخابات يضر بالديمقراطية ويساهم في صعود مسؤولين غير أكفاء وغير ملتزمين بخدمة الشعب. هذا التصرف يقوض التنمية ويؤدي إلى استدامة الفساد.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li>- توعية المواطنين بخطورة بيع الأصوات على مستقبلهم ومستقبل الأجيال القادمة.</li>
    <li>- تفعيل قوانين صارمة لمعاقبة المتورطين في شراء الأصوات أو بيعها.</li>
    <li>- تعزيز الشفافية في الحملات الانتخابية لمكافحة الفساد الانتخابي.</li>
    <li>- تشجيع المواطنين على اختيار مرشحين بناءً على الكفاءة والنزاهة بدلاً من المصالح الشخصية.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>صوتك هو أمانة، استخدمه بحكمة لبناء مجتمع أفضل ورفض الفساد من جذوره.</p>
  `,
    category: ["social", "work"],
  },
  {
    id: 42,
    behavior: "الهرڭاوي هو لي كيتعدى على المرأة و كيتحرش بيها.",
    solution: `
  <p>التعدي على المرأة والتحرش بها يُعتبران انتهاكًا خطيرًا للكرامة الإنسانية وحقوق المرأة، سواء كان ذلك في الشارع، مكان العمل، أو حتى عبر الوسائط الرقمية. هذه التصرفات تخلق بيئة غير آمنة وتعرقل مساهمة المرأة في المجتمع.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li><strong>- التوعية:</strong> نشر حملات توعوية حول خطورة التحرش وأثره السلبي على الأفراد والمجتمع.</li>
    <li><strong>- التشريع:</strong> تطبيق قوانين صارمة تعاقب المتحرشين وتضمن حق النساء في الأمان.</li>
    <li><strong>- التبليغ:</strong> تشجيع النساء على الإبلاغ عن حالات التحرش وتوفير الحماية والدعم النفسي لهن.</li>
    <li><strong>- التربية:</strong> تعزيز قيم الاحترام والمساواة في المدارس والمنازل لبناء جيل واعٍ يحترم حقوق المرأة.</li>
    <li><strong>- الرقابة:</strong> تفعيل الرقابة في الأماكن العامة وأماكن العمل لضمان بيئة آمنة للجميع.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>كرامة المرأة هي كرامة المجتمع. دعونا نعمل معًا لبناء بيئة تحترم الجميع وتضمن المساواة والأمان.</p>
  `,
    category: [
      "street",
      "social",
      "public",
      "digital",
      "work",
      "school",
      "home",
    ],
  },
  {
    id: 43,
    behavior:
      "الهرڭاوي هو لي كيسوق السيارة أو الموطور ديالو بتهور و كيهدد حياة الناس.",
    solution: `
  <p>القيادة المتهورة تُعرض حياة السائق والآخرين على الطريق لخطر كبير. الحوادث الناتجة عن هذه التصرفات يمكن أن تؤدي إلى خسائر فادحة في الأرواح والممتلكات.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li><strong>- التوعية:</strong> تنظيم حملات توعوية لتذكير السائقين بأهمية احترام قوانين السير وخطورة القيادة المتهورة.</li>
    <li><strong>- التدريب:</strong> تقديم دورات تدريبية حول السلامة الطرقية وتشجيع السائقين على تطوير مهاراتهم في القيادة.</li>
    <li><strong>- القوانين:</strong> تشديد العقوبات على السائقين المتهورين، مثل الغرامات أو سحب رخص القيادة.</li>
    <li><strong>- التكنولوجيا:</strong> استخدام أجهزة مراقبة السرعة ونظام النقاط لرصد السلوكيات الخطيرة على الطريق.</li>
    <li><strong>- المسؤولية المجتمعية:</strong> تشجيع الأفراد على الإبلاغ عن السائقين المتهورين والتوعية بضرورة القيادة بأمان.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>السلامة على الطريق مسؤوليتنا جميعًا. بقيادتك الآمنة، تُساهم في حماية حياتك وحياة الآخرين.</p>
  `,
    category: ["street", "social", "driving"],
  },
  {
    id: 44,
    behavior: "الهرڭاوي هو لي كيوقف فبلاصة ديال ذوي الاحتياجات الخاصة.",
    solution: `
  <p>احتلال أماكن مخصصة لذوي الاحتياجات الخاصة يعوق حقهم في التنقل بحرية وسهولة، ويعبر عن عدم احترام حقوق الآخرين.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li><strong>- التوعية:</strong> نشر حملات إعلامية تشرح أهمية احترام أماكن ذوي الاحتياجات الخاصة، مع تسليط الضوء على معاناتهم عند استخدام تلك الأماكن بشكل غير قانوني.</li>
    <li><strong>- الإشارات:</strong> وضع علامات واضحة على أماكن وقوف السيارات المخصصة لهم، مع تحديد العقوبات عند المخالفة.</li>
    <li><strong>- القوانين:</strong> تطبيق غرامات مالية صارمة أو سحب رخص القيادة عند الوقوف في هذه الأماكن بدون تصريح.</li>
    <li><strong>- التكنولوجيا:</strong> تركيب كاميرات مراقبة لرصد المخالفات وضمان الالتزام.</li>
    <li><strong>- المسؤولية المجتمعية:</strong> تشجيع المواطنين على التبليغ عن المخالفات لزيادة الوعي الجماعي بأهمية احترام حقوق ذوي الاحتياجات الخاصة.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>احترام أماكن ذوي الاحتياجات الخاصة يعكس إنسانيتك ويُساهم في خلق مجتمع عادل للجميع.</p>
  `,
    category: ["social", "public", "health"],
  },
  {
    id: 45,
    behavior: "الهرڭاوي هو لي كيغش في الميزان و كيسرق الناس.",
    solution: `
  <p>الغش في الميزان لا يضر فقط بالزبون بل يهدم الثقة بين أفراد المجتمع ويؤثر سلبًا على الاقتصاد المحلي.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li><strong>- التوعية:</strong> تنظيم حملات توعية للتجار حول أهمية الأمانة في البيع وتأثير الغش على سمعتهم ومجتمعهم.</li>
    <li><strong>- التقنين:</strong> إجراء فحوصات دورية على الموازين من طرف السلطات المختصة للتأكد من مطابقتها للمعايير.</li>
    <li><strong>- العقوبات:</strong> فرض غرامات مالية أو إلغاء تراخيص العمل على المخالفين.</li>
    <li><strong>- التشجيع:</strong> الاعتراف بالممارسات الأخلاقية عبر شهادات أو جوائز لتجار ملتزمين بالنزاهة.</li>
    <li><strong>- التبليغ:</strong> تمكين المواطنين من الإبلاغ عن حالات الغش عبر قنوات سرية وآمنة.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>النزاهة في البيع ليست فقط واجبًا قانونيًا بل مسؤولية أخلاقية تُساهم في بناء مجتمع يسوده الثقة والعدالة.</p>
  `,
    category: ["social", "work"],
  },
  {
    id: 46,
    behavior: "الهرڭاوي هو لي كيحقر الصغير و الضعيف و كيعامل الناس بتكبر.",
    solution: `
  <p>التعامل بتكبر واحتقار الآخرين يعمق الفجوة الاجتماعية ويخلق بيئة سلبية مليئة بالظلم وعدم الاحترام.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li><strong>- التوعية:</strong> نشر ثقافة الاحترام والمساواة بين الناس من خلال برامج تعليمية وإعلامية.</li>
    <li><strong>- التطوير الشخصي:</strong> تشجيع الأفراد على التعاطف مع الآخرين والتفكير في تأثير أفعالهم على المجتمع.</li>
    <li><strong>- القوانين:</strong> وضع قوانين تجرم التمييز وسوء المعاملة في أماكن العمل أو الأماكن العامة.</li>
    <li><strong>- القدوة الحسنة:</strong> تعزيز دور الشخصيات المؤثرة التي تمثل التواضع والاحترام في المجتمع.</li>
    <li><strong>- العمل الجماعي:</strong> تشجيع الأنشطة التي تعزز روح الفريق، حيث يتعلم الأفراد التعاون بغض النظر عن اختلافاتهم.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>التواضع والتعامل بإنسانية مع الجميع يجعلك شخصًا محترمًا ويُبني مجتمعًا قويًا تسوده العدالة.</p>
  `,
    category: ["social", "work"],
  },
  {
    id: 47,
    behavior: "الهرڭاوي هو لي كيلوح القمامة فأي بلاصة.",
    solution: `
  <p>إلقاء القمامة في الشوارع يعكر صفو البيئة ويؤثر سلبًا على صحة المجتمع. فالنفايات التي تترك في الأماكن العامة يمكن أن تؤدي إلى تلوث المياه والهواء، كما تجذب الحشرات والقوارض، مما يزيد من خطر انتشار الأمراض.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li><strong>- استخدام الحاويات:</strong> تأكد من التخلص من القمامة في الحاويات المخصصة لذلك. إذا لم تجد سلة قريبة، احتفظ بالقمامة معك حتى تجد مكانًا مناسبًا.</li>
    <li><strong>- التوعية:</strong> نشر حملات توعية تحث المواطنين على ضرورة الحفاظ على نظافة البيئة والآثار السلبية لإلقاء القمامة في الأماكن العامة.</li>
    <li><strong>- الرقابة:</strong> تعزيز الرقابة في المناطق العامة وتشديد العقوبات على من يلقي القمامة في الأماكن غير المخصصة.</li>
    <li><strong>- المشاركة المجتمعية:</strong> تشجيع الأفراد على المشاركة في حملات تنظيف الأماكن العامة مع التركيز على تنظيف الأحياء والشوارع.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>بيئة نظيفة تبدأ من كل واحد منا. لنعمل معًا لجعل شوارعنا أكثر نظافة وجمالاً.</p>
  `,
    category: ["street", "public", "environment", "health"],
  },
  {
    id: 48,
    behavior: "الهرڭاوي هو لي كيقاطعك فالهضرة و مكيحتارمش الآراء المخالفة.",
    solution: `
  <p>مقاطعة الآخرين أثناء الحديث وعدم احترام الآراء المخالفة يعكس نقصًا في الوعي الثقافي والاجتماعي، وقد يؤدي إلى توتر العلاقات الشخصية وإضعاف الحوار البناء.</p>
  <br>
  <h3><strong>الحل:</strong></h3>
  <ul>
    <li><strong>- التثقيف:</strong> تعزيز ثقافة الحوار والاحترام المتبادل من خلال ورش عمل ومبادرات اجتماعية.</li>
    <li><strong>- الاستماع الفعّال:</strong> تعليم الأفراد مهارات الاستماع الجيد وإعطاء المتحدثين الفرصة للتعبير عن أفكارهم.</li>
    <li><strong>- التوعية:</strong> نشر محتوى توعوي عبر وسائل الإعلام يبرز أهمية قبول وجهات النظر المختلفة.</li>
    <li><strong>- القدوة:</strong> تقديم نماذج إيجابية من قادة ومؤثرين يحترمون الاختلاف ويشجعون الحوار المفتوح.</li>
  </ul>
  <br>
  <h3><strong>الرسالة التوعوية:</strong></h3>
  <p>الاختلاف في الرأي لا يفسد للود قضية. لنبنِ جسور التفاهم بدلًا من حواجز التنافر.</p>
  `,
    category: ["social"],
  },
  {
    id: 49,
    behavior: "الهرڭاوي هو لي كيمارس التنمر الإلكتروني فحق ناس أبرياء.",
    solution: `
    <p>التنمر الإلكتروني يُعد من أخطر الظواهر في العصر الرقمي، حيث يمكن أن يسبب أضرارًا نفسية جسيمة للضحايا، بما في ذلك القلق، والاكتئاب، وحتى الانتحار في بعض الحالات.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> تنظيم حملات توعية تسلط الضوء على تأثير التنمر الإلكتروني وأهمية احترام الآخرين على المنصات الرقمية.</li>
      <li><strong>- الإبلاغ:</strong> تشجيع الضحايا أو الشهود على الإبلاغ عن حالات التنمر عبر المنصات أو الجهات المختصة.</li>
      <li><strong>- التربية:</strong> تعليم الأطفال والمراهقين كيفية استخدام الإنترنت بشكل مسؤول، واحترام الآخرين أثناء التفاعل الرقمي.</li>
      <li><strong>- العقوبات:</strong> تطبيق قوانين صارمة ضد التنمر الإلكتروني، بما في ذلك الغرامات أو السجن حسب شدة الأضرار الناتجة.</li>
      <li><strong>- الدعم النفسي:</strong> توفير خدمات دعم نفسي للضحايا لمساعدتهم على تجاوز الأثر السلبي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>كن صوتًا للخير والاحترام على الإنترنت. العالم الرقمي يجب أن يكون مساحة آمنة للجميع.</p>
    `,
    category: ["social", "digital"],
  },
  {
    id: 50,
    behavior:
      "الهرڭاوي هو لي مكيحتارمش الخصوصية ديال الأفراد و كيتدخل في ما لا يعنيه.",
    solution: `
    <p>التدخل في خصوصية الآخرين يُعتبر تصرفًا غير أخلاقي يؤثر سلبًا على العلاقات الاجتماعية ويخلق حالة من التوتر وعدم الثقة بين الأفراد.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- احترام الخصوصية:</strong> تجنب التدخل في حياة الآخرين أو طرح أسئلة شخصية دون إذن مسبق.</li>
      <li><strong>- التوعية:</strong> نشر ثقافة احترام الخصوصية من خلال حملات توعية تسلط الضوء على أهمية هذا السلوك في بناء مجتمع متماسك.</li>
      <li><strong>- تعلم الحدود:</strong> كن واعيًا لحدودك الشخصية وحدود الآخرين، وتجنب مشاركة أو نشر معلومات شخصية تخص غيرك دون موافقتهم.</li>
      <li><strong>- المحاسبة:</strong> فرض قوانين صارمة على الأفراد أو الجهات التي تتعدى على الخصوصية الرقمية أو الواقعية للأفراد.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>خصوصية الآخرين ليست مجالًا للنقاش أو التدخل. احترامك لها يعكس رقيك واحترامك لذاتك وللمجتمع.</p>
    `,
    category: ["social", "public", "digital"],
  },
  {
    id: 51,
    behavior: "الهرڭاوي هو لي كيكلاكصوني وسط المدينة لأتفه الأسباب.",
    solution: `
    <p>الاستخدام المفرط للكلاكسون في المناطق المزدحمة يتسبب في ضجيج يزعج السكان ويزيد من مستويات التوتر، مما يؤثر سلبًا على جودة الحياة اليومية، خاصة بالنسبة للأطفال وكبار السن.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- استخدام الكلاكسون عند الضرورة فقط:</strong> استعمل المنبه الصوتي فقط في حالات الطوارئ لتفادي الحوادث أو لتحذير السائقين الآخرين.</li>
      <li><strong>- الصبر:</strong> في حالات التأخير البسيطة أو الزحام، تحلّ بالصبر بدلاً من اللجوء إلى الضجيج.</li>
      <li><strong>- احترام القوانين:</strong> التزم بالقوانين التي تحد من استخدام الكلاكسون في المناطق السكنية أو الهادئة.</li>
      <li><strong>- التواصل الإيجابي:</strong> استخدم إشارات اليد أو الإيماءات للتواصل مع السائقين الآخرين بدلاً من الكلاكسون في المواقف غير الضرورية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>بالهدوء واحترام الآخرين، نسهم في تحسين جودة الحياة داخل مدينتنا. كل إشارة صامتة تُظهر أخلاقك واحترامك للبيئة من حولك.</p>
    `,
    category: ["street", "public", "driving"],
  },
  {
    id: 52,
    behavior: "الهرڭاوي هو لي كيعطي الرشوة باش يشد البيرمي بلا كفاءة.",
    solution: `
    <p>الحصول على رخصة القيادة دون كفاءة عبر الرشوة لا يؤدي فقط إلى تدهور القيم الأخلاقية، بل يعرّض حياة السائقين والمشاة للخطر. الأشخاص غير المؤهلين للقيادة قد يتسببون في حوادث مميتة نتيجة نقص المهارة والمعرفة بقوانين السير.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> نشر حملات توعوية تسلط الضوء على خطورة الحصول على رخصة القيادة دون كفاءة وآثارها السلبية.</li>
      <li><strong>- تشديد الرقابة:</strong> تعزيز آليات الرقابة على مدارس تعليم القيادة والاختبارات لضمان النزاهة ومنع الفساد.</li>
      <li><strong>- العقوبات:</strong> تطبيق عقوبات صارمة على الأفراد والمؤسسات المتورطة في تقديم أو قبول الرشوة.</li>
      <li><strong>- التبليغ:</strong> تشجيع المواطنين على الإبلاغ عن أي ممارسات غير قانونية تتعلق بإصدار رخص القيادة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>رخصة القيادة مسؤولية، وليست مجرد وثيقة. اكتسب مهارتك بجدارة، لتحمي نفسك والآخرين على الطريق.</p>
    `,
    category: ["driving"],
  },
  {
    id: 53,
    behavior: "الهرڭاوي هو لي كيخلي بلاصتو موسخة فالبحر.",
    solution: `
    <p>ترك القمامة في الأماكن الساحلية يؤدي إلى تلوث البيئة البحرية، مما يؤثر على الكائنات البحرية وصحة الإنسان. النفايات التي تُترك في الشواطئ قد تنجرف إلى البحر، مما يهدد الحياة البحرية ويقلل من جمالية الشاطئ.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التخلص السليم:</strong> استخدام حاويات النفايات الموجودة على الشاطئ أو الاحتفاظ بالقمامة حتى تجد مكانًا مناسبًا للتخلص منها.</li>
      <li><strong>- التوعية:</strong> تنظيم حملات توعية لتعريف الزوار بأهمية الحفاظ على نظافة الشواطئ وحماية البيئة البحرية.</li>
      <li><strong>- المشاركة المجتمعية:</strong> تنظيم حملات تنظيف دورية للشواطئ بمشاركة السكان المحليين والزوار.</li>
      <li><strong>- القوانين:</strong> تشديد العقوبات على من يلوث الشواطئ لضمان التزام الجميع بالمحافظة على النظافة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على شواطئنا نظيفة وجميلة من أجل بيئة بحرية مستدامة وصحية للجميع.</p>
    `,
    category: ["public", "environment", "health", "animals"],
  },
  {
    id: 54,
    behavior: "الهرڭاوي هو لي كيبول فلابيسين.",
    solution: `
    <p>العبث أو الإهمال في الأماكن العامة مثل الأبسين يمكن أن يتسبب في تلوث البيئة ويؤثر على صحة الأفراد. قد يؤدي ذلك إلى انتشار الجراثيم والأمراض بين الناس بسبب قلة النظافة والإهمال في هذه الأماكن.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> نشر حملات توعية تشدد على ضرورة الحفاظ على النظافة العامة وعدم ترك القمامة في الأماكن العامة مثل الأبسين.</li>
      <li><strong>- الرقابة:</strong> زيادة الرقابة على الأماكن العامة وتكثيف الجهود للحفاظ على نظافة الأبسين وتحديد المسؤوليات في الحفاظ عليها.</li>
      <li><strong>- التشجيع على المشاركة:</strong> دعوة المواطنين للمشاركة في حملات تطوعية لتنظيف الأماكن العامة وزيادة الوعي لدى الجميع حول أهمية العناية بالبيئة.</li>
      <li><strong>- فرض عقوبات:</strong> تشديد العقوبات على من يلوث الأماكن العامة ويهمل النظافة، مع تطبيق قوانين صارمة على المخالفين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>نظافة الأماكن العامة مسؤولية الجميع. لنعمل معًا للحفاظ على بيئة صحية وآمنة.</p>
    `,
    category: ["public", "environment", "health"],
  },
  {
    id: 55,
    behavior: "الهرڭاوي هو لي كيمشي للبحر كيبني خيمة و كيحجب الرؤية على الناس.",
    solution: `
    <p>إقامة خيمة أو أي هياكل أخرى على الشواطئ بطريقة تحجب الرؤية عن الآخرين تؤثر على حرية استخدام الأماكن العامة وتقلل من راحة الزوار. بالإضافة إلى أنها قد تشكل إزعاجًا للأشخاص الذين يرغبون في الاستمتاع بالمنظر أو الفضاء.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> توعية الأشخاص بأهمية احترام المساحة العامة وعدم حجب الرؤية عن الآخرين أثناء تواجدهم على الشاطئ.</li>
      <li><strong>- الأنظمة القانونية:</strong> تطبيق قوانين تمنع بناء الهياكل غير الضرورية التي قد تعيق حرية الآخرين في الاستمتاع بالبيئة الطبيعية.</li>
      <li><strong>- المراقبة:</strong> تعزيز الرقابة على الشواطئ والمناطق العامة لمنع التجاوزات التي تؤدي إلى تقييد حرية الآخرين.</li>
      <li><strong>- الاحترام المتبادل:</strong> تشجيع المواطنين على ممارسة سلوكيات تعكس الاحترام المتبادل عند استخدام الأماكن العامة، مثل الشواطئ.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>احترم حقوق الآخرين واستمتع بالبحر بطريقة لا تضر الآخرين. لنعمل معًا لنحافظ على الأماكن العامة نظيفة ومريحة للجميع.</p>
    `,
    category: ["social", "public"],
  },
  {
    id: 56,
    behavior: "الهرڭاوي هو لي كيڭريسي عباد الله و كيدير السبة بالبطالة.",
    solution: `
    <p>السرقة مهما كانت المبررات غير مقبولة في المجتمع لأنها تضر بالآخرين وتؤدي إلى تفشي الفوضى وعدم الأمن. يجب أن نفهم أن البطالة ليست سببًا لارتكاب الجرائم، وأن هناك دائمًا طرقًا أكثر أخلاقية وحلولا قانونية لمواجهة التحديات الاقتصادية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> نشر الوعي حول الآثار السلبية للسرقة على المجتمع وتشجيع الأفراد على البحث عن حلول مشروعة للتعامل مع البطالة.</li>
      <li><strong>- التدريب والتوظيف:</strong> توفير برامج تدريبية وفرص عمل للشباب العاطل عن العمل لتمكينهم من إيجاد مصادر دخل بديلة، بعيدًا عن السلوكيات السلبية.</li>
      <li><strong>- التدخل الاجتماعي:</strong> تقديم الدعم الاجتماعي والنفسي للأشخاص الذين يعانون من البطالة، ومساعدتهم في التغلب على الضغوط النفسية التي قد تدفعهم إلى اتخاذ قرارات خاطئة.</li>
      <li><strong>- القانون والعدالة:</strong> ضرورة تشديد الرقابة القانونية والعقوبات على الأعمال الإجرامية، مع توفير حلول قانونية لدمج الأشخاص المتورطين في الأنشطة غير القانونية في المجتمع بشكل إيجابي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>البطالة ليست مبررًا للسرقة، فهناك دائمًا طرق قانونية وشريفة لتحسين وضعك المالي. دعونا نتكاتف لتوفير فرص عمل وتحقيق العدالة الاجتماعية.</p>
    `,
    category: ["social"],
  },
  {
    id: 57,
    behavior:
      "الهرڭاوي هو الموظف لي مكيبغيش يدير خدمتو و كيڭوليك سير حتا لغدا.",
    solution: `
    <p>التقصير في العمل من قبل الموظف ينعكس سلبًا على سير العمل ويدمر الثقة بين الموظف والإدارة. يجب أن يكون كل فرد في موقعه مسؤولاً عن عمله وملتزمًا بالمواعيد المحددة لتنفيذ المهام المطلوبة منه. عدم الالتزام بالعمل قد يؤدي إلى تأخير المشاريع وتقديم خدمات غير مرضية للمواطنين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التزام الموظف:</strong> يجب على الموظفين أن يتحملوا مسؤولياتهم في العمل، وأن يلتزموا بالوقت والجهد اللازمين لإتمام المهام المنوطة بهم.</li>
      <li><strong>- التدريب والتطوير:</strong> توفير برامج تدريبية للموظفين لتحسين أدائهم ورفع مستوى التزامهم في أداء مهامهم.</li>
      <li><strong>- التقييم والمراقبة:</strong> يجب إجراء تقييم دوري لأداء الموظفين مع مراقبة مستوى إنجازاتهم وضمان الالتزام بالجداول الزمنية المحددة.</li>
      <li><strong>- العقوبات والتحفيز:</strong> فرض عقوبات على الموظفين الذين يتقاعسون في أداء مهامهم مع توفير مكافآت للتحفيز على الالتزام بالعمل وتحقيق النتائج المرجوة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>النجاح في العمل يأتي من الالتزام والانضباط. فلنحرص جميعًا على أداء واجبنا بكل جهد واحترافية لضمان تحقيق الأهداف المشتركة.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 58,
    behavior: "الهرڭاوي هو الموظف لي كيعامل الناس بتكبر و بدون احترام.",
    solution: `
    <p>التعامل بتكبر وعدم الاحترام من قبل الموظف يؤدي إلى تدهور العلاقات بين الموظف والمواطنين أو الزملاء في العمل، كما يؤثر على صورة المؤسسة ويساهم في انخفاض مستوى الرضا العام. الاحترام المتبادل في بيئة العمل يعد أساسًا لتحقيق تعاون فعّال وإنتاجية عالية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التدريب على مهارات التواصل:</strong> يجب توفير دورات تدريبية لتعزيز مهارات التواصل الفعّال والاحترام بين الموظفين والمواطنين، مع التركيز على أهمية التعامل بلطف واحترام.</li>
      <li><strong>- مراجعة السلوكيات:</strong> يجب مراقبة سلوكيات الموظفين في التعامل مع الجمهور وتقديم الملاحظات بناءً على التقييمات، مع العمل على تصحيح السلوكيات السلبية.</li>
      <li><strong>- العقوبات والتوجيه:</strong> فرض عقوبات مناسبة على الموظفين الذين يظهرون سلوكًا غير لائق، مع توجيههم إلى أهمية تحسين أسلوب التعامل مع الآخرين.</li>
      <li><strong>- بيئة عمل إيجابية:</strong> خلق بيئة عمل تشجع على الاحترام المتبادل والتعاون بين جميع أفراد العمل من خلال المبادرات والأنشطة الجماعية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام هو أساس النجاح في أي بيئة عمل. فلنحرص على أن تكون سلوكياتنا دائمًا نموذجًا في الاحترام والتعاون.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 59,
    behavior: "الهرڭاوي هو المواطن لي مكيحتارمش الموظف.",
    solution: `
    <p>عدم احترام الموظف من قبل المواطنين يؤدي إلى تدهور العلاقة بين الطرفين ويؤثر سلبًا على أداء المؤسسات العامة. من المهم أن يعمل كل من الموظف والمواطن معًا لتحقيق بيئة عمل صحية وفعّالة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> تنظيم حملات توعية للمواطنين حول أهمية احترام الموظفين، ودورهم في تقديم الخدمات للمجتمع، وكيفية التعاون مع الموظف بطريقة محترمة.</li>
      <li><strong>- الاستماع والتفاهم:</strong> تشجيع الموظفين على الاستماع للمواطنين وتقديم الإجابات الواضحة، وفي الوقت نفسه تشجيع المواطنين على التفاهم واحترام الموظفين في مختلف المواقف.</li>
      <li><strong>- تعزيز العلاقات الإيجابية:</strong> تعزيز ثقافة الاحترام المتبادل بين الموظف والمواطن من خلال أنشطة أو برامج مشتركة تعزز التعاون بين الطرفين.</li>
      <li><strong>- العقوبات والإجراءات:</strong> فرض عقوبات مناسبة على الأشخاص الذين يظهرون سلوكًا غير محترم تجاه الموظفين، مع توفير قنوات للمواطنين لتقديم الشكاوى بطريقة منطقية ومهنية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التعامل باحترام هو الأساس في بناء مجتمع متعاون وفعال. لنحترم بعضنا البعض من أجل بيئة أكثر إيجابية.</p>
    `,
    category: ["social", "work", "public"],
  },
  {
    id: 60,
    behavior: "الهرڭاوي هو لي كيسرق الصبابط من الجامع.",
    solution: `
    <p>سرقة الأحذية في أماكن العبادة مثل المساجد تعتبر انتهاكًا لحرمة المكان وتؤثر سلبًا على المجتمع. هذه التصرفات تعكس غياب الاحترام للأماكن المقدسة وتقلل من ثقافة الأمان والثقة بين الأفراد.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز الوعي الديني:</strong> نشر الوعي بين الأفراد حول احترام الأماكن المقدسة وضرورة الحفاظ على ممتلكات الآخرين.</li>
      <li><strong>- مراقبة الأماكن العامة:</strong> تعزيز مراقبة الأماكن العامة مثل المساجد والمرافق الدينية لتوفير بيئة آمنة للمصلين.</li>
      <li><strong>- التعاون مع السلطات:</strong> التعاون مع السلطات المحلية والمجتمع المحلي لوضع حلول فعّالة لتجنب هذه التصرفات، مثل توفير خزائن خاصة لتخزين الأحذية.</li>
      <li><strong>- الردع والتوعية:</strong> فرض عقوبات رادعة لمن يثبت تورطهم في هذه السرقات، وتنظيم حملات توعية داخل المجتمع لتعزيز قيم الأمان والاحترام.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>احترام الأماكن المقدسة وحقوق الآخرين جزء من تعاليم ديننا. دعونا نعمل معًا على الحفاظ على الأمن والاحترام في جميع الأماكن العامة.</p>
    `,
    category: ["religion", "social", "public"],
  },
  {
    id: 61,
    behavior:
      "الهرڭاوي هو لي كيتعامل بتكبر مع السيرفور و مكيخليش ليه pourboire.",
    solution: `
    <p>التعامل بتكبر مع العاملين في المطاعم أو الأماكن العامة يؤثر سلبًا على بيئة العمل ويقوض قيم الاحترام المتبادل. إضافة إلى ذلك، عدم تقديم البقشيش (pourboire) للعاملين الذين يقدمون خدمة متميزة يعتبر إهمالًا لحقهم في الحصول على تقدير مادي مقابل عملهم الشاق.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز ثقافة الاحترام:</strong> يجب تعزيز ثقافة الاحترام بين الأفراد، والتأكيد على أهمية التعامل بأدب وتقدير مع جميع الموظفين بغض النظر عن منصبهم.</li>
      <li><strong>- التوعية بأهمية البقشيش:</strong> توعية الزبائن بأهمية البقشيش كطريقة لتقدير الجهود التي يبذلها العاملون في تقديم الخدمة.</li>
      <li><strong>- تشجيع الابتسامة والتقدير:</strong> تشجيع الأفراد على استخدام الكلمات الطيبة والابتسامة أثناء التعامل مع العاملين، مما يساهم في خلق بيئة إيجابية للجميع.</li>
      <li><strong>- المسؤولية الفردية:</strong> يجب على كل شخص أن يتحمل المسؤولية في تغيير سلوكياته وتحسين الطريقة التي يتعامل بها مع الآخرين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام هو أساس التعامل الجيد. دعونا نخلق بيئة من التعاون والتقدير بين الجميع في مختلف الأماكن.</p>
    `,
    category: ["social", "work", "public"],
  },
  {
    id: 62,
    behavior: "الهرڭاوي هو لي كيخرب الممتلكات العامة.",
    solution: `
    <p>تخريب الممتلكات العامة يعتبر تصرفًا غير مسؤول يؤثر سلبًا على المجتمع والبيئة. هذه التصرفات لا تضر فقط بمرافقنا العامة، بل تؤدي أيضًا إلى تكاليف إضافية لإصلاح الأضرار. بالإضافة إلى ذلك، هذا السلوك يعكس نقصًا في الوعي المدني والاحترام للملكية العامة التي هي ملك للجميع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز الوعي العام:</strong> نشر حملات توعية حول أهمية الحفاظ على الممتلكات العامة وشرح الأضرار التي يمكن أن تنتج عن تخريبها.</li>
      <li><strong>- تطبيق القوانين:</strong> يجب أن تكون هناك عقوبات واضحة للمخالفين الذين يضرون بالمرافق العامة، مع تعزيز الرقابة في الأماكن العامة.</li>
      <li><strong>- المشاركة المجتمعية:</strong> تشجيع الأفراد على المشاركة في حملات لحماية الممتلكات العامة وتنظيف الأماكن العامة.</li>
      <li><strong>- التحفيز على التقدير:</strong> تحفيز الأفراد على تقدير الممتلكات العامة من خلال الأنشطة المجتمعية التي تبرز أهمية الحفاظ على البيئة والمرافق العامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الممتلكات العامة هي مسؤولية كل فرد في المجتمع. لنعمل معًا للحفاظ عليها نظيفة وآمنة للجميع.</p>
    `,
    category: ["social", "public", "street", "school", "environment"],
  },
  {
    id: 63,
    behavior:
      "الهرڭاوي هو لي مكيعطيش الأولوية لكبار السن و النساء الحوامل وذوي الإحتياجات الخاصة فالمقاعد العمومية.",
    solution: `
    <p>عدم إعطاء الأولوية لكبار السن والنساء الحوامل وذوي الإحتياجات الخاصة في الأماكن العامة يعد تصرفًا غير لائق يعكس قلة احترام للحقوق الإنسانية. هذه الفئات تحتاج إلى رعاية واهتمام خاص في الأماكن العامة ووسائل النقل، وذلك لضمان راحتهم وسلامتهم.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- زيادة الوعي:</strong> نشر حملات توعية في المجتمع حول أهمية احترام حقوق الآخرين، خاصة الفئات الضعيفة مثل كبار السن وذوي الإحتياجات الخاصة والنساء الحوامل.</li>
      <li><strong>- توفير المقاعد المخصصة:</strong> التأكد من تخصيص مقاعد واضحة لكبار السن والنساء الحوامل وذوي الإحتياجات الخاصة في وسائل النقل العامة والأماكن العامة.</li>
      <li><strong>- تعزيز القوانين والتشريعات:</strong> فرض قوانين صارمة لضمان توفير الأولوية لهذه الفئات في الأماكن العامة ووسائل النقل، مع تشجيع الأفراد على الامتثال لهذه القوانين.</li>
      <li><strong>- التدريب على التعامل اللائق:</strong> تدريب موظفي وسائل النقل العامة والأماكن العامة على كيفية التعامل مع هذه الفئات وتوفير كل ما يسهل عليهم استخدامها.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام والتعاون هما أساس الحياة المجتمعية. لنعمل جميعًا على إعطاء الأولوية لمن يحتاجون إليها، بما يضمن توفير بيئة أكثر إنسانية لكل أفراد المجتمع.</p>
    `,
    category: ["social", "public", "health", "driving"],
  },
  {
    id: 64,
    behavior: "الهرڭاوي هو لي كيلوح مخلفات البناء في مناطق غير مرخصة.",
    solution: `
    <p>إلقاء مخلفات البناء في أماكن غير مرخصة يتسبب في تلوث البيئة ويضر بالصحة العامة. هذا التصرف يؤدي إلى تدهور المظهر الجمالي للمدن والمناطق السكنية ويسهم في تلوث الهواء والماء. بالإضافة إلى ذلك، يمكن أن يشكل خطرًا على الحياة البرية والنباتات في المنطقة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الالتزام بالقوانين:</strong> على الجميع الالتزام بالقوانين التي تنظم التخلص من مخلفات البناء. يجب على الشركات والأفراد تفريغ هذه المخلفات في الأماكن المخصصة لذلك.</li>
      <li><strong>- تشجيع إعادة التدوير:</strong> تشجيع مشاريع إعادة تدوير المواد البنائية بدلاً من التخلص منها بشكل غير قانوني. يمكن إعادة استخدام العديد من المواد في مشاريع أخرى.</li>
      <li><strong>- تشديد الرقابة:</strong> على السلطات المحلية تعزيز الرقابة على الأماكن العامة ومراقبة الأماكن التي يتم فيها التخلص من مخلفات البناء، مع فرض غرامات على المخالفين.</li>
      <li><strong>- التوعية المجتمعية:</strong> نشر الوعي حول أهمية الحفاظ على نظافة البيئة وتوجيه الأفراد والشركات إلى سبل التخلص السليم من النفايات البنائية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>بيئة نظيفة تبدأ من عاداتنا اليومية. لنعمل معًا للحفاظ على مدننا وجعلها أكثر جمالًا وأمانًا.</p>
    `,
    category: ["street", "public", "environment"],
  },
  {
    id: 65,
    behavior: "الهرڭاوي هو لي كيربي حيوان أليف و مكيتهلاش فيه.",
    solution: `
    <p>الاهتمام بالحيوانات الأليفة هو مسؤولية كبيرة تتطلب رعاية جيدة لضمان صحتها وسعادتها. عدم توفير الرعاية المناسبة للحيوان الأليف يمكن أن يؤدي إلى تدهور صحته ويؤثر على سلوكه، مما يعرضه للإصابة بالأمراض أو حتى الموت.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- توفير الغذاء والماء:</strong> يجب على صاحب الحيوان الأليف توفير طعام وماء مناسبين له بشكل دائم.</li>
      <li><strong>- الرعاية الصحية:</strong> يجب متابعة الزيارات البيطرية المنتظمة للحيوان، بما في ذلك التطعيمات والعناية بالصحة العامة.</li>
      <li><strong>- الاهتمام بالبيئة:</strong> يجب أن يعيش الحيوان في بيئة نظيفة وآمنة، مع توفير مساحة كافية له للحركة.</li>
      <li><strong>- التفاعل واللعب:</strong> يجب أن يتم منح الحيوان الأليف وقتًا مناسبًا للتفاعل واللعب لضمان رفاهيته النفسية والجسدية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الحيوانات الأليفة هي جزء من العائلة، وعلينا أن نتحمل مسؤوليتها بكل حب ورعاية.</p>
    `,
    category: ["animals", "home"],
  },
  {
    id: 66,
    behavior: "الهرڭاوي هو لي كيخلي ولادو يلعبو الكرة قدام بيبان الناس.",
    solution: `
    <p>لعب الأطفال في الأماكن العامة قد يكون ممتعًا لهم، ولكنه قد يسبب إزعاجًا للجيران أو يعرضهم للخطر إذا كانت الكرة تتسبب في تحطيم الممتلكات أو دخولها إلى الأماكن التي يمكن أن تكون خطرة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- اختيار الأماكن المناسبة:</strong> يجب على الآباء اختيار أماكن آمنة وواسعة للعب أطفالهم، مثل الملاعب المخصصة لذلك أو الحدائق العامة التي توفر بيئة آمنة.</li>
      <li><strong>- مراقبة الأطفال:</strong> يجب على الآباء مراقبة أطفالهم أثناء اللعب والتأكد من عدم تعريضهم للأذى أو إزعاج الآخرين.</li>
      <li><strong>- التوعية:</strong> توعية الأطفال بأهمية احترام خصوصية الآخرين وعدم اللعب في الأماكن غير المخصصة لهم.</li>
      <li><strong>- تنظيم الأنشطة:</strong> تشجيع الأطفال على المشاركة في الأنشطة الرياضية في أماكن مخصصة، مما يسهم في تطوير مهاراتهم ويعزز سلوكهم الرياضي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>اللعب حق للأطفال، ولكن يجب أن يكون في مكان آمن ومحترم لحقوق الآخرين. لنعمل معًا لخلق بيئة مثالية للعب والنمو.</p>
    `,
    category: ["street", "social", "public", "children"],
  },
  {
    id: 67,
    behavior: "الهرڭاوي هو لي كيدير عرس و كيشعل الموسيقى حتى ل 4 ديال الصباح.",
    solution: `
    <p>الاحتفالات مثل الأعراس هي مناسبات سعيدة، لكن إزعاج الآخرين من خلال تشغيل الموسيقى بصوت عالٍ في الساعات المتأخرة من الليل قد يؤدي إلى تعطيل الراحة العامة ويسبب مشاكل مع الجيران.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الاحترام والمرونة:</strong> يجب أن يكون هناك توازن بين الاحتفال واحترام راحة الآخرين. يمكن تقليل حجم الصوت أو إنهاء الحفل في ساعات معقولة لضمان عدم إزعاج الجيران.</li>
      <li><strong>- اختيار المواقع المناسبة:</strong> من الأفضل إقامة الحفلات في أماكن معزولة أو قاعات مخصصة لذلك، بعيدًا عن المناطق السكنية لتقليل الإزعاج.</li>
      <li><strong>- تنظيم الأنشطة:</strong> يمكن تحديد توقيت مناسب للاحتفال مثل البدء في وقت مبكر والانتهاء في وقت معقول حتى لا يؤثر على راحة الآخرين.</li>
      <li><strong>- التواصل مع الجيران:</strong> قبل تنظيم الحفل، يجب التواصل مع الجيران وإبلاغهم بالتفاصيل وتحديد ساعات الاحتفال، مما يسهم في تجنب أي مشاكل.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحتفال من حق الجميع، ولكن يجب أن يكون مع احترام للآخرين. لنحترم راحة جيراننا ونحتفل بمسؤولية.</p>
    `,
    category: ["social", "public"],
  },
  {
    id: 68,
    behavior: "الهرڭاوي هو السائق لي كيوقف فوسط الطريق باش يسلم على صاحبو.",
    solution: `
    <p>إيقاف السيارة وسط الطريق دون مبرر يعطل حركة المرور ويعرض السائقين والمشاة للخطر. من المهم الحفاظ على انسيابية حركة السير لتجنب الحوادث وضمان السلامة للجميع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- احترام قواعد المرور:</strong> يجب على السائقين الالتزام بقواعد المرور وعدم الوقوف في أماكن غير مخصصة لذلك. يمكنهم استخدام أماكن مخصصة للتوقف مثل مواقف السيارات أو جوانب الطريق الآمنة.</li>
      <li><strong>- التفكير في السلامة:</strong> يجب أن يتذكر السائقون أن سلامة الجميع هي الأولوية. التوقف المفاجئ في وسط الطريق يمكن أن يتسبب في حوادث، خاصةً في الأماكن المزدحمة أو أثناء القيادة بسرعة.</li>
      <li><strong>- التواصل مع الآخرين بحذر:</strong> إذا كان السائق يريد التحدث مع شخص آخر، يجب عليه التوقف في مكان آمن بعيدًا عن الطريق العام.</li>
      <li><strong>- الوعي بالمخاطر:</strong> يجب توعية السائقين بأهمية احترام حركة المرور وكيفية تجنب الإضرار بالآخرين أثناء القيادة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>السير بأمان يبدأ من كل واحد منا. دعونا نحافظ على سلامة الطريق ونتجنب التصرفات التي تعرضنا للخطر.</p>
    `,
    category: ["street", "driving"],
  },
  {
    id: 69,
    behavior: "الهرڭاوي هو لي كيخلي تيليفون ديالو يصوني بصوت عالي فالمسجد.",
    solution: `
    <p>إبقاء الهاتف يصوني بصوت عالٍ في أماكن مثل المساجد يسبب إزعاجًا للآخرين ويعكر صفو المكان الذي يُفترض أن يكون هادئًا ومخصصًا للعبادة. من المهم أن نلتزم بآداب الأماكن العامة والمقدسة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- إيقاف الصوت:</strong> يجب إيقاف الصوت أو وضع الهاتف على وضع "صامت" قبل الدخول إلى الأماكن المقدسة أو العامة.</li>
      <li><strong>- التوعية:</strong> نشر حملات توعية في المجتمع حول أهمية احترام الأماكن المقدسة وعدم إزعاج الآخرين.</li>
      <li><strong>- التفكير في الآخرين:</strong> إذا كان الهاتف ضروريًا، يمكن استخدام الوضع "الاهتزاز" بدلاً من الصوت لضمان عدم إزعاج الآخرين.</li>
      <li><strong>- مراعاة حرمة المكان:</strong> يجب احترام قدسية المكان والتركيز على العبادة دون الانشغال بالمكالمات الهاتفية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>نحن مسؤولون عن احترام أماكن العبادة والآخرين. لنجعل بيئاتنا أكثر هدوءًا وراحة للجميع.</p>
    `,
    category: ["public", "social", "religion"],
  },
  {
    id: 70,
    behavior: "الهرڭاوي هو لي كيصور الناس بلا إذن ديالهم.",
    solution: `
    <p>تصوير الأشخاص دون إذن منهم يعد انتهاكًا لخصوصيتهم وقد يسبب لهم الإحراج أو القلق. يجب على الجميع احترام خصوصية الآخرين في الأماكن العامة والخاصة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- طلب الإذن:</strong> قبل التقاط أي صورة لشخص آخر، يجب أن تطلب إذنه أو موافقته الصريحة.</li>
      <li><strong>- احترام الخصوصية:</strong> يجب على كل فرد أن يكون على وعي بأن لكل شخص الحق في أن يحافظ على خصوصيته، خاصة في الأماكن العامة.</li>
      <li><strong>- التوعية:</strong> نشر حملات توعية حول أهمية احترام خصوصية الآخرين في عصر التصوير الرقمي.</li>
      <li><strong>- التحقق من النية:</strong> يجب التأكد من أن الهدف من التقاط الصور لا يهدف إلى التسبب في إزعاج أو تهديد للآخرين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>احترام خصوصية الآخرين يعكس احترامنا لأنفسنا. لنبني معًا بيئة من الأمان والاحترام المتبادل.</p>
    `,
    category: ["social", "public", "digital"],
  },
  {
    id: 71,
    behavior:
      "الهرڭاوي هو لي كيعلق الحوايج فالبالكون و كيخلي الما يقطر على الناس.",
    solution: `
    <p>تعليق الملابس في البالكون بشكل غير مناسب يمكن أن يسبب إزعاجًا للآخرين، خاصة إذا كانت المياه تتساقط على المارة. هذا التصرف يعتبر غير مهذب وغير مسؤول تجاه الجيران والمجتمع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الاحترام المتبادل:</strong> يجب على كل فرد احترام حقوق جيرانه وعدم إزعاجهم بتصرفات غير لائقة.</li>
      <li><strong>- استخدام الأماكن المخصصة:</strong> يجب تجنب تعليق الملابس في البالكون إذا كان هناك خطر من تساقط المياه أو أي أضرار أخرى للمارة.</li>
      <li><strong>- الانتباه للصيانة:</strong> تأكد من أن الحوايج معلقه بشكل صحيح ولا تسمح بتساقط الماء على الأشخاص في الأسفل.</li>
      <li><strong>- التواصل مع الجيران:</strong> إذا كان هناك أي مشكلة مع تعليق الملابس أو تساقط المياه، يجب التواصل مع الجيران لحل المشكلة بشكل ودي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام المتبادل بين الجيران يعزز من جو الود والأمان في المجتمع. لنعمل معًا للحفاظ على بيئتنا نظيفة وآمنة.</p>
    `,
    category: ["street", "social", "public", "home"],
  },
  {
    id: 72,
    behavior: "الهرڭاوي هو لي كيدير مقهى و كيحط الكراسي كاملين فالتروطوار.",
    solution: `
    <p>احتلال الأرصفة بالكراسي والطاولات يعوق حركة المارة، خاصة كبار السن وذوي الاحتياجات الخاصة. الأرصفة ملك عام مخصص للجميع، ويجب احترام هذه المساحات لضمان سلامة وراحة المواطنين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الالتزام بالقانون:</strong> على أصحاب المقاهي احترام القوانين المحلية المتعلقة باستخدام الأرصفة العامة.</li>
      <li><strong>- ترك مساحة كافية:</strong> توفير ممر مريح وآمن للمارة عند وضع الكراسي والطاولات.</li>
      <li><strong>- التنسيق مع السلطات:</strong> طلب التصاريح اللازمة وضمان الالتزام بالتوجيهات المحلية.</li>
      <li><strong>- التوعية:</strong> رفع وعي أصحاب الأعمال والمواطنين بأهمية احترام الأماكن العامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الأرصفة حق للجميع، فلنحترم مساحاتنا المشتركة ونضمن راحة وسلامة الجميع.</p>
    `,
    category: ["street", "social", "public", "work"],
  },
  {
    id: 73,
    behavior: "الهرڭاوي هو لي كيبني و كيخلي مواد البناء فالزنقة شهور.",
    solution: `
    <p>ترك مواد البناء في الشوارع لفترات طويلة يعرقل حركة السير، يشوه المنظر العام، وقد يؤدي إلى حوادث للمارة والسيارات. من المهم ضمان تنظيم هذه المواد لتجنب الإزعاج والمخاطر.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تنظيم التخزين:</strong> ترتيب مواد البناء بشكل آمن في أماكن مخصصة بعيدًا عن الطريق العام.</li>
      <li><strong>- الالتزام بالقوانين:</strong> الحصول على تصاريح رسمية للبناء وتحديد فترة محددة لإنجاز العمل.</li>
      <li><strong>- إزالة المخلفات:</strong> تنظيف المكان فور انتهاء العمل لضمان عودة الشارع إلى وضعه الطبيعي.</li>
      <li><strong>- التوعية:</strong> نشر حملات توعية لتوضيح تأثير ترك مواد البناء في الأماكن العامة على المجتمع.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنبني مدنًا منظمة وجميلة، نحتاج إلى احترام حقوق الآخرين ومساحاتنا العامة.</p>
    `,
    category: ["street", "social", "public", "environment"],
  },
  {
    id: 74,
    behavior: "الهرڭاوي هو لي كيكري دار و مكيخلصش الكرا فالوقت.",
    solution: `
    <p>عدم دفع الإيجار في الوقت المتفق عليه يشكل عبئًا ماليًا على المالك وقد يؤدي إلى نزاعات قانونية أو شخصية. الالتزام بالاتفاقات المالية يعكس احترام الشخص لحقوق الآخرين ومصداقيته.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- وضع خطة مالية:</strong> تنظيم المصاريف الشهرية لضمان توفير مبلغ الإيجار في الموعد المحدد.</li>
      <li><strong>- التواصل:</strong> إذا حدثت ظروف طارئة، يجب التحدث مع المالك ومحاولة الوصول إلى اتفاق مؤقت.</li>
      <li><strong>- العقود الرسمية:</strong> تأكيد كتابة عقد يحدد حقوق وواجبات الطرفين بوضوح، بما في ذلك مواعيد الدفع والالتزامات المترتبة عند التأخر.</li>
      <li><strong>- الاحترام المتبادل:</strong> بناء علاقة قائمة على الاحترام والثقة بين المؤجر والمستأجر لتجنب الخلافات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التزامك بمواعيد الإيجار يعكس مسؤوليتك واحترامك لحقوق الآخرين.</p>
    `,
    category: ["social", "home"],
  },
  {
    id: 75,
    behavior: "الهرڭاوي هو لي كيخدم فالمقهى و كيعامل الزبناء بقلة احترام.",
    solution: `
    <p>معاملة الزبائن بقلة احترام تؤثر سلبًا على سمعة المقهى وتجربة العملاء، وقد تؤدي إلى فقدان الزبائن وثقتهم. التفاعل الإيجابي مع الزبائن هو أساس نجاح أي عمل يقدم خدمات عامة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التدريب:</strong> توفير تدريب منتظم للموظفين على أساليب التعامل مع الزبائن باحترام ولباقة.</li>
      <li><strong>- بيئة عمل إيجابية:</strong> خلق جو عمل يحفز الموظفين على تقديم أفضل خدمة ويحد من الضغوط التي قد تؤثر على تعاملهم.</li>
      <li><strong>- الاستماع للزبائن:</strong> متابعة شكاوى واقتراحات الزبائن والعمل على تحسين جودة الخدمة.</li>
      <li><strong>- مكافأة الموظفين:</strong> تشجيع الموظفين الملتزمين بأسلوب تعامل راقٍ من خلال مكافآت وتحفيزات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>ابتسامتك وكلماتك الطيبة هي مفتاح كسب ولاء الزبائن ونجاح مكان عملك.</p>
    `,
    category: ["work", "social"],
  },
  {
    id: 76,
    behavior: "الهرڭاوي هو لي كيشعل النار فالزبل قدام الدار.",
    solution: `
    <p>إشعال النار في القمامة أمام المنازل يسبب أضرارًا بيئية وصحية جسيمة. الأدخنة الناتجة عن الحرق تحتوي على مواد سامة تؤثر على جودة الهواء وتعرض صحة السكان للخطر، خاصة الأطفال وكبار السن.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التخلص السليم من النفايات:</strong> وضع القمامة في الأماكن المخصصة لها وتجنب الحرق تمامًا.</li>
      <li><strong>- التوعية:</strong> توجيه حملات توعوية للمجتمع حول مخاطر حرق النفايات على الصحة والبيئة.</li>
      <li><strong>- الإبلاغ:</strong> تشجيع السكان على الإبلاغ عن حالات حرق القمامة في الأحياء لتتخذ الجهات المختصة الإجراءات اللازمة.</li>
      <li><strong>- بدائل للحرق:</strong> تعزيز خدمات إعادة التدوير وتوفير حاويات للنفايات العضوية وغير العضوية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الحفاظ على صحة بيئتنا يبدأ بتصرفاتنا اليومية. لنتوقف عن حرق النفايات ونساهم في بيئة نظيفة وآمنة للجميع.</p>
    `,
    category: ["street", "social", "public", "environment"],
  },
  {
    id: 77,
    behavior: "الهرڭاوي هو لي كيحط سلعة ديالو كاملة فالرصيف و كيسد الطريق.",
    solution: `
    <p>وضع البضائع على الأرصفة يعيق حركة المارة، خاصة كبار السن وذوي الاحتياجات الخاصة. كما يؤدي إلى تشويه المنظر العام ويخلق حالة من الفوضى في الشوارع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تنظيم أماكن العرض:</strong> تخصيص مساحات محددة للتجار لعرض بضائعهم بعيدًا عن الأرصفة المخصصة للمشاة.</li>
      <li><strong>- التوعية:</strong> توعية التجار بأهمية احترام الأرصفة وحقوق المارة.</li>
      <li><strong>- الرقابة:</strong> تعزيز الرقابة من قبل السلطات المحلية لتجنب استغلال الأرصفة بطرق غير قانونية.</li>
      <li><strong>- بدائل عملية:</strong> توفير أسواق أو مناطق مخصصة للتجارة لتجنب استخدام الأرصفة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الأرصفة حق للجميع، فلنحترمها ونسهم في تنظيم شوارعنا لضمان سلامة المارة وراحة الجميع.</p>
    `,
    category: ["street", "social", "public", "work"],
  },
  {
    id: 78,
    behavior: "الهرڭاوي هو لي كيدير مطعم و كيرمي الزيت المستعمل فالقادوس.",
    solution: `
    <p>التخلص من الزيت المستعمل في المصارف (القواديس) يسبب انسدادها ويؤدي إلى تلوث المياه الجوفية والبيئة بشكل عام، مما يشكل خطرًا على الصحة العامة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التخلص السليم:</strong> جمع الزيت المستعمل في أوعية مخصصة وتسليمه لشركات إعادة التدوير أو التخلص منه بطريقة صحية وآمنة.</li>
      <li><strong>- التوعية:</strong> توعية أصحاب المطاعم بالمخاطر البيئية والصحية الناتجة عن تصريف الزيت في المصارف.</li>
      <li><strong>- الرقابة:</strong> فرض غرامات على المخالفين وتشديد الرقابة لضمان الالتزام بالقوانين البيئية.</li>
      <li><strong>- البدائل:</strong> استخدام تقنيات إعادة تدوير الزيت المستعمل لتصنيعه في منتجات أخرى كالصابون أو الوقود الحيوي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>حماية البيئة تبدأ من احترام أبسط التفاصيل. تخلص من الزيوت المستعملة بطرق آمنة لتحافظ على صحتنا وصحة كوكبنا.</p>
    `,
    category: ["work", "environment", "health"],
  },
  {
    id: 79,
    behavior: "الهرڭاوي هو لي كيخلي ولادو يكتبو على الحيوط.",
    solution: `
    <p>الكتابة على الجدران تُعد من السلوكيات التي تشوه جمال الفضاءات العامة والخاصة وتؤدي إلى هدر الموارد عند الحاجة لإصلاحها. كما تعكس عدم المسؤولية لدى الأطفال وغياب التوجيه من أولياء الأمور.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التربية والتوعية:</strong> توعية الأطفال بأهمية الحفاظ على الممتلكات العامة والخاصة وتعليمهم السلوك الصحيح في التعبير عن إبداعاتهم.</li>
      <li><strong>- توفير بدائل:</strong> إنشاء أماكن مخصصة للرسم والتعبير الفني، مثل لوحات جدارية مخصصة لهذا الغرض.</li>
      <li><strong>- القدوة الحسنة:</strong> أن يكون الآباء قدوة في احترام الممتلكات العامة وتشجيع الأطفال على فعل الشيء نفسه.</li>
      <li><strong>- العقوبات التربوية:</strong> تحفيز الأطفال على تصحيح أفعالهم، مثل تنظيف الجدران التي شوهوا مظهرها.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الحفاظ على جمال أحيائنا ومدارسنا يبدأ من غرس القيم الصحيحة في أطفالنا. لنعلمهم أن الجدران ليست لوحات للرسم العشوائي.</p>
    `,
    category: ["children", "street", "social", "public", "school"],
  },
  {
    id: 80,
    behavior: "الهرڭاوي هو لي كيشري حوايج مسروقة و هو عارف.",
    solution: `
    <p>شراء الأشياء المسروقة يُعد تواطؤًا مع الجريمة ويسهم في تشجيع السلوكيات غير القانونية. كما يؤدي إلى زيادة انتشار السرقات بسبب وجود طلب على هذه البضائع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الوعي القانوني:</strong> توعية الناس بأن شراء المسروقات جريمة يعاقب عليها القانون، إلى جانب إسهامها في تشجيع المجرمين.</li>
      <li><strong>- التأكد من المصدر:</strong> يجب على المشتري التحقق من مصدر البضائع والتأكد من أنها ليست مسروقة.</li>
      <li><strong>- الإبلاغ:</strong> الإبلاغ عن بائعي الأشياء المسروقة للمساهمة في محاربة الجريمة وحماية المجتمع.</li>
      <li><strong>- تشجيع النزاهة:</strong> تعزيز قيم الأمانة واحترام حقوق الآخرين من خلال الحملات التوعوية والمبادرات المجتمعية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>عندما تشتري شيئًا مسروقًا، فأنت تشارك في الجريمة بشكل غير مباشر. اختر النزاهة ودعنا نعمل معًا لبناء مجتمع أكثر أمانًا.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 81,
    behavior: "الهرڭاوي هو لي كيتكلم بصوت عالي فأماكن عامة.",
    solution: `
    <p>التحدث بصوت عالٍ في الأماكن العامة يزعج الآخرين ويقلل من احترام الخصوصية والراحة للناس المحيطين. الأماكن العامة مثل وسائل النقل والمقاهي تحتاج إلى الحفاظ على مستوى معين من الهدوء.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> نشر الوعي بأهمية احترام الآخرين في الأماكن العامة والالتزام بالأداب العامة.</li>
      <li><strong>- إدارة السلوك:</strong> تقليل استخدام الصوت العالي واختيار التحدث بنبرة هادئة، خاصة في الأماكن المغلقة أو المزدحمة.</li>
      <li><strong>- تعليم الأطفال:</strong> غرس قيم الاحترام والهدوء لدى الأطفال منذ الصغر لضمان التزامهم بهذه القيم في المستقبل.</li>
      <li><strong>- وضع الإشارات:</strong> إضافة لافتات في الأماكن العامة تذكر بضرورة التزام الهدوء واحترام الآخرين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الصوت الهادئ علامة على الاحترام والرقي. لنجعل من الأماكن العامة مساحات مريحة للجميع.</p>
    `,
    category: ["street", "social", "public"],
  },
  {
    id: 82,
    behavior: "الهرڭاوي هو لي كيحط الطوموبيل قدام الكراج ديال الناس.",
    solution: `
    <p>إيقاف السيارة أمام كراج شخص آخر يعطل حركة المرور ويزعج الجيران، حيث قد يحرمهم من القدرة على دخول أو الخروج من منازلهم. هذه التصرفات تؤثر سلبًا على العلاقات المجتمعية وتخلق توترًا بين الأفراد.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الاحترام المتبادل:</strong> احترام حقوق الآخرين وعدم الوقوف في الأماكن المخصصة لهم، مثل مواقف السيارات أمام الكراجات.</li>
      <li><strong>- التوعية:</strong> نشر الوعي بين السائقين حول أهمية احترام أماكن وقوف السيارات وعدم إعاقة حركة المرور أو وصول الآخرين إلى منازلهم.</li>
      <li><strong>- استخدام مواقف مخصصة:</strong> البحث عن مواقف سيارات قانونية ومخصصة بدلاً من الوقوف في أماكن تعطل الآخرين.</li>
      <li><strong>- عقوبات صارمة:</strong> تشديد الرقابة وتطبيق غرامات على السيارات المتوقفة بشكل غير قانوني لتفادي هذه التصرفات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام المتبادل يبدأ من احترام المساحات المشتركة، لنعمل معًا لتحقيق بيئة حضرية منظمة وسلسة.</p>
    `,
    category: ["driving", "street", "public", "social"],
  },
  {
    id: 83,
    behavior: "الهرڭاوي هو لي كيبيع الدوا فالسوق بلا رخصة.",
    solution: `
    <p>بيع الأدوية في الأسواق بدون ترخيص يمثل خطرًا كبيرًا على الصحة العامة، حيث يمكن أن يؤدي إلى تداول أدوية مغشوشة أو غير مناسبة للاستخدام، مما يعرض حياة الأشخاص للخطر.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التشديد على الرقابة:</strong> يجب على السلطات المعنية تعزيز الرقابة على الأسواق لضمان أن الأدوية المتداولة هي أدوية مرخصة وآمنة للاستخدام.</li>
      <li><strong>- التوعية:</strong> من الضروري توعية المواطنين حول خطر شراء الأدوية من مصادر غير مرخصة وأهمية الحصول على الأدوية فقط من الصيدليات المعتمدة.</li>
      <li><strong>- توفير أدوية بأسعار معقولة:</strong> يجب تشجيع توفير الأدوية بأسعار مناسبة في الصيدليات المعتمدة لكي يكون في متناول الجميع ويحافظ على صحتهم.</li>
      <li><strong>- التعامل مع المخالفين:</strong> يجب فرض عقوبات صارمة على الأشخاص الذين يبيعون الأدوية بشكل غير قانوني لضمان حماية الصحة العامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>صحتك أمانة، لا تستهين بها. احرص على شراء الأدوية من أماكن مرخصة لضمان سلامتك.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 84,
    behavior: "الهرڭاوي هو لي كيدير حفرة فالزنقة بلا رخصة و ميعلمهاش.",
    solution: `
    <p>إحداث حفرة في الطريق بدون ترخيص أو إشعار يمثل خطرًا كبيرًا على السلامة العامة. فقد تؤدي هذه الحفر إلى وقوع حوادث سير خطيرة، بالإضافة إلى تعرض الأشخاص للخطر خاصة في فترات الظلام أو خلال الظروف الجوية السيئة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التصريح والترخيص:</strong> يجب على كل شخص يرغب في القيام بأعمال حفر في الشوارع الحصول على التصاريح اللازمة من السلطات المحلية، لضمان سلامة الطريق والمارة.</li>
      <li><strong>- وضع إشارات تحذيرية:</strong> يجب أن يتم وضع إشارات تحذيرية واضحة حول الحفر التي تم إنشاؤها، حتى تكون مرئية للسائقين والمشاة، مما يساعد في تجنب الحوادث.</li>
      <li><strong>- القيام بالترميم الفوري:</strong> على الشخص الذي قام بالحفر إتمام العمل بسرعة وملء الحفرة فورًا بعد الانتهاء من الأعمال لتفادي إحداث أضرار للطريق.</li>
      <li><strong>- تشديد الرقابة:</strong> ينبغي تعزيز الرقابة على من يقومون بهذه الأعمال، مع فرض عقوبات على المخالفين الذين يتسببون في مخاطر على السلامة العامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>سلامتك وسلامة الآخرين مسؤولية الجميع. احرص على اتباع القوانين وإشعار الآخرين عند القيام بأي أعمال في الشوارع.</p>
    `,
    category: ["street", "social", "public", "driving"],
  },
  {
    id: 85,
    behavior: "الهرڭاوي هو لي كيخلي مواد خطيرة فين يلعبو الدراري الصغار.",
    solution: `
    <p>ترك المواد الخطيرة في أماكن اللعب يعرض الأطفال لخطر كبير. هذه المواد قد تشمل الزجاج المكسور، النفايات السامة، أو المواد الحادة التي قد تتسبب في إصابات خطيرة للأطفال.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التنظيف والتخلص السليم:</strong> يجب على الأفراد والشركات التخلص من المواد الخطيرة بطرق آمنة في الأماكن المخصصة لذلك، وعدم تركها في الأماكن العامة أو بالقرب من مناطق لعب الأطفال.</li>
      <li><strong>- التوعية:</strong> نشر حملات توعية حول مخاطر ترك المواد الخطيرة في الأماكن العامة، وخاصة في المناطق التي يكثر فيها الأطفال.</li>
      <li><strong>- الرقابة والجزاءات:</strong> يجب فرض رقابة صارمة على الأماكن العامة والمرافق التي تتجمع فيها المواد الخطرة، مع فرض عقوبات على المخالفين الذين يتركونها في الأماكن العامة.</li>
      <li><strong>- إصلاح البنية التحتية:</strong> توفير مساحات آمنة للأطفال للعب والتأكد من أن هذه الأماكن خالية من أي مواد قد تشكل خطرًا عليهم.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على سلامة أطفالنا من خلال توفير بيئة آمنة للعب، ولنحترم القوانين التي تحمي صحة المجتمع.</p>
    `,
    category: ["street", "social", "public", "work", "environment", "health"],
  },
  {
    id: 86,
    behavior: "الهرڭاوي هو لي كيبيع المخدرات قدام المدرسة.",
    solution: `
    <p>بيع المخدرات بالقرب من المدارس يعرض حياة الأطفال والمراهقين للخطر ويؤثر على صحتهم العقلية والجسدية. هذه الأنشطة غير القانونية تضر بالمجتمع وتؤدي إلى انتشار الجريمة والمشاكل الاجتماعية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التعاون مع السلطات:</strong> يجب على المجتمع التعاون مع الشرطة والسلطات المحلية للإبلاغ عن هذه الأنشطة غير القانونية في المناطق القريبة من المدارس.</li>
      <li><strong>- التوعية:</strong> تنظيم حملات توعية في المدارس والمجتمع حول مخاطر المخدرات وأثرها المدمر على الصحة والعلاقات الاجتماعية.</li>
      <li><strong>- الرقابة الأمنية:</strong> تعزيز الرقابة الأمنية حول المدارس، وتنظيم حملات التفتيش للحد من بيع المخدرات في الأماكن العامة القريبة من هذه المؤسسات التعليمية.</li>
      <li><strong>- البرامج التعليمية:</strong> تقديم برامج تربوية للطلاب حول كيفية الابتعاد عن المخدرات وأهمية اتخاذ قرارات صحية تؤثر بشكل إيجابي على حياتهم المستقبلية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>مستقبل أطفالنا يعتمد على بيئة آمنة وصحية. لنعمل معًا للقضاء على ظاهرة بيع المخدرات وحماية أجيالنا القادمة.</p>
    `,
    category: ["social", "school", "health"],
  },
  {
    id: 87,
    behavior: "الهرڭاوي هو لي كيدير كاميرا تصور دار الجيران.",
    solution: `
    <p>تركيب كاميرات لتصوير منازل الجيران يعد انتهاكًا للخصوصية ويضر بالثقة بين الأفراد في المجتمع. هذا السلوك قد يؤدي إلى شعور الجيران بعدم الأمان وقد يسبب مشاكل قانونية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- احترام الخصوصية:</strong> يجب على الجميع احترام خصوصية الآخرين وعدم التدخل في حياتهم الخاصة. تركيب كاميرات مراقبة يجب أن يكون قانونيًا وبتصريح رسمي من الجهات المختصة.</li>
      <li><strong>- التوعية:</strong> نشر الوعي حول أهمية الخصوصية واحترامها، وتعريف الناس بالعواقب القانونية والاجتماعية لانتهاكها.</li>
      <li><strong>- حل النزاعات بطرق سلمية:</strong> إذا كان هناك مشكلة مع الجيران، يجب محاولة حلها بطرق سلمية عن طريق الحوار بدلاً من اللجوء إلى انتهاك خصوصيتهم.</li>
      <li><strong>- القوانين المحلية:</strong> يجب الالتزام بالقوانين المحلية التي تحمي الخصوصية وتمنع التصوير غير القانوني للمنازل.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الخصوصية حق للجميع. لنعمل معًا على احترام حقوق الآخرين وتحقيق الأمن والراحة في مجتمعاتنا.</p>
    `,
    category: ["social", "public", "home"],
  },
  {
    id: 88,
    behavior: "الهرڭاوي هو لي كيعيط على الناس من البالكون.",
    solution: `
    <p>الصراخ من البالكون يمكن أن يسبب إزعاجًا لجيرانك ويؤدي إلى توتر العلاقات الاجتماعية. يجب أن نحرص على أن تكون تعاملاتنا مع الآخرين هادئة ومحترمة، خاصة في الأماكن السكنية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- احترام الهدوء:</strong> يجب تجنب الصراخ أو رفع الصوت في الأماكن السكنية، حيث يعتبر ذلك مصدر إزعاج للآخرين.</li>
      <li><strong>- التواصل المباشر:</strong> إذا كان هناك أمر هام يتطلب لفت انتباه أحد الجيران، من الأفضل التوجه إليه بشكل مباشر أو الاتصال به بدلاً من الصراخ من البالكون.</li>
      <li><strong>- الوعي المجتمعي:</strong> التوعية بأهمية احترام راحة الآخرين وضرورة الحفاظ على بيئة هادئة ومريحة للجميع.</li>
      <li><strong>- حل النزاعات بشكل حضاري:</strong> إذا كان هناك نزاع مع الجيران، ينبغي اللجوء إلى الطرق السلمية في حل الخلافات، مثل التحدث مباشرة أو استخدام وسطاء لحل المشكلة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام المتبادل والهدوء يساهمان في خلق بيئة سكنية آمنة ومريحة للجميع. لنعمل معًا للحفاظ على علاقتنا الجيدة مع الجيران.</p>
    `,
    category: ["social", "street", "public", "home"],
  },
  {
    id: 89,
    behavior: "الهرڭاوي هو لي كيخلي الما يطيح من الكليماتيزور على الناس.",
    solution: `
    <p>تسرب المياه من المكيفات الهوائية يمكن أن يسبب إزعاجًا للأشخاص في الشوارع أو الجيران الذين يقيمون في الطوابق السفلية. هذا التصرف يمكن أن يؤدي إلى تشويه الممتلكات العامة والخاصة وزيادة التلوث.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الصيانة الدورية:</strong> يجب على أصحاب المنازل أو المباني التأكد من صيانة أجهزة التكييف بشكل دوري للتأكد من أن المياه لا تتسرب.</li>
      <li><strong>- استخدام التصريف المناسب:</strong> التأكد من أن أجهزة التكييف مجهزة بنظام تصريف للمياه بشكل جيد للحفاظ على البيئة المحيطة.</li>
      <li><strong>- الوعي البيئي:</strong> نشر الوعي بين السكان حول أهمية الحفاظ على الأجهزة المنزلية بشكل صحيح، وأثر ذلك على المجتمع.</li>
      <li><strong>- الإبلاغ عن الأعطال:</strong> في حالة اكتشاف تسرب المياه من أجهزة التكييف، يجب الإبلاغ فورًا عن المشكلة واتخاذ الإجراءات اللازمة لإصلاحها.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>حافظ على منزلك وأجهزتك لحماية بيئتك ومحيطك. التصرفات البسيطة تؤدي إلى بيئة نظيفة وصحية للجميع.</p>
    `,
    category: ["street", "public", "home"],
  },
  {
    id: 90,
    behavior: "الهرڭاوي هو لي كيدير سباق بالموطورات فالليل فحي سكني.",
    solution: `
    <p>إقامة سباقات دراجات نارية في الأحياء السكنية خلال الليل يمكن أن يسبب إزعاجًا شديدًا للسكان، مما يؤثر على راحتهم وسلامتهم. إضافة إلى أن هذه التصرفات قد تعرض حياة الناس للخطر بسبب السرعة العالية والقيادة المتهورة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز الوعي:</strong> من الضروري نشر الوعي حول تأثيرات هذه التصرفات على المجتمع وسلامة الأفراد. على الأفراد أن يدركوا أن القيادة المتهورة يمكن أن تؤدي إلى حوادث كارثية.</li>
      <li><strong>- تنظيم الفعاليات:</strong> تنظيم أنشطة رياضية أو سباقات في أماكن مخصصة بعيدا عن الأحياء السكنية لتفادي الإزعاج وتعزيز السلامة.</li>
      <li><strong>- التعاون مع الشرطة:</strong> تكثيف دوريات الشرطة في الأحياء السكنية لمراقبة مثل هذه الأنشطة واتخاذ الإجراءات القانونية ضد المخالفين.</li>
      <li><strong>- التشجيع على الاحترام:</strong> من المهم تعليم الشباب احترام حقوق الجيران والمجتمع وضرورة الحفاظ على الهدوء خلال الليل.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>دعونا نحترم جيراننا ونحافظ على الأمان في أحيائنا. القيادة المسؤولة تبدأ من كل واحد منا.</p>
    `,
    category: ["street", "social", "public", "driving"],
  },
  {
    id: 91,
    behavior: "الهرڭاوي هو لي كيبيع الماكلة منتهية الصلاحية.",
    solution: `
    <p>بيع الطعام منتهي الصلاحية يعد من التصرفات المرفوضة التي تشكل خطرًا على صحة الناس. فالأطعمة الفاسدة يمكن أن تؤدي إلى حالات تسمم غذائي وأمراض خطيرة أخرى، وتؤثر بشكل سلبي على الثقة بين البائعين والمستهلكين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- مراقبة الجودة:</strong> يجب على السلطات المعنية تعزيز الرقابة على أسواق المواد الغذائية للتحقق من سلامة المنتجات ومدى صلاحيتها للاستهلاك.</li>
      <li><strong>- التوعية:</strong> من المهم نشر الوعي بين التجار والمستهلكين حول مخاطر بيع واستهلاك المنتجات المنتهية الصلاحية وأهمية الحفاظ على الصحة العامة.</li>
      <li><strong>- العقوبات:</strong> فرض عقوبات صارمة على التجار الذين يبيعون مواد غذائية فاسدة يمكن أن يكون رادعًا مهمًا للحفاظ على سلامة المجتمع.</li>
      <li><strong>- التفتيش الدوري:</strong> يجب إجراء عمليات تفتيش منتظمة للمحلات التجارية لضمان عدم تداول الأطعمة الفاسدة في الأسواق.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>صحتك أولوية! لنبذل جهدًا معًا للحفاظ على سلامتنا وسلامة مجتمعنا من خلال اختيار الطعام الطازج والآمن.</p>
    `,
    category: ["work", "health"],
  },
  {
    id: 92,
    behavior: "الهرڭاوي هو لي كيخدم الدراري الصغار بأثمنة رخيصة.",
    solution: `
    <p>استغلال الأطفال في العمل مقابل أجور زهيدة يعد من التصرفات غير القانونية وغير الإنسانية التي تضر بنموهم الجسدي والعقلي. يجب على المجتمع ضمان حقوق الأطفال وحمايتهم من الاستغلال والعمل القسري.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> نشر الوعي بين الأهالي والمجتمع حول أهمية التعليم وحقوق الأطفال، وحثهم على عدم السماح لهم بالعمل في سن مبكرة.</li>
      <li><strong>- التشريعات:</strong> يجب أن تكون هناك قوانين صارمة تمنع استغلال الأطفال في العمل، مع تشديد الرقابة على تطبيق هذه القوانين.</li>
      <li><strong>- الفرص البديلة:</strong> توفير فرص تعليمية وترفيهية للأطفال بدلاً من العمل، مما يساعدهم في بناء مستقبل أفضل.</li>
      <li><strong>- التعاون مع المنظمات:</strong> التعاون مع المنظمات المحلية والدولية التي تعمل على حماية حقوق الأطفال، لضمان محاربة هذه الظاهرة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الأطفال هم مستقبلنا. لنمنحهم الفرصة للنمو والتعلم بدلًا من العمل المبكر. لا للاستغلال!</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 93,
    behavior: "الهرڭاوي هو لي كيحرڭ العجلات فالزنقة.",
    solution: `
    <p>حرق العجلات في الشوارع تؤدي إلى تلوث البيئة وتسبب في انبعاث الدخان الضار الذي يهدد صحة المجتمع. كما أن هذا التصرف يضر بممتلكات العامة ويمكن أن يشكل خطراً على سلامة الأفراد.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية:</strong> نشر الوعي بين المواطنين حول الآثار السلبية لهذا التصرف على البيئة وصحة الإنسان.</li>
      <li><strong>- تشديد العقوبات:</strong> فرض عقوبات على الأشخاص الذين يمارسون هذه التصرفات في الأماكن العامة لضمان الحد من هذه الظاهرة.</li>
      <li><strong>- الرقابة:</strong> تعزيز الرقابة في الشوارع وتوجيه فرق الشرطة لمتابعة حالات الحرق الغير قانونية واتخاذ الإجراءات المناسبة.</li>
      <li><strong>- التفاعل المجتمعي:</strong> تحفيز المجتمع للمشاركة في حملات تطوعية لزيادة الوعي وحماية البيئة من مثل هذه الممارسات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على شوارعنا ونحمي بيئتنا. تجنبوا التصرفات التي تضر بالصحة والبيئة.</p>
    `,
    category: ["street", "social", "public", "environment", "health"],
  },
  {
    id: 94,
    behavior:
      "الهرڭاوي هو لي كيغسل الطوموبيل ديالو بالما ديال الشرب فوقت الجفاف.",
    solution: `
    <p>استخدام مياه الشرب لغسل السيارات أثناء فترات الجفاف يساهم في هدر المياه الثمينة التي تعد من الموارد المحدودة. هذا التصرف يعزز نقص المياه ويؤثر سلبًا على البيئة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- استخدام المياه بشكل مسؤول:</strong> التأكد من استخدام المياه بحذر، خاصة في فترات الجفاف، وبدلاً من ذلك استخدام المياه المعاد تدويرها أو المياه غير الصالحة للشرب لغسل السيارات.</li>
      <li><strong>- التوعية:</strong> نشر الوعي بين المواطنين حول أهمية ترشيد استهلاك المياه والآثار السلبية لهدرها في غير محلها.</li>
      <li><strong>- فرض قوانين:</strong> تشديد القوانين المحلية التي تمنع غسل السيارات باستخدام مياه الشرب خلال فترات الجفاف لتقليل الفاقد.</li>
      <li><strong>- استخدام طرق بديلة:</strong> تشجيع الأشخاص على استخدام تقنيات غسيل السيارات التي تتطلب أقل قدر ممكن من المياه، مثل الأنظمة المغلقة أو غسيل السيارات الجاف.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>كل قطرة ماء مهمة. دعونا نحرص على استخدام المياه بحكمة لنحافظ على مواردنا للأجيال القادمة.</p>
    `,
    category: ["social", "environment"],
  },
  {
    id: 95,
    behavior: "الهرڭاوي هو لي كيبني طابق فوق طابق بلا رخصة.",
    solution: `
    <p>بناء طابق فوق طابق دون الحصول على الترخيص القانوني يعرض حياة الأفراد للخطر ويخالف الأنظمة العمرانية المعتمدة. هذه التصرفات تساهم في تدهور البيئة العمرانية وتسبب في مشاكل هيكلية قد تؤدي إلى انهيار المباني.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التقيد بالقوانين:</strong> يجب على جميع الأفراد التقيد بالقوانين المحلية المتعلقة بالبناء والحصول على التصاريح اللازمة قبل إجراء أي تعديل أو إضافة في البناء.</li>
      <li><strong>- التوعية القانونية:</strong> نشر الوعي بين المواطنين بأهمية اتباع الإجراءات القانونية وضرورة استشارة المختصين في مجال البناء.</li>
      <li><strong>- الرقابة والمراقبة:</strong> تعزيز الرقابة على المشاريع العمرانية لضمان أن جميع البناءات تتم وفقًا للمعايير القانونية والتقنية.</li>
      <li><strong>- التعاون مع السلطات:</strong> تشجيع المواطنين على التعاون مع السلطات المحلية للإبلاغ عن أي بناء غير مرخص أو مخالف للقوانين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على بيئتنا العمرانية من خلال البناء الآمن والمستدام وفقًا للقوانين لحماية الأرواح والممتلكات.</p>
    `,
    category: ["work", "home"],
  },
  {
    id: 96,
    behavior: "الهرڭاوي هو المقدم لي كيشد الرشوة و كيسمح بالبناء العشوائي.",
    solution: `
    <p>الرشوة والموافقة على البناء العشوائي تؤدي إلى تدهور بيئي واجتماعي. هذه الممارسات تعيق التنمية المستدامة وتزيد من الأزمات السكنية، كما تعرض السلامة العامة للخطر.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التبليغ عن الفساد:</strong> يجب على المواطنين الإبلاغ عن أي تصرفات غير قانونية بما في ذلك طلبات الرشوة، وذلك من خلال القنوات الرسمية.</li>
      <li><strong>- التوعية القانونية:</strong> نشر التوعية بين المواطنين حول مخاطر الرشوة والبناء العشوائي وأثرها على المجتمع.</li>
      <li><strong>- تعزيز الرقابة:</strong> يجب على الجهات المختصة تعزيز الرقابة على جميع المقدمين والموظفين الحكوميين لضمان التزامهم بالقوانين.</li>
      <li><strong>- إجراءات رادعة:</strong> فرض عقوبات صارمة على الأشخاص الذين يقبلون الرشوة أو يوافقون على البناء غير المرخص.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>البناء العشوائي والرشوة لا تؤديان إلا إلى تدمير مجتمعنا. لنلتزم بالقوانين ونحارب الفساد لبناء بيئة آمنة ومستدامة.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 97,
    behavior: "الهرڭاوي هو لي كيخلي العمال يخدمو بلا تأمين.",
    solution: `
    <p>ترك العمال يعملون بدون تأمين يعرضهم لمخاطر صحية ومالية كبيرة. غياب التأمين يعني أن العمال لا يمكنهم الحصول على الرعاية الصحية أو التعويضات في حالة حدوث إصابات أو حوادث أثناء العمل.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الالتزام بالقوانين:</strong> يجب على أصحاب العمل توفير التأمين الصحي والعمالي لجميع العاملين طبقًا للقوانين المعمول بها.</li>
      <li><strong>- التوعية:</strong> نشر حملات توعية حول أهمية التأمين الاجتماعي والصحي للعمال وكيفية حصولهم عليه.</li>
      <li><strong>- الرقابة القانونية:</strong> تعزيز الرقابة من قبل السلطات المختصة على المنشآت لضمان التزامها بتوفير التأمين للعاملين.</li>
      <li><strong>- تعزيز العقوبات:</strong> فرض عقوبات صارمة على أصحاب العمل الذين يخالفون القوانين ولا يوفرون التأمين للعمال.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>حقوق العمال يجب أن تكون محمية. تأمينهم هو حقهم، وهو مسؤولية كل صاحب عمل.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 98,
    behavior: "الهرڭاوي هو لي كيدير مشروع و مكيخلصش الضرائب.",
    solution: `
    <p>عدم دفع الضرائب هو خرق للقانون ويؤثر سلبًا على الاقتصاد الوطني. الضرائب تُستخدم في تمويل الخدمات العامة مثل التعليم والصحة والبنية التحتية. عندما يتجنب البعض دفع الضرائب، يتحمل المواطنون الآخرون العبء المالي، مما يؤثر على التنمية المستدامة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الامتثال للقانون:</strong> يجب على جميع أصحاب المشاريع دفع الضرائب المستحقة وفقًا للقوانين المحلية لضمان تمويل الخدمات العامة.</li>
      <li><strong>- التوعية:</strong> نشر حملات توعية تبرز أهمية دفع الضرائب لدعم التنمية الاقتصادية والاجتماعية وتحسين جودة الحياة.</li>
      <li><strong>- التشديد على الرقابة:</strong> تعزيز الرقابة على الأنشطة التجارية والمشاريع الصغيرة والكبيرة لضمان التزامها بتسديد الضرائب.</li>
      <li><strong>- تقديم حوافز:</strong> تقديم حوافز ضريبية للمشاريع التي تلتزم بدفع الضرائب في الوقت المحدد لتعزيز الثقافة الضريبية الجيدة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>دفع الضرائب هو واجب وحق للمجتمع. من خلال الوفاء بالضرائب، نساهم جميعًا في بناء وطن أفضل.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 99,
    behavior: "الهرڭاوي هو لي كيبيع دواء مغشوش للمرضى.",
    solution: `
    <p>بيع الأدوية المغشوشة يشكل تهديدًا كبيرًا على صحة الناس، حيث يمكن أن تؤدي إلى تفاقم المرض أو ظهور مضاعفات صحية خطيرة. الأدوية المغشوشة لا تحترم المعايير الطبية وتفتقر إلى الفعالية المطلوبة في علاج الأمراض، مما يؤدي إلى تعرض المرضى للمخاطر.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية الصحية:</strong> نشر حملات توعية حول خطر الأدوية المغشوشة وتشجيع المرضى على شراء الأدوية من الصيدليات المرخصة فقط.</li>
      <li><strong>- الرقابة الصيدلانية:</strong> تعزيز الرقابة على بيع الأدوية وتنفيذ قوانين صارمة لمكافحة بيع الأدوية المغشوشة.</li>
      <li><strong>- التبليغ عن المخالفات:</strong> تشجيع المواطنين على التبليغ عن أي شخص يبيع أدوية مغشوشة أو غير مرخصة، وذلك لحماية المجتمع من الأضرار الصحية.</li>
      <li><strong>- التحقيقات القانونية:</strong> القيام بتحقيقات قانونية ومعاقبة كل من يثبت تورطه في بيع أدوية مغشوشة، لتكون عبرة للآخرين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>صحتك أولاً. اختر دائمًا الأدوية الموثوقة وتجنب الأدوية المغشوشة التي قد تؤذي صحتك وتؤثر على حياتك.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 100,
    behavior: "الهرڭاوي هو لي كيستغل الناس المحتاجين و كيشغلهم بثمن رخيص.",
    solution: `
    <p>استغلال الأشخاص المحتاجين يعد من الأفعال غير الإنسانية والمخالفة للقوانين، حيث يتم استغلال وضعهم الاقتصادي والاجتماعي من خلال دفع أجور منخفضة للغاية مقابل العمل الشاق. هذا يؤدي إلى تفاقم الفقر ويعزز الدورة السلبية للاعتماد على الأجور المنخفضة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تشجيع العدالة في الأجور:</strong> من الضروري توفير أجور عادلة للعمال بما يتناسب مع الجهد المبذول، والحرص على تحسين ظروف العمل.</li>
      <li><strong>- الرقابة على سوق العمل:</strong> يجب تكثيف الرقابة على المؤسسات والشركات للتأكد من أنها تلتزم بقوانين العمل وتوفر حقوق العمال الأساسية، مثل الحد الأدنى للأجور.</li>
      <li><strong>- التوعية بحقوق العمال:</strong> نشر الوعي بين العمال حول حقوقهم القانونية والتأكّد من أنهم يعرفون كيفية المطالبة بتحسين ظروف العمل.</li>
      <li><strong>- تشجيع المسؤولية الاجتماعية:</strong> تشجيع الشركات على تطبيق سياسات المسؤولية الاجتماعية لضمان رفاهية العمال وتحسين ظروفهم.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العدالة في العمل تضمن مجتمعًا متوازنًا. لنحترم حقوق الآخرين ونساعد في بناء بيئة عمل أفضل للجميع.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 101,
    behavior:
      "الهرڭاوي هو لي كيربي كلب خطير غير باش يحرشو على الناس و ماكيديرش ليه الكمامة.",
    solution: `
    <p>تربية الكلاب الخطيرة دون اتخاذ الإجراءات الوقائية اللازمة، مثل وضع الكمامة أو تقييدها، يشكل خطرًا على المجتمع، خصوصًا على الأطفال والمارة. كما أن ذلك يعكس عدم المسؤولية تجاه الآخرين والمجتمع ككل.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الالتزام بالقوانين:</strong> يجب على أصحاب الحيوانات الأليفة الالتزام بالقوانين المحلية المتعلقة بتربية الكلاب، بما في ذلك إلزامية وضع الكمامة أو تقييد الحيوانات الخطيرة.</li>
      <li><strong>- التوعية:</strong> نشر حملات توعية حول كيفية تربية الحيوانات بشكل آمن، وكيفية التعامل مع الكلاب العدوانية بشكل صحيح.</li>
      <li><strong>- التفتيش والرقابة:</strong> تعزيز الرقابة على أصحاب الحيوانات الأليفة، خصوصًا في الأماكن العامة، لضمان التزامهم بالقوانين المتعلقة بالسلامة.</li>
      <li><strong>- تدريب الكلاب:</strong> من المهم تدريب الكلاب بشكل مناسب على السلوكيات المقبولة في المجتمع لتجنب التصرفات العدوانية أو المزعجة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام والأمان يتطلبان المسؤولية. دعونا نحرص على تربية الحيوانات بطريقة آمنة وصديقة للمجتمع.</p>
    `,
    category: ["social", "public", "animals"],
  },
  {
    id: 102,
    behavior:
      "الهرڭاوي هو لي كيدير غاراج ديال الميكانيك أو النجارة فوسط حي سكني.",
    solution: `
    <p>إقامة ورشات ميكانيك أو نجارة في الأحياء السكنية دون مراعاة الأنظمة والقوانين الخاصة قد تؤدي إلى إزعاج السكان بسبب الضوضاء، التلوث، والخطر على السلامة. إضافة إلى أنها قد تؤثر على جودة الحياة في المنطقة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الامتثال للقوانين:</strong> يجب على أصحاب الورشات والأنشطة التجارية مثل الميكانيك أو النجارة التأكد من الالتزام بالقوانين المحلية المتعلقة بإقامة مثل هذه الأنشطة في مناطق سكنية.</li>
      <li><strong>- الانتقال إلى مناطق صناعية:</strong> من الأفضل نقل مثل هذه الأنشطة إلى المناطق الصناعية أو الأماكن المخصصة لذلك لضمان عدم التأثير على حياة السكان.</li>
      <li><strong>- التنظيم والإشراف:</strong> يجب على السلطات المحلية أن تقوم بتنظيم ومراقبة ورشات العمل في الأحياء السكنية لضمان تقليل الإزعاج والحفاظ على سلامة السكان.</li>
      <li><strong>- التوعية:</strong> نشر التوعية بين أصحاب الأنشطة التجارية حول أهمية اختيار الأماكن المناسبة لإقامة الورشات وعدم تضرر المجتمع المحلي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنعمل معًا على خلق بيئة سكنية هادئة وآمنة للجميع من خلال الالتزام بالقوانين وتجنب الأنشطة التي تضر بالجوار.</p>
    `,
    category: ["street", "social", "public", "work"],
  },
  {
    id: 103,
    behavior: "الهرڭاوي هو لي كيخدم بالمعدات لي كتدير الصداع فالليل.",
    solution: `
    <p>استخدام المعدات التي تسبب ضوضاء عالية خلال الليل يؤثر على راحة السكان ويعرضهم للقلق والإزعاج، مما يؤدي إلى اضطراب النوم وصحة نفسية غير جيدة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تحديد ساعات العمل:</strong> يجب على أصحاب الأنشطة التجارية تحديد ساعات العمل التي لا تؤثر على راحة الجيران، مع تجنب العمل في أوقات متأخرة من الليل.</li>
      <li><strong>- استخدام معدات أقل ضجيجًا:</strong> يمكن استخدام معدات تعمل بصوت أقل أو شراء أدوات حديثة تكون أكثر هدوءًا لتقليل الإزعاج.</li>
      <li><strong>- التواصل مع الجيران:</strong> من المهم التواصل مع الجيران وإبلاغهم مسبقًا عن العمل الذي سيتم في ساعات معينة، مما يسهم في تقليل التوتر والفهم المتبادل.</li>
      <li><strong>- التقيد بالقوانين المحلية:</strong> يجب الالتزام بالقوانين المحلية التي تحدد أوقات العمل في المناطق السكنية للحد من الضوضاء والإزعاج.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>من أجل بيئة أكثر هدوءًا وراحة، دعونا نعمل معًا على احترام ساعات العمل وتقليل الضوضاء.</p>
    `,
    category: ["social", "public", "work"],
  },
  {
    id: 104,
    behavior: "الهرڭاوي هو لي كيخلي ولادو يضربو و يتنمرو على ولاد الجيران.",
    solution: `
    <p>التنمر والعنف بين الأطفال يمكن أن يكون له تأثيرات سلبية على صحتهم النفسية والعاطفية، مما يساهم في نشر العدوانية وعدم التفاهم بين الأفراد في المجتمع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التعليم على احترام الآخرين:</strong> يجب تعليم الأطفال كيفية التعامل مع الآخرين بلطف واحترام، من خلال الأنشطة التعليمية والتوجيه المستمر من الأهل والمعلمين.</li>
      <li><strong>- مراقبة السلوك:</strong> يجب على الأهل مراقبة سلوك أطفالهم في البيت وفي الخارج، وضمان أنهم لا يتعرضون للتنمر أو يمارسونه ضد الآخرين.</li>
      <li><strong>- التحلي بالقدوة الحسنة:</strong> يجب أن يكون الأهل قدوة في سلوكهم مع الآخرين، لأن الأطفال غالبًا ما يتعلمون من تصرفات الأهل.</li>
      <li><strong>- التواصل مع المدرسة:</strong> من المهم التواصل مع المدرسة والمعلمين عند ملاحظة أي سلوك غير مناسب من الأطفال، للتأكد من توفير بيئة آمنة وخالية من التنمر.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>نربي الأطفال على التسامح والاحترام، لأن مجتمعًا خاليًا من العنف يبدأ من البيت والمدرسة.</p>
    `,
    category: ["social", "school", "children"],
  },
  {
    id: 105,
    behavior: "الهرڭاوي هو لي كيركن طوموبيل كبيرة فزنقة ضيقة.",
    solution: `
    <p>ركن السيارات في أماكن غير مناسبة يمكن أن يسبب ازدحامًا مروريًا ويعطل حركة المرور ويضر براحة الآخرين. من المهم أن يتم ركن السيارات بطريقة تحترم حقوق الآخرين وتساهم في تنظيم حركة المرور.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الاختيار المناسب لمكان الركن:</strong> يجب على السائقين التأكد من ركن سياراتهم في أماكن مخصصة لذلك بعيدًا عن الأماكن الضيقة أو التي تعيق حركة السير.</li>
      <li><strong>- التعليم على قواعد المرور:</strong> توعية السائقين بأهمية احترام القواعد المرورية وركن السيارات في الأماكن المخصصة لها، وخاصة في الأماكن الضيقة.</li>
      <li><strong>- تعزيز الرقابة:</strong> يجب على السلطات المختصة تعزيز الرقابة على مواقف السيارات في الأماكن العامة والتأكد من أن السائقين يلتزمون بالقوانين.</li>
      <li><strong>- توفير مواقف كافية:</strong> يجب توفير مواقف كافية في المناطق التي تشهد حركة مرورية كبيرة لتسهيل عملية ركن السيارات وتفادي المشاكل المرورية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>نظام المرور يبدأ من كل واحد منا. دعونا نركن سياراتنا بطريقة مسؤولة لتحسين حركة المرور وراحة الآخرين.</p>
    `,
    category: ["street", "public", "driving"],
  },
  {
    id: 106,
    behavior:
      "الهرڭاوي هو لي كيحتاكر السلع و كيبيعهم فأوقات الأزمات بثمن غالي.",
    solution: `
    <p>احتكار السلع وبيعها بأسعار مبالغ فيها في أوقات الأزمات يعد من التصرفات غير الأخلاقية ويضر بالمجتمع. هذا السلوك يمكن أن يزيد من معاناة الأفراد الذين يعانون أصلاً من الظروف الصعبة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الرقابة على الأسعار:</strong> يجب على السلطات المختصة مراقبة الأسعار في الأسواق خلال فترات الأزمات لضمان عدم استغلال الناس.</li>
      <li><strong>- تشجيع المنافسة:</strong> فتح الفرص للموردين المختلفين وخلق بيئة تنافسية تحد من احتكار السلع وارتفاع أسعارها بشكل غير مبرر.</li>
      <li><strong>- التوعية المجتمعية:</strong> نشر الوعي بين الأفراد حول أهمية التعاون في الأوقات الصعبة وعدم استغلال الآخرين لصالح المصلحة الشخصية.</li>
      <li><strong>- تشديد العقوبات:</strong> فرض عقوبات صارمة على من يثبت تورطه في احتكار السلع أو التلاعب بأسعارها خلال الأزمات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التعاون أساس النجاح في الأزمات. دعونا نتجنب استغلال الآخرين ونعمل معًا لتجاوز التحديات.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 107,
    behavior: "الهرڭاوي هو لي كيخلي الناس يخلصو باش يدخلو للشاطئ العمومي.",
    solution: `
    <p>الشواطئ العمومية هي ملك للجميع ويجب أن تكون مجانية للوصول إليها. فرض رسوم للدخول إلى الشاطئ يعد تصرفًا غير قانوني ويمنع العديد من الأشخاص من الاستمتاع بهذه المساحة العامة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بالقوانين:</strong> نشر الوعي بين المواطنين حول حقوقهم في الوصول المجاني إلى الشواطئ العمومية.</li>
      <li><strong>- التصدي للممارسات غير القانونية:</strong> تعزيز الرقابة على الشواطئ لمنع أي شخص من فرض رسوم غير قانونية على الزوار.</li>
      <li><strong>- التحقيق في المخالفات:</strong> يجب أن يتم التحقيق في أي حالة يتعرض فيها المواطنون لدفع رسوم غير قانونية للدخول إلى الشاطئ.</li>
      <li><strong>- التعاون مع السلطات المحلية:</strong> التنسيق بين البلديات والشرطة لتوفير بيئة آمنة ومجانية على الشواطئ العامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الشاطئ ملك للجميع. لنحافظ على حقوقنا في الاستمتاع بالمساحات العامة بشكل مجاني.</p>
    `,
    category: ["social", "public"],
  },
  {
    id: 108,
    behavior:
      "الهرڭاوي هو لي كيدير كافي و كيخلي الشباب يلعبو فيها القمار و يستهلكو المخدرات.",
    solution: `
    <p>تسهيل القمار وتعاطي المخدرات في الأماكن العامة هو تصرف خطير يؤثر على صحة المجتمع ويعرض الشباب لمخاطر كبيرة. يجب أن تكون الأماكن العامة مثل المقاهي خالية من أي أنشطة غير قانونية أو مدمرة للمجتمع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الرقابة الصارمة:</strong> يجب على السلطات المحلية فرض رقابة شديدة على المقاهي والأماكن العامة للتأكد من عدم السماح بممارسة القمار أو تعاطي المخدرات.</li>
      <li><strong>- التوعية المجتمعية:</strong> نشر حملات توعية تهدف إلى تحذير الشباب من مخاطر القمار والمخدرات، وتوجيههم إلى سلوكيات صحية وآمنة.</li>
      <li><strong>- التعاون مع المجتمع المحلي:</strong> تشجيع أصحاب المحلات والمقاهي على الإبلاغ عن أي سلوك مضر أو غير قانوني يحدث في أماكنهم.</li>
      <li><strong>- تعزيز العقوبات:</strong> فرض عقوبات صارمة على أصحاب المحلات الذين يساهمون في تسهيل هذه الأنشطة غير القانونية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على مجتمع آمن وصحي بعيدًا عن المخدرات والقمار. كل واحد منا مسؤول عن بناء بيئة خالية من التهديدات.</p>
    `,
    category: ["social", "public", "work"],
  },
  {
    id: 109,
    behavior: "الهرڭاوي هو لي كيدير حمام و كيضيع الما بلا مراقبة.",
    solution: `
    <p>إهدار المياه في الحمامات التجارية يعد تصرفًا غير مسؤول، حيث أن المياه من الموارد الطبيعية المحدودة التي يجب الحفاظ عليها. تبذير المياه يساهم في مشاكل بيئية خطيرة، مثل نقص المياه الجوفية، بالإضافة إلى تأثيره على البيئة والمجتمع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تركيب أنظمة ترشيد استهلاك المياه:</strong> يجب على أصحاب الحمامات تركيب أنظمة موفرة للمياه مثل الحنفيات ذات التدفق المنخفض أو الأنابيب ذات الكفاءة العالية في استخدام المياه.</li>
      <li><strong>- التوعية البيئية:</strong> نشر حملات توعية حول أهمية الحفاظ على المياه وترشيد استخدامها في الأماكن العامة والخاصة.</li>
      <li><strong>- الرقابة المستمرة:</strong> ضرورة الرقابة على المنشآت التي تستهلك كميات كبيرة من المياه، والتأكد من التزامها بالتوجيهات البيئية.</li>
      <li><strong>- تعزيز المسؤولية:</strong> تشجيع أصحاب الأعمال على تحمل مسؤوليتهم في الحفاظ على الموارد الطبيعية وتوفير بيئة صحية وآمنة للعملاء.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>المياه ثروة يجب أن نحافظ عليها. لنعمل معًا لترشيد استخدامها في كل مكان، للحفاظ على البيئة وحماية الأجيال القادمة.</p>
    `,
    category: ["social", "work", "environment"],
  },
  {
    id: 110,
    behavior: "الهرڭاوي هو لي كيخلي مرتو تخدم و كياخد منها الفلوس.",
    solution: `
    <p>السلوك الذي يتضمن استغلال الشريك في العلاقة الزوجية من خلال الضغط عليه للعمل ومن ثم أخذ أمواله يعد غير أخلاقي. يجب على الزوجين أن يتعاملوا مع بعضهما البعض بالاحترام والمساواة، وأن يدعما بعضهما لتحقيق التوازن المالي والتعاون المشترك.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التفاهم والمشاركة:</strong> من الضروري أن يتفق الزوجان على كيفية إدارة الشؤون المالية بشكل مشترك، بحيث لا يشعر أي منهما بالاستغلال.</li>
      <li><strong>- تعزيز المساواة:</strong> يجب على الأزواج دعم بعضهم البعض في العمل والمنزل وتوزيع المسؤوليات بشكل عادل.</li>
      <li><strong>- الاستقلال المالي:</strong> تشجيع كلا الزوجين على تحقيق الاستقلال المالي واتخاذ القرارات المالية بشكل مشترك ومتوازن.</li>
      <li><strong>- التوعية:</strong> نشر الوعي حول حقوق الزوجين في علاقة متوازنة وتحقيق الاحترام المتبادل.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام المتبادل والمساواة هما الأساس في بناء علاقة صحية ومتوازنة. دعونا نعمل معًا لبناء أسر قائمة على التعاون والدعم المشترك.</p>
    `,
    category: ["social", "home", "work"],
  },
  {
    id: 111,
    behavior: "الهرڭاوي هو لي كيبيع الدجاج المريض للناس.",
    solution: `
    <p>بيع الدجاج المريض أو أي نوع من الأطعمة الفاسدة يشكل خطرًا على صحة المستهلكين ويعد انتهاكًا للقوانين الصحية. يجب على التجار أن يتحلوا بالأمانة وأن يضمنوا أن منتجاتهم آمنة للاستهلاك.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الرقابة الصحية:</strong> تعزيز الرقابة من الجهات الصحية على المحلات التجارية لضمان بيع منتجات صالحة للاستهلاك.</li>
      <li><strong>- التوعية:</strong> نشر الوعي بين الناس حول كيفية اكتشاف الطعام المريض أو الفاسد وأهمية شراء المنتجات من مصادر موثوقة.</li>
      <li><strong>- العقوبات الصارمة:</strong> تطبيق عقوبات مشددة على من يبيع منتجات ملوثة أو غير صالحة للاستهلاك.</li>
      <li><strong>- التعاون بين المجتمع والسلطات:</strong> تشجيع المواطنين على الإبلاغ عن أي ممارسات غير قانونية في بيع المواد الغذائية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>صحتك تهمنا. لنعمل معًا للحفاظ على أطعمة صحية وآمنة للجميع.</p>
    `,
    category: ["social", "work", "health", "animals"],
  },
  {
    id: 112,
    behavior: "الهرڭاوي هو لي كيخلي الما ديال الصرف الصحي يخرج للزنقة.",
    solution: `
    <p>تسريب المياه من شبكة الصرف الصحي إلى الشوارع يشكل خطرًا كبيرًا على البيئة وصحة المواطنين. يمكن أن يؤدي ذلك إلى تلوث المياه الجوفية، وانتشار الأمراض، بالإضافة إلى التأثير السلبي على الحياة اليومية للمجتمع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الصيانة المستمرة:</strong> التأكد من صيانة شبكات الصرف الصحي بانتظام لتفادي حدوث أي تسريبات أو انسدادات.</li>
      <li><strong>- الرقابة:</strong> تكثيف الرقابة على الصرف الصحي في المناطق العامة، واتخاذ الإجراءات اللازمة عند حدوث أي تسريب.</li>
      <li><strong>- التوعية:</strong> نشر الوعي بين المواطنين حول ضرورة إبلاغ السلطات المختصة عن أي تسريب في شبكة الصرف الصحي.</li>
      <li><strong>- التعاون بين الجهات المعنية:</strong> التعاون بين البلديات، شركات المياه، والمواطنين لضمان إصلاح أي مشاكل متعلقة بالصرف الصحي في الوقت المناسب.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>نظافة الشوارع تبدأ من إصلاح المشكلات البيئية. لنعمل معًا لضمان بيئة صحية وآمنة للجميع.</p>
    `,
    category: ["street", "social", "public", "environment", "health"],
  },
  {
    id: 113,
    behavior:
      "الهرڭاوي هو لي كيدير قهوة قدام المدرسة و كيبيع السجائر للقاصرين.",
    solution: `
    <p>بيع السجائر للقاصرين هو أمر غير قانوني ويشكل خطرًا كبيرًا على صحتهم. كما أن وجود مقاهي قريبة من المدارس التي تبيع السجائر يشجع على تعاطي هذه المواد في سن مبكرة، مما يؤثر سلبًا على النمو العقلي والجسدي للمراهقين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الرقابة:</strong> تكثيف الرقابة على المقاهي والمحال التجارية بالقرب من المدارس لمنع بيع السجائر للقاصرين.</li>
      <li><strong>- التوعية:</strong> تنفيذ حملات توعية موجهة للمراهقين حول أضرار التدخين، وكيفية حماية صحتهم من المخاطر المحتملة.</li>
      <li><strong>- العقوبات:</strong> تطبيق عقوبات رادعة على أصحاب المقاهي الذين يبيعون السجائر للقاصرين.</li>
      <li><strong>- دور المدرسة:</strong> إشراك المدارس في برامج توعية تركز على أهمية اتخاذ قرارات صحية، وتعزيز السلوكيات الإيجابية بين الطلاب.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>صحتك أغلى من كل شيء، لا تدع التدخين يؤثر على مستقبلك. احمِ نفسك وابتعد عن هذه العادات الضارة.</p>
    `,
    category: ["social", "public", "school", "work", "health"],
  },
  {
    id: 114,
    behavior: "الهرڭاوي هو لي كيخدم فالإدارة و كيطلب الرشوة باش يدير الخدمة.",
    solution: `
    <p>طلب الرشوة في الإدارة يشكل فسادًا يؤثر سلبًا على سير العمل في المؤسسات العامة، ويضر بسمعة البلاد ويعوق تحقيق العدالة والمساواة. يجب محاربة الرشوة بكل الوسائل الممكنة لضمان تحقيق بيئة عمل نزيهة وشفافة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية والتثقيف:</strong> نشر حملات توعية بين الموظفين والمواطنين حول أضرار الرشوة وتأثيرها السلبي على المجتمع.</li>
      <li><strong>- تطبيق القانون:</strong> التشديد على العقوبات ضد الموظفين الذين يطلبون الرشوة أو يقبلونها، وضمان تطبيق القانون بصرامة.</li>
      <li><strong>- إجراءات الرقابة:</strong> تعزيز الرقابة الداخلية والخارجية في المؤسسات الحكومية لضمان الشفافية ومنع الفساد.</li>
      <li><strong>- تعزيز ثقافة النزاهة:</strong> العمل على خلق ثقافة مؤسسية تشجع على النزاهة، والشفافية، والمساءلة في جميع مستويات العمل.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لننقض معًا الفساد، ونعمل من أجل بيئة إدارية نزيهة وشفافة تعود بالفائدة على الجميع.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 115,
    behavior: "الهرڭاوي هو لي كيخلي الناس يخلصو على الوقوف فشارع عمومي.",
    solution: `
    <p>فرض رسوم على وقوف السيارات في الشوارع العامة يعد خطوة غير عادلة إذا لم يكن هناك تنظيم وشفافية في جمع هذه الرسوم. من الضروري أن تكون هناك سياسات واضحة وعادلة لضمان الاستخدام الفعّال للفضاءات العامة مع الحفاظ على حقوق المواطنين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- إعادة تقييم السياسات:</strong> يجب أن تتم دراسة فرض الرسوم على وقوف السيارات في الشوارع العامة بناءً على الحاجة الفعلية لتلك المساحات وحجم الإقبال عليها.</li>
      <li><strong>- الشفافية والمساواة:</strong> التأكد من أن الرسوم التي يتم جمعها تستخدم بشكل صحيح لتطوير وتحسين البنية التحتية للمدينة، مثل تطوير مواقف سيارات وتنظيم الحركة المرورية.</li>
      <li><strong>- الإعلان المسبق:</strong> توفير إعلانات واضحة ومعلومة دقيقة للمواطنين بشأن الأماكن التي يتم فرض الرسوم فيها، ومتى يتم فرضها.</li>
      <li><strong>- التأكد من العدالة:</strong> التأكد من أن الرسوم لا تكون عبئًا غير عادل على السكان المحليين أو أصحاب المشاريع الصغيرة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>من المهم أن تتبع الإدارة المحلية طرقًا شفافة وعادلة في فرض الرسوم على وقوف السيارات لضمان تحقيق المنفعة العامة دون المساس بحقوق المواطنين.</p>
    `,
    category: ["street", "public"],
  },
  {
    id: 116,
    behavior: "الهرڭاوي هو لي كيبيع الحوايج المقلدة على أنها أصلية.",
    solution: `
    <p>بيع السلع المقلدة على أنها أصلية هو من الأنشطة غير القانونية التي تؤثر على الاقتصاد المحلي وتعزز من انتشار الغش. هذه الممارسات تؤدي إلى تدهور ثقة المستهلكين في السوق وتؤثر سلبًا على الشركات الأصلية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية القانونية:</strong> من الضروري توعية الناس بالمخاطر القانونية المرتبطة ببيع السلع المقلدة، بما في ذلك العقوبات المالية أو السجن.</li>
      <li><strong>- تشديد الرقابة:</strong> يجب تكثيف التفتيش والمراقبة في الأسواق لضبط السلع المقلدة والتأكد من أن البائعين يلتزمون بالقوانين.</li>
      <li><strong>- تشجيع المستهلكين على التحقق:</strong> تعليم المستهلكين كيفية التعرف على المنتجات الأصلية والتأكد من جودتها قبل الشراء، مثل التحقق من الملصقات والشهادات الموثوقة.</li>
      <li><strong>- الدعم للمنتجين المحليين:</strong> تشجيع دعم المنتجات الأصلية من خلال حملات توعية أو تقديم حوافز للمستهلكين الذين يشترون السلع القانونية والأصلية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>من الضروري أن يتحلى الجميع بالوعي القانوني، وأن يتجنبوا شراء المنتجات المقلدة التي قد تضر بصحتهم أو تساهم في تدهور الاقتصاد المحلي.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 117,
    behavior: "الهرڭاوي هو لي كيخلي الدراري يبيعو السلع فالضو الحمر.",
    solution: `
    <p>بيع السلع في الضوء الأحمر يعتبر مخالفًا للقوانين في العديد من المدن، حيث يتم اعتبار ذلك عملًا غير قانوني وغير آمن. كما أن السماح للأطفال بالقيام بذلك يعرضهم للخطر ويشجع على الانتهاك الصريح للقانون.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- توعية المجتمع:</strong> من المهم أن يتم توعية المجتمع والمواطنين بمخاطر بيع السلع في الأماكن غير القانونية وتأثير ذلك على الأطفال والمجتمع.</li>
      <li><strong>- فرض القوانين:</strong> يجب على السلطات المحلية فرض قوانين صارمة بشأن بيع السلع في الأماكن غير المسموح بها، لا سيما بالقرب من الإشارات الضوئية.</li>
      <li><strong>- دعم الأطفال:</strong> توجيه الأطفال نحو أنشطة أفضل وأكثر أمانًا وتوفير فرص تعليمية أو رياضية بديلة بدلاً من السماح لهم بالمشاركة في أعمال غير قانونية.</li>
      <li><strong>- تشجيع الرقابة المجتمعية:</strong> من خلال تعزيز دور الأحياء والمجتمع في رصد الأنشطة غير القانونية والتبليغ عنها، يمكن توفير بيئة أكثر أمانًا للأطفال.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>يجب حماية الأطفال من التعرض للمخاطر القانونية والبدنية من خلال توجيههم نحو بيئات آمنة وموثوقة. التجارة في الضوء الأحمر لا تقدم سوى المخاطر، ويجب أن يتم التوقف عنها فورًا.</p>
    `,
    category: ["street", "social", "children"],
  },
  {
    id: 118,
    behavior: "الهرڭاوي هو لي كيستعمل النفوذ ديالو باش ياخد أراضي الناس.",
    solution: `
    <p>استغلال النفوذ لأخذ أراضي الناس هو نوع من الظلم والفساد، ويشكل انتهاكًا لحقوق الملكية. هذا التصرف يؤثر بشكل كبير على الأفراد ويعرضهم للضرر، ويجب محاربته من خلال تطبيق القوانين التي تحمي الحقوق الشخصية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- مكافحة الفساد:</strong> يجب تكثيف الجهود لمكافحة الفساد على جميع المستويات، خاصة في قضايا ملكية الأراضي. يجب على الجهات القانونية اتخاذ تدابير صارمة ضد استغلال النفوذ.</li>
      <li><strong>- تفعيل الرقابة القانونية:</strong> من الضروري إنشاء لجان مستقلة للتحقيق في الحالات المشبوهة ومراجعة سجلات ملكية الأراضي لمنع الاستيلاء غير المشروع عليها.</li>
      <li><strong>- التوعية القانونية:</strong> يجب توعية المجتمع والمواطنين بحقوقهم القانونية وكيفية حماية أراضيهم من التعدي أو الاستغلال غير القانوني.</li>
      <li><strong>- دعم المظلومين:</strong> تقديم الدعم القانوني للأفراد الذين يتعرضون للتعدي على ممتلكاتهم، وتوفير استشارات قانونية مجانية للمساعدة في استرداد الحقوق.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>استغلال النفوذ للحصول على أراضي الناس يشكل ظلمًا غير مقبول. يجب تطبيق القانون لحماية حقوق المواطنين وإيقاف هذه الأنشطة الغير قانونية فورًا.</p>
    `,
    category: ["social"],
  },
  {
    id: 119,
    behavior: "الهرڭاوي هو لي كيخلي الولاد يتسولو فالشارع.",
    solution: `
    <p>تسول الأطفال في الشارع هو تصرف غير مقبول ينتهك حقوقهم الأساسية في التعليم والنمو في بيئة آمنة. هذا السلوك يعرضهم للاستغلال والعديد من المخاطر الاجتماعية. يجب اتخاذ خطوات لحماية الأطفال من التسول والعمل على توفير بيئة مناسبة لهم.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- توفير الرعاية الاجتماعية:</strong> يجب تكثيف الجهود الحكومية لتوفير برامج اجتماعية تهدف إلى تأمين احتياجات الأطفال، مثل التعليم، الرعاية الصحية، والدعم النفسي، من أجل الوقاية من تسولهم.</li>
      <li><strong>- التعاون مع المنظمات:</strong> التعاون مع الجمعيات الخيرية والمنظمات غير الحكومية لضمان حماية الأطفال من التسول والعمل على إعادة تأهيلهم في بيئة آمنة.</li>
      <li><strong>- التوعية للأسر:</strong> من المهم توعية الأسر حول مخاطر التسول وتوفير الدعم لهم حتى يتمكنوا من تلبية احتياجات أطفالهم دون الحاجة للتسول.</li>
      <li><strong>- إنفاذ القوانين:</strong> يجب تشديد تطبيق القوانين التي تحظر تسول الأطفال، مع فرض عقوبات صارمة على المخالفين لحماية حقوق الأطفال من الاستغلال.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التسول ليس حلاً، وعلينا أن نعمل معاً لحماية أطفالنا من هذه الممارسات الضارة وتوفير بيئة آمنة ومستقرة لهم لكي ينمو بشكل صحي وآمن.</p>
    `,
    category: ["street", "social", "children"],
  },
  {
    id: 120,
    behavior: "الهرڭاوي هو لي كيخلي الخادمة ديال الدار تخدم عندو بلا تأمين.",
    solution: `
    <p>من المهم أن يحصل كل عامل، بما في ذلك العاملين في المنازل، على حقوقه الأساسية مثل التأمين الاجتماعي والعلاج الطبي. عدم توفير هذه الحقوق يعرض هؤلاء العمال للخطر ويعتبر استغلالاً لجهودهم. من واجب أصحاب المنازل احترام حقوق العمال والعمل وفقاً للقوانين التي تحميهم.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التزام القوانين:</strong> يجب على أصحاب المنازل الالتزام بالقوانين المتعلقة بتوظيف الخدم، بما في ذلك تسجيلهم في نظام التأمينات الاجتماعية وتوفير لهم حقوقهم القانونية مثل التأمين الصحي.</li>
      <li><strong>- التوعية:</strong> يجب نشر الوعي بين أصحاب المنازل حول أهمية توفير الحماية للعمال وحفظ حقوقهم في العمل، والتأكيد على ضرورة معاملة الخدم بإنصاف واحترام.</li>
      <li><strong>- مراقبة وتنفيذ القوانين:</strong> يجب أن تعمل السلطات على تطبيق قوانين العمل بشكل فعال وتدقيق ظروف العمل للأفراد الذين يعملون في المنازل لضمان حصولهم على حقوقهم.</li>
      <li><strong>- الدعم للعمال:</strong> من المهم إنشاء برامج لدعم العاملين في المنازل، مثل تقديم استشارات قانونية حول حقوقهم وتسهيل الوصول إلى التأمين الصحي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>كل عامل يستحق الاحترام والمعاملة العادلة. علينا أن نعمل على تحسين ظروف العمل وضمان حقوق العمال في جميع المجالات بما في ذلك العمال في المنازل.</p>
    `,
    category: ["social", "work", "home", "health"],
  },
  {
    id: 121,
    behavior: "الهرڭاوي هو لي كيدير جمعية خيرية و كياكل الفلوس.",
    solution: `
    <p>إن القيام بأنشطة خيرية يتطلب النزاهة والشفافية في استخدام الأموال. أي تلاعب أو فساد في الجمعيات الخيرية يعرض سمعة العمل الخيري للتشويه ويضر بالمتطوعين والمستفيدين على حد سواء. يجب أن يكون هناك رقابة صارمة على هذه الجمعيات لضمان وصول الأموال للمحتاجين وفقاً للأهداف المعلنة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الشفافية والمحاسبة:</strong> يجب على القائمين على الجمعيات الخيرية أن يوفروا تقارير مالية واضحة للمجتمع والمتبرعين توضح كيفية استخدام الأموال والتأكد من أنها تُنفق في الأغراض المحددة.</li>
      <li><strong>- تشديد الرقابة:</strong> يجب أن يكون هناك رقابة حكومية أو من منظمات مستقلة على نشاطات الجمعيات الخيرية، للتأكد من عدم حدوث فساد أو استغلال للموارد.</li>
      <li><strong>- التوعية المجتمعية:</strong> من الضروري توعية المجتمع بمخاطر الفساد في الجمعيات الخيرية وكيفية التمييز بين الجمعيات ذات السمعة الطيبة والجمعيات التي تستغل الأموال لأغراض شخصية.</li>
      <li><strong>- تشجيع التبرعات المشروعة:</strong> يجب تشجيع الناس على التبرع للجمعيات التي تعمل بشفافية ولها سجل حافل في مساعدة الفئات المحتاجة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العمل الخيري يجب أن يكون نزيهاً وأميناً. لا مكان للفساد في الجمعيات الخيرية، فالمحتاجون هم المستفيدون الأولون من هذا العمل، ويجب أن تُنفق التبرعات بشكل مسؤول وواضح.</p>
    `,
    category: ["social"],
  },
  {
    id: 122,
    behavior: "الهرڭاوي هو لي كيدير براكة فالشارع بلا ترخيص.",
    solution: `
    <p>إقامة البراكات أو الأكشاك في الشوارع بدون ترخيص تشكل تعديًا على الممتلكات العامة وتسبب فوضى في النظام العام. هذه الممارسات تؤثر سلبًا على المظهر الحضري وتسبب مشاكل تتعلق بالسلامة والبيئة. يجب أن يتم تنظيم هذا النوع من الأنشطة وفقًا للقوانين المحلية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الالتزام بالقوانين والأنظمة:</strong> يجب على الأفراد الراغبين في إقامة براكة أو كشك في الشارع أن يحصلوا على الترخيص المطلوب من السلطات المحلية.</li>
      <li><strong>- تحسين الرقابة:</strong> يجب على السلطات المحلية تكثيف الرقابة على الأماكن العامة للتأكد من عدم وجود أي تعديات على الأرصفة أو الشوارع.</li>
      <li><strong>- إعادة تنظيم الأماكن:</strong> من الضروري تخصيص أماكن مخصصة للباعة في المناطق التجارية أو الأسواق، مما يساعد في تنظيم الحركة التجارية دون إعاقة المرور أو التأثير على البيئة.</li>
      <li><strong>- التوعية القانونية:</strong> يجب نشر الوعي بين المواطنين حول أهمية الحصول على التراخيص اللازمة لتجنب المخالفات القانونية والمشاكل التي قد تنشأ.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>احترام القوانين هو أساس بناء مجتمع منظم وآمن. لا يجوز إعاقة حركة المرور أو التعدي على الممتلكات العامة، ويجب على كل فرد الالتزام بالقوانين لضمان سلامة الجميع.</p>
    `,
    category: ["street", "social", "public"],
  },
  {
    id: 123,
    behavior: "الهرڭاوي هو لي كيدير حضانة بلا ترخيص فالدار.",
    solution: `
    <p>إقامة حضانة أو مؤسسة تعليمية في المنزل بدون ترخيص تشكل خطرًا على الأطفال الذين يتلقون الرعاية هناك. هذا التصرف قد يسبب قلة الأمان وعدم توفر الظروف المناسبة للتعليم أو الرعاية. يجب أن يتم تنظيم مثل هذه الأنشطة وفقًا للقوانين المحلية لضمان سلامة الأطفال وجودة الرعاية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الحصول على الترخيص المناسب:</strong> يجب على الأفراد الذين يرغبون في فتح حضانة أو مؤسسة تعليمية في المنزل التقدم للحصول على ترخيص من السلطات المحلية المعنية.</li>
      <li><strong>- التأكد من السلامة:</strong> يجب أن توفر الحضانة بيئة آمنة وصحية للأطفال، وأن تلتزم بكافة الإجراءات الوقائية مثل توفير ألعاب آمنة ومساحة مناسبة للتعلم والنمو.</li>
      <li><strong>- التفتيش والمتابعة:</strong> من المهم أن يتم إجراء تفتيشات دورية من قبل الجهات المعنية لضمان أن الحضانات التي تعمل في المنازل تستوفي كافة الشروط الصحية والتعليمية.</li>
      <li><strong>- التوعية بأهمية الترخيص:</strong> يجب نشر الوعي بين المواطنين حول أهمية الحصول على التراخيص اللازمة لإقامة حضانات في المنازل لضمان جودة الرعاية والحفاظ على حقوق الأطفال.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>رعاية الأطفال مسؤولية كبيرة ويجب أن تتم في بيئة آمنة وفقًا للمعايير القانونية. الحصول على الترخيص يعزز الأمان ويساهم في تقديم أفضل رعاية للأطفال.</p>
    `,
    category: ["social", "work", "school"],
  },
  {
    id: 124,
    behavior: "الهرڭاوي هو لي كيبيع لحم الحمير على أنه لحم البقر.",
    solution: `
    <p>بيع لحم الحمير على أنه لحم بقر يشكل جريمة صحية وأخلاقية، حيث يعرّض صحة المستهلكين للخطر ويخالف قوانين السلامة الغذائية. هذا الفعل يعكس انعدام الأمانة ويضر بمصداقية السوق المحلي. يجب أن يتم التحقق من مصدر اللحوم وضمان مطابقتها للمعايير الصحية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التشديد على الرقابة الصحية:</strong> يجب تكثيف الرقابة من قبل الجهات المعنية مثل وزارة الصحة ووزارة التجارة للتأكد من سلامة اللحوم المعروضة في الأسواق.</li>
      <li><strong>- التوعية بخطورة بيع اللحوم غير المصرح بها:</strong> نشر التوعية بين المواطنين حول مخاطر شراء اللحوم من مصادر غير موثوقة وكيفية التحقق من مصدر اللحوم التي يشترونها.</li>
      <li><strong>- معاقبة المخالفين:</strong> يجب أن تكون هناك عقوبات صارمة ضد الأشخاص الذين يبيعون لحوم غير مطابقة للمواصفات أو الذين يتلاعبون بمصادر اللحوم بهدف الربح غير المشروع.</li>
      <li><strong>- التأكد من الشهادات الصحية:</strong> يجب على محلات بيع اللحوم تقديم شهادات صحية تؤكد أن اللحوم التي يبيعونها خضعت للفحص الطبي ولا تشكل خطرًا على صحة المواطنين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>من المهم أن يكون هناك وعي عام بمخاطر تناول لحوم غير معروفة المصدر، كما يجب أن نتعاون مع الجهات المختصة لحماية صحة المجتمع من المخاطر الصحية.</p>
    `,
    category: ["social", "work", "health", "animals"],
  },
  {
    id: 125,
    behavior: "الهرڭاوي هو لي كيحط البركاصة حدا المدرسة.",
    solution: `
    <p>وضع البركاصة (أو أي نوع من المحلات التجارية أو الأكشاك) بالقرب من المدرسة يمكن أن يشكل تهديدًا لصحة وسلامة الطلاب، بالإضافة إلى أن وجود هذه المحلات قد يؤدي إلى تزايد حركة المرور وتعريض الطلاب للمخاطر. يجب أن تتم إقامة المحلات التجارية في الأماكن المناسبة بعيدًا عن المؤسسات التعليمية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- مراجعة السياسات المحلية:</strong> يجب على السلطات المحلية أن تضع قوانين تمنع إنشاء المحلات التجارية بالقرب من المدارس، وخاصة إذا كانت هذه المحلات تبيع سلعًا قد تؤثر على صحة الطلاب مثل الأطعمة غير الصحية أو المشروبات الغازية.</li>
      <li><strong>- الرقابة على الأماكن المحيطة بالمدارس:</strong> يجب تكثيف الرقابة على الأنشطة التجارية القريبة من المدارس، والتأكد من أن المحلات تلتزم بالقوانين الخاصة بالصحة العامة وسلامة الأطفال.</li>
      <li><strong>- إشراك المجتمع المحلي:</strong> من المهم إشراك أولياء الأمور والمجتمع المحلي في اتخاذ قرارات حول الأماكن المناسبة لإقامة المحلات التجارية حول المدارس، وذلك لضمان بيئة آمنة وصحية للطلاب.</li>
      <li><strong>- التوعية بأضرار المحلات غير المناسبة:</strong> من خلال حملات توعية موجهة إلى أصحاب المحلات التجارية وأولياء الأمور والمجتمع، يتم التركيز على أهمية الحفاظ على بيئة آمنة للأطفال.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>من المهم أن نحرص جميعًا على توفير بيئة صحية وآمنة للطلاب في محيط المدارس، وذلك من خلال التزامنا بالقوانين والحرص على مصلحة الأطفال وسلامتهم.</p>
    `,
    category: ["school", "work", "social", "health"],
  },
  {
    id: 126,
    behavior: "الهرڭاوي هو لي كيبيع المواد المهربة فالسوق.",
    solution: `
    <p>بيع المواد المهربة يعد مخالفة قانونية تشكل تهديدًا للصحة العامة، إذ قد تحتوي هذه المواد على مكونات غير معروفة أو غير خاضعة للرقابة، مما يعرض المستهلكين لمخاطر صحية كبيرة. بالإضافة إلى ذلك، يؤثر هذا النشاط على الاقتصاد المحلي ويشجع على انتشار التجارة غير المشروعة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز الرقابة القانونية:</strong> يجب تكثيف جهود الشرطة والسلطات المحلية لمكافحة بيع المواد المهربة، من خلال تنفيذ حملات تفتيش دورية في الأسواق.</li>
      <li><strong>- التوعية المجتمعية:</strong> يجب تنظيم حملات توعية للجمهور حول مخاطر شراء المواد المهربة، والتأكيد على أهمية شراء السلع من أماكن معترف بها وموثوقة.</li>
      <li><strong>- تشديد العقوبات:</strong> يجب أن تكون هناك عقوبات صارمة ضد التجار الذين يبيعون المواد المهربة، لردعهم عن ممارسة هذا النشاط غير القانوني.</li>
      <li><strong>- تشجيع التجارة القانونية:</strong> يجب دعم وتعزيز التجارة القانونية في السوق، من خلال تسهيل إجراءات البيع والترخيص للأعمال التجارية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>المواد المهربة لا تضر فقط بصحتك، بل تضر أيضًا بالاقتصاد المحلي. اختر دائمًا شراء المنتجات القانونية من مصادر موثوقة لضمان صحتك وسلامتك.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 127,
    behavior: "الهرڭاوي هو لي كيدير حمام فالدار و كيضر الجيران بالدخان.",
    solution: `
    <p>تشغيل حمام داخل المنزل بشكل غير مناسب يمكن أن يكون له تأثيرات سلبية على الجيران والبيئة المحيطة. الدخان الناتج عن هذه الأنشطة قد ينتشر إلى المنازل المجاورة، مما يتسبب في تلوث الهواء ويضر بالصحة العامة، خاصة لأولئك الذين يعانون من مشاكل في الجهاز التنفسي.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- مراقبة التهوية:</strong> يجب التأكد من أن الحمام الداخلي مزود بتهوية جيدة لاحتواء الدخان ومنع تسريبه إلى المنازل المجاورة.</li>
      <li><strong>- استخدام تقنيات صديقة للبيئة:</strong> يمكن استخدام تقنيات حديثة لتقليل الانبعاثات الدخانية مثل فلاتر الهواء أو أجهزة تنقية الهواء.</li>
      <li><strong>- التفاهم مع الجيران:</strong> من المهم التواصل مع الجيران لتحديد أوقات معينة لاستخدام الحمام أو اتباع طرق لتقليل الإزعاج الناتج عن الدخان.</li>
      <li><strong>- التنظيم القانوني:</strong> يجب على السلطات المحلية وضع لوائح خاصة بتشغيل الحمامات المنزلية، مع تحديد معايير للحفاظ على صحة البيئة والمجتمع.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>مراعاة الراحة الصحية للجيران والبيئة هي مسؤولية الجميع. اختر دائمًا طرقًا آمنة ومستدامة لاستخدام الحمام في المنزل للحفاظ على الصحة العامة.</p>
    `,
    category: ["social", "environment", "health", "home"],
  },
  {
    id: 128,
    behavior: "الهرڭاوي هو الفلاح لي كيسقي الخضرة بالما الموسخ.",
    solution: `
    <p>سقاية النباتات بالمياه الملوثة يمكن أن تكون لها آثار سلبية على البيئة والصحة العامة. المياه الملوثة قد تحتوي على مواد كيميائية أو بكتيريا ضارة قد تنتقل إلى النباتات، مما يسبب تلوث الطعام الذي يتم استهلاكه لاحقًا.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- استخدام المياه النظيفة:</strong> يجب على الفلاحين استخدام المياه النظيفة والصحية لري المحاصيل الزراعية لضمان جودة الطعام وسلامته.</li>
      <li><strong>- إعادة تدوير المياه:</strong> يمكن استغلال مياه الأمطار أو مياه الصرف الصحي المعالجة بشكل آمن لسقاية المحاصيل، مما يساهم في الحفاظ على الموارد المائية.</li>
      <li><strong>- الاستثمار في تقنيات الري الحديثة:</strong> من خلال استخدام تقنيات الري الحديثة مثل الري بالتنقيط، يمكن تحسين كفاءة استهلاك المياه وتفادي استخدام المياه الملوثة.</li>
      <li><strong>- التوعية الصحية:</strong> من الضروري أن يتلقى الفلاحون تدريبًا على أهمية استخدام المياه النظيفة وتأثير المياه الملوثة على المحاصيل والصحة العامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>تسقى النباتات بالمياه النظيفة للحفاظ على جودة المحاصيل وسلامة الصحة العامة. الاهتمام بمصادر المياه يضمن استدامة البيئة وتحسين صحة المجتمع.</p>
    `,
    category: ["work", "environment", "health"],
  },
  {
    id: 129,
    behavior:
      "الهرڭاوي هو لي كيخلي الناس يوقفو يتسناو في الإدارة و هو جالس كيشرب أتاي.",
    solution: `
    <p>التصرف الذي يتضمن تجاهل احترام الآخرين في بيئة العمل يُعتبر سلوكًا غير مهني ويؤثر سلبًا على سير العمل. مثل هذه التصرفات تؤدي إلى الشعور بالإحباط وعدم التقدير من قبل الزملاء أو المتعاملين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز الاحترام المتبادل:</strong> يجب أن يكون هناك احترام متبادل بين الجميع في مكان العمل، ويجب على كل فرد الالتزام بالتعامل مع الآخرين بروح التعاون والاحترام.</li>
      <li><strong>- تعزيز قيم العدالة والمساواة:</strong> من المهم أن يتعامل المسؤولون مع الجميع بشكل عادل، ولا ينبغي لهم الاستفادة من وضعهم في الحصول على معاملة خاصة أثناء أوقات العمل.</li>
      <li><strong>- التوعية والسلوك المهني:</strong> يجب تنظيم ورش عمل لتوعية الموظفين بالقيم المهنية، بما في ذلك الاحترام المتبادل وأهمية احترام وقت الآخرين.</li>
      <li><strong>- تشجيع التواصل الفعّال:</strong> يمكن تحسين بيئة العمل من خلال تشجيع الموظفين على التواصل الفعّال وتبادل الآراء والأفكار، مما يسهم في خلق بيئة مهنية صحية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التصرف باحترام وتقدير للآخرين يسهم في تحسين بيئة العمل ويساعد في زيادة الإنتاجية والروح الجماعية. استمتع بوقتك، لكن لا تنسى مسؤولياتك.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 130,
    behavior: "الهرڭاوي هو لي كيدير فران و كيحرق البلاستيك.",
    solution: `
    <p>حرق البلاستيك يُعتبر سلوكًا مضرًا بالبيئة والصحة العامة. البلاستيك عندما يُحرق ينتج عنه ملوثات سامة تؤثر على جودة الهواء وتسبب أضرارًا صحية للإنسان والكائنات الحية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بالمخاطر الصحية والبيئية:</strong> يجب نشر التوعية حول الأضرار الناتجة عن حرق البلاستيك وكيفية التعامل مع النفايات بشكل آمن وصحي.</li>
      <li><strong>- استخدام تقنيات صديقة للبيئة:</strong> يُفضل استخدام تقنيات حديثة وآمنة لمعالجة النفايات والحد من حرق المواد البلاستيكية.</li>
      <li><strong>- تشجيع إعادة التدوير:</strong> من المهم تعزيز ثقافة إعادة التدوير وتقليل استهلاك البلاستيك، والعمل على إيجاد حلول مستدامة للتعامل مع النفايات.</li>
      <li><strong>- فرض قوانين صارمة:</strong> يجب تطبيق قوانين تحظر حرق البلاستيك وتشجع على التخلص من النفايات بطرق آمنة وصديقة للبيئة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>حرق البلاستيك ليس فقط خطرًا على البيئة، بل يؤثر أيضًا بشكل مباشر على صحتنا. لنبادر جميعًا للبحث عن طرق أكثر أمانًا ونظافة في معالجة النفايات.</p>
    `,
    category: ["social", "environment", "health"],
  },
  {
    id: 131,
    behavior: "الهرڭاوي هو لي كيدير مخبزة بلا رخصة.",
    solution: `
    <p>فتح مخبزة بدون رخصة يعتبر خرقًا للقوانين المعمول بها في العديد من البلدان. هذا السلوك يضر بالصحة العامة، حيث قد يتم استخدام مواد غير آمنة أو تقنيات غير صحية في إنتاج المخبوزات، مما يهدد سلامة المواطنين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التزام بالقوانين المحلية:</strong> يجب على أي شخص يرغب في فتح مخبزة أن يحصل على الترخيص المناسب من الجهات المختصة، لضمان تطبيق معايير الصحة والسلامة.</li>
      <li><strong>- التفتيش الدوري:</strong> ينبغي تنفيذ عمليات تفتيش دورية للتأكد من أن المحلات التجارية تعمل وفقًا للمعايير الصحية والبيئية.</li>
      <li><strong>- التوعية الصحية:</strong> يجب توعية أصحاب المحلات التجارية بأهمية الالتزام بالقوانين والمعايير الصحية من أجل ضمان سلامة المنتجات المقدمة للمستهلكين.</li>
      <li><strong>- تشجيع الأعمال القانونية:</strong> من المهم تشجيع أصحاب الأعمال على اتباع الإجراءات القانونية لتجنب المخاطر الصحية والتأكد من تقديم منتجات ذات جودة عالية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>افتتاح مخبزة بدون رخصة قد يعرض صحة المجتمع للخطر. من أجل مصلحة الجميع، يجب الالتزام بالقوانين للحصول على ترخيص رسمي وفتح الأعمال بشكل قانوني وآمن.</p>
    `,
    category: ["social", "work", "environment", "health"],
  },
  {
    id: 132,
    behavior: "الهرڭاوي هو لي كيخلي الناس يخدمو بلا عقد.",
    solution: `
    <p>العمل بدون عقد رسمي يعد انتهاكًا لحقوق العمال ويعرضهم للعديد من المخاطر القانونية والمالية. العمال الذين يعملون بدون عقد لا يتمتعون بالحماية القانونية التي يوفرها لهم عقد العمل الرسمي، مثل التأمينات الاجتماعية، حقوق الأجر، والإجازات.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- إبرام عقد عمل رسمي:</strong> يجب على أصحاب الأعمال أن يضمنوا توقيع عقد عمل رسمي بين صاحب العمل والعامل، يحدد حقوق وواجبات كل طرف.</li>
      <li><strong>- الالتزام بالقوانين العمالية:</strong> ينبغي على أصحاب العمل الالتزام بالقوانين المحلية التي تحمي حقوق العمال، بما في ذلك تأمين العمال، وتحديد الأجور، وضمان ظروف العمل الآمنة.</li>
      <li><strong>- التوعية بحقوق العمال:</strong> يجب تعزيز الوعي بين العمال حول حقوقهم القانونية في مكان العمل، وتشجيعهم على المطالبة بحقوقهم إذا كانت مهضومة.</li>
      <li><strong>- فرض عقوبات على المخالفين:</strong> من الضروري فرض عقوبات على أصحاب العمل الذين لا يلتزمون بتوفير عقود عمل رسمية أو الذين يستغلون العمال بشكل غير قانوني.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العمل بدون عقد رسمي يعرض العمال لمخاطر متعددة. من أجل حقوقهم وحمايتهم، يجب التأكد من أن جميع الأعمال تتم وفقًا لقوانين العمل المعمول بها.</p>
    `,
    category: ["work", "social"],
  },
  {
    id: 133,
    behavior: "الهرڭاوي هو لي كيبيع العصير المغشوش.",
    solution: `
    <p>بيع العصير المغشوش يُعتبر غشًا تجاريًا ويُعرض صحة المستهلكين للخطر. العصير المغشوش قد يحتوي على مواد ضارة أو غير صالحة للاستهلاك، مما يؤدي إلى مشاكل صحية قد تكون خطيرة. بيع مثل هذه المنتجات يضر بالمستهلكين ويسبب أضرارًا للصحة العامة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- مراقبة الجودة:</strong> يجب على الجهات المختصة تنفيذ حملات تفتيش دورية على الأسواق والمحال التجارية للتأكد من أن المنتجات الغذائية، بما في ذلك العصير، تلتزم بمعايير الجودة والسلامة الصحية.</li>
      <li><strong>- التوعية ضد الغش التجاري:</strong> يجب توعية المواطنين بأهمية شراء المنتجات من مصادر موثوقة، والتحقق من مكونات المنتجات المدونة على العبوات.</li>
      <li><strong>- فرض عقوبات قانونية:</strong> ينبغي تطبيق عقوبات صارمة ضد البائعين الذين يقومون ببيع منتجات مغشوشة، بما في ذلك فرض غرامات مالية أو إغلاق المحال المخالفة.</li>
      <li><strong>- تحفيز المحلات على البيع المسؤول:</strong> تشجيع أصحاب المحلات التجارية على تقديم منتجات ذات جودة عالية، ومراجعة مصادرهم لتقديم منتج آمن للعملاء.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>شراء العصير المغشوش يعرض صحتك للخطر. حافظ على صحتك واشترِ منتجات من أماكن موثوقة تلتزم بمعايير السلامة الصحية.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 134,
    behavior: "الهرڭاوي هو لي كيبيع التذاكر ديال الماتش فالسوق السوداء.",
    solution: `
    <p>بيع التذاكر في السوق السوداء هو عمل غير قانوني يعرض المشترين للبائعين إلى أضرار كبيرة. كما أنه يتسبب في مشاكل للوصول العادل للجماهير المتعطشة لحضور المباريات. السوق السوداء تؤدي إلى زيادة الأسعار بشكل مبالغ فيه وتمنع الأشخاص الذين لم يكن لديهم القدرة على شراء التذاكر الأصلية من الحصول عليها.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التنظيم الصارم:</strong> يجب على السلطات المحلية تطبيق قوانين صارمة ضد بيع التذاكر في السوق السوداء، مع فرض عقوبات على البائعين الذين يتورطون في هذه الأنشطة غير القانونية.</li>
      <li><strong>- التحكم في التوزيع:</strong> تنظيم مبيعات التذاكر بحيث تُباع من خلال القنوات الرسمية فقط، مثل مواقع الإنترنت الرسمية أو نقاط البيع المعتمدة، لتجنب الاستغلال ورفع الأسعار.</li>
      <li><strong>- التوعية والتثقيف:</strong> التوعية بأضرار السوق السوداء وأهمية شراء التذاكر من الأماكن الرسمية يمكن أن تساعد في تقليل الطلب على التذاكر غير القانونية.</li>
      <li><strong>- تقنيات منع التزوير:</strong> تطبيق تقنيات أمان حديثة على التذاكر، مثل الأكواد الرقمية أو الباركود، بحيث يصعب تزويرها أو بيعها في السوق السوداء.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>تجنب شراء التذاكر من السوق السوداء واحرص على شراء تذاكر المباريات من المصادر الرسمية لضمان الأمان والعدالة في الأسعار.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 135,
    behavior: "الهرڭاوي هو لي كيخلي العمال يخدمو فوق 12 ساعة.",
    solution: `
    <p>إجبار العمال على العمل لأكثر من 12 ساعة يومياً يُعتبر استغلالاً وينتهك حقوق العمال. العمل لساعات طويلة قد يؤدي إلى الإرهاق الجسدي والنفسي، كما يزيد من احتمال الإصابة بالأمراض المهنية، ويؤثر سلباً على حياة العامل الشخصية. بالإضافة إلى ذلك، يمكن أن يؤثر على الإنتاجية ويزيد من احتمالية حدوث الحوادث في مكان العمل.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تطبيق قوانين العمل:</strong> يجب على الشركات احترام قوانين العمل المحلية والدولية التي تحدد ساعات العمل القصوى. يجب توفير بيئة عمل صحية وآمنة تُحترم فيها حقوق العمال.</li>
      <li><strong>- تشجيع ساعات العمل المرنة:</strong> توفير ساعات عمل مرنة يتيح للعمال إدارة وقتهم بشكل أفضل، مما يساهم في تقليل الضغوطات النفسية والجسدية عليهم.</li>
      <li><strong>- الراحة والإجازات:</strong> يجب على أصحاب العمل ضمان منح العمال فترات راحة كافية وأيام إجازات منتظمة لضمان صحتهم العامة.</li>
      <li><strong>- التوعية بحقوق العمال:</strong> التوعية المستمرة بحقوق العمال وأهمية الراحة بين فترات العمل يمكن أن تساعد في منع هذه الممارسات السلبية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لا يجوز لأحد أن يعمل لساعات طويلة تُؤثر على صحته ورفاهيته. علينا أن نتذكر أن الحفاظ على التوازن بين العمل والحياة هو مفتاح النجاح.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 136,
    behavior: "الهرڭاوي هو لي كيدير شركة نظافة و كيرمي الزبل فالخلا.",
    solution: `
    <p>إلقاء المخلفات في الأماكن غير المخصصة لها يمثل ضرراً كبيراً للبيئة والصحة العامة. يعتبر من غير المقبول أن تقوم شركات النظافة، التي يُفترض بها الحفاظ على نظافة البيئة، بإلقاء النفايات في أماكن غير قانونية أو في الطبيعة. هذا التصرف يمكن أن يؤدي إلى تلوث المياه، الهواء، والتربة، بالإضافة إلى تهديد صحة الإنسان والحيوانات.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- مراقبة صارمة:</strong> يجب على السلطات المحلية تنفيذ مراقبة صارمة على شركات النظافة لضمان عدم إلقاء المخلفات في الأماكن غير المخصصة. كما يجب فرض غرامات على المخالفين.</li>
      <li><strong>- التوعية البيئية:</strong> توعية الشركات والعاملين في قطاع النظافة بأهمية التعامل السليم مع النفايات وضرورة الحفاظ على البيئة.</li>
      <li><strong>- تشجيع التدوير:</strong> توجيه الشركات إلى تبني ممارسات تدوير النفايات واستخدامها بشكل مفيد بدلاً من التخلص منها بطرق غير قانونية.</li>
      <li><strong>- تحسين إدارة المخلفات:</strong> يجب تحسين نظام جمع وإدارة المخلفات في المناطق الحضرية لضمان التخلص الآمن والصحي من النفايات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>المحافظة على نظافة البيئة مسؤولية الجميع. على شركات النظافة أن تكون قدوة في الحفاظ على بيئة نظيفة وصحية للأجيال القادمة.</p>
    `,
    category: ["social", "public", "work", "environment", "health", "animals"],
  },
  {
    id: 137,
    behavior: "الهرڭاوي هو لي كيبيع المكياج المقلد للعيالات.",
    solution: `
    <p>بيع المكياج المقلد يعد من الممارسات الخطيرة التي تهدد صحة الأفراد، خاصة عند استخدامها على الجلد أو العينين. المكياج المقلد قد يحتوي على مواد كيميائية ضارة يمكن أن تسبب تهيجاً أو حساسية جلدية، وأحياناً قد تكون سامة. إضافة إلى ذلك، يُعتبر بيع المنتجات المقلدة انتهاكاً لحقوق الملكية الفكرية ويشكل تهديداً للاقتصاد المحلي.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- فرض عقوبات:</strong> يجب تطبيق قوانين صارمة ضد بيع المنتجات المقلدة. يُفضل وضع عقوبات رادعة للمخالفين بهدف حماية المستهلكين.</li>
      <li><strong>- التوعية بخطورة المنتجات المقلدة:</strong> نشر التوعية بين الناس حول الأضرار التي قد تلحق بهم جراء استخدام المكياج المقلد، خاصة في ظل انتشار الحملات التوعوية على وسائل التواصل الاجتماعي.</li>
      <li><strong>- تشجيع شراء المنتجات الأصلية:</strong> دعم الأسواق والمتاجر التي تبيع المنتجات الأصلية عبر توفير تسهيلات وعروض للمستهلكين.</li>
      <li><strong>- مراقبة الأسواق:</strong> تعزيز الرقابة على الأسواق ومحلات بيع المكياج، خاصة في المناطق التي تكثر فيها المنتجات المقلدة، من خلال الزيارات التفتيشية المنتظمة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>حافظي على سلامتك وراحتك باستخدام مستحضرات تجميل آمنة وموثوقة. لا تشتري المكياج المقلد لأنه قد يسبب مشاكل صحية خطيرة على المدى الطويل.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 138,
    behavior: "الهرڭاوي هو لي كيخلي الموظفين يخدمو فالعطلة بلا تعويض.",
    solution: `
    <p>إجبار الموظفين على العمل أثناء العطلات الرسمية دون تعويض يعتبر انتهاكاً لحقوقهم ويؤثر سلباً على رفاههم الشخصي والعائلي. وفقاً للقوانين العمالية في العديد من الدول، يجب أن يحصل الموظفون على تعويض مناسب عند العمل في أيام العطلات أو في أوقات استثنائية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تطبيق قوانين العمل:</strong> يجب التأكد من أن جميع أرباب العمل يلتزمون بالقوانين المحلية المتعلقة بالعمل في أيام العطلات. يشمل ذلك دفع تعويضات مالية أو منح إجازات بديلة للموظفين الذين يعملون خلال العطلات.</li>
      <li><strong>- التفاوض مع الموظفين:</strong> على الشركات التفاوض مع موظفيها بشأن العمل في أيام العطلات وإيجاد حلول مرضية للطرفين، مثل زيادة الأجر أو تحديد أوقات عطل تعويضية.</li>
      <li><strong>- التوعية بحقوق الموظفين:</strong> من المهم أن يكون الموظفون على دراية بحقوقهم القانونية، مثل حقوقهم في الحصول على تعويضات عن العمل في أيام العطلات.</li>
      <li><strong>- تعزيز الرقابة العمالية:</strong> تعزيز دور الهيئات الحكومية المختصة في مراقبة ظروف العمل وضمان تطبيق القوانين الخاصة بالعطلات والتعويضات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>حقوقك كموظف تتضمن الحصول على راحة وتعويض عادل في العطلات الرسمية. لا تتنازل عن حقوقك.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 139,
    behavior: "الهرڭاوي هو لي كيدير مركز ديال الهجرة و كينصب على الناس.",
    solution: `
    <p>الاحتيال في مراكز الهجرة يعتبر جريمة تؤثر على حياة الأفراد الذين يسعون للحصول على فرص أفضل. مراكز الهجرة يجب أن تكون مرخصة من السلطات المختصة وتعمل وفقاً للقوانين لضمان حقوق الناس وضمان شفافيتها.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التبليغ عن الاحتيال:</strong> يجب على الضحايا أو أي شخص يلاحظ حالات احتيال التبليغ عنها للسلطات المعنية مثل وزارة الداخلية أو إدارة الهجرة.</li>
      <li><strong>- التأكد من الترخيص:</strong> قبل التعامل مع أي مركز هجرة، يجب التحقق من أن المركز مرخص من الجهات الحكومية المعنية.</li>
      <li><strong>- التوعية بحقوق المهاجرين:</strong> من الضروري أن يتلقى المهاجرون معلومات واضحة وصحيحة حول حقوقهم وعملية الهجرة القانونية.</li>
      <li><strong>- إغلاق مراكز الاحتيال:</strong> يجب أن تقوم السلطات المعنية بإغلاق مراكز الهجرة التي تعمل بشكل غير قانوني وتفرض عقوبات على مرتكبي الاحتيال.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لا تقع ضحية الاحتيال، تحقق من صحة مراكز الهجرة واطلب استشارة قانونية قبل اتخاذ أي خطوة.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 140,
    behavior: "الهرڭاوي هو لي كيبيع المواد منتهية الصلاحية للمطاعم.",
    solution: `
    <p>بيع المواد الغذائية منتهية الصلاحية يشكل تهديداً لصحة المجتمع ويعد انتهاكاً لقوانين الصحة والسلامة الغذائية. يجب على المطاعم والموردين الالتزام بمعايير الصحة وشراء المواد الغذائية من مصادر موثوقة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التبليغ عن المخالفات:</strong> يجب على الأفراد أو العاملين في المطاعم الإبلاغ عن أي حالة لبيع مواد منتهية الصلاحية للسلطات المعنية مثل وزارة الصحة أو الهيئة المختصة في الرقابة الغذائية.</li>
      <li><strong>- التدقيق في الموردين:</strong> ينبغي للمطاعم التأكد من أن الموردين ملتزمين بقوانين سلامة الغذاء وتاريخ الصلاحية قبل شراء المواد الغذائية.</li>
      <li><strong>- التوعية بأضرار المواد منتهية الصلاحية:</strong> يجب أن يتلقى أصحاب المطاعم والموظفون توعية بخصوص مخاطر استخدام المواد منتهية الصلاحية وكيفية تحديد تاريخ الصلاحية على العبوات.</li>
      <li><strong>- تطبيق الرقابة:</strong> يجب على الجهات المختصة زيادة حملات الرقابة على الأسواق والمطاعم لضمان احترام معايير سلامة الغذاء ومنع بيع المواد المنتهية صلاحيتها.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الصحة هي الأهم، حافظ على معايير السلامة في طعامك وابتعد عن بيع أو استهلاك المواد الغذائية المنتهية صلاحيتها.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 141,
    behavior: "الهرڭاوي هو لي كيخلي الشاحنة ديالو تدخل للمدينة فوقت ممنوع.",
    solution: `
    <p>دخول الشاحنات إلى المدن في أوقات ممنوعة يعد من المخالفات التي تضر بحركة السير وتزيد من الازدحام. من الضروري احترام القوانين التي تنظم حركة المرور في المناطق الحضرية للحفاظ على النظام والسلامة العامة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الامتثال لأوقات الحظر:</strong> يجب على أصحاب الشاحنات أو سائقيها الالتزام بالأوقات المقررة من قبل السلطات المحلية لدخول الشاحنات إلى المدينة، سواء في ساعات الذروة أو خارجها.</li>
      <li><strong>- فرض عقوبات:</strong> ينبغي على السلطات المحلية فرض غرامات أو عقوبات على السائقين الذين يخالفون هذه القوانين لضمان الالتزام.</li>
      <li><strong>- التوعية بالقوانين:</strong> يجب توفير حملات توعية للسائقين حول أهمية احترام هذه الأوقات المحددة لتقليل الازدحام المروري وتحسين حركة السير في المدينة.</li>
      <li><strong>- تعزيز الرقابة:</strong> يجب تكثيف الرقابة على الشوارع والمناطق الحضرية لضمان عدم دخول الشاحنات في الأوقات الممنوعة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>احترم قوانين السير لتساهم في الحفاظ على سلامتك وسلامة الآخرين وضمان حركة مرور مرنة وآمنة في المدينة.</p>
    `,
    category: ["street", "social", "driving"],
  },
  {
    id: 142,
    behavior:
      "الهرڭاوي هو لي كيدير مركز ديال العلاج بالأعشاب و الحجامة بلا رخصة.",
    solution: `
    <p>فتح مراكز للعلاج بالأعشاب أو الحجامة بدون ترخيص من السلطات الصحية يعد انتهاكًا للقوانين المعمول بها في القطاع الصحي، ويشكل خطرًا على صحة المرضى. يجب على هؤلاء الذين يقدمون هذه الخدمات الالتزام بالمعايير القانونية والصحية المقررة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الحصول على الترخيص:</strong> يجب على أصحاب مراكز العلاج بالأعشاب والحجامة التقدم للحصول على الترخيص المناسب من وزارة الصحة أو السلطات المعنية قبل فتح أي مركز.</li>
      <li><strong>- التأكد من المؤهلات:</strong> من المهم أن يكون العاملون في هذه المراكز حاصلين على المؤهلات اللازمة والمعترف بها في مجال الطب البديل والعلاج بالأعشاب لتفادي المخاطر الصحية.</li>
      <li><strong>- الرقابة والتفتيش:</strong> يجب على السلطات الصحية إجراء حملات تفتيش منتظمة للتأكد من أن المراكز تعمل وفقًا للمعايير الصحية وتلتزم بالقوانين.</li>
      <li><strong>- التوعية العامة:</strong> يجب زيادة الوعي بين المواطنين حول أهمية التحقق من ترخيص مراكز العلاج البديل للتأكد من سلامتهم.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>صحتك أمانة، تأكد من التعامل مع محترفين مرخصين وتجنب المراكز غير القانونية للحفاظ على سلامتك.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 143,
    behavior: "الهرڭاوي هو لي كيخلي الناس يخدمو فالورش بلا خوذة.",
    solution: `
    <p>العمل في الورش بدون خوذة يعد من المخاطر الكبيرة التي تهدد صحة وسلامة العمال. يجب الالتزام بالمعايير الصحية والسلامة في أماكن العمل لتجنب الحوادث والإصابات التي قد تحدث بسبب الإهمال في توفير معدات الوقاية اللازمة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- إلزام العمال بارتداء الخوذات:</strong> يجب على صاحب الورشة أو المقاول التأكد من أن جميع العمال يرتدون خوذات الأمان أثناء العمل في الأماكن التي قد تشكل خطراً على الرأس.</li>
      <li><strong>- التدريب والتوعية:</strong> توفير دورات تدريبية للعمال حول أهمية استخدام معدات الحماية الشخصية، مثل الخوذات، لتجنب الإصابات الرأسية الخطيرة.</li>
      <li><strong>- الرقابة والمراجعة:</strong> يجب أن تكون هناك رقابة منتظمة من قبل الجهات المعنية لضمان التزام أصحاب الورش بالتدابير الوقائية.</li>
      <li><strong>- فرض عقوبات:</strong> يجب فرض غرامات وعقوبات على أصحاب الورش الذين لا يلتزمون بالقوانين المتعلقة بسلامة العمال.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>سلامتك أولوية! لا تتجاهل أهمية خوذة الأمان في مكان العمل لحماية رأسك من الإصابات الخطيرة.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 144,
    behavior: "الهرڭاوي هو لي كيخلي المستخدمين يمضيو على شيكات فارغة.",
    solution: `
    <p>التوقيع على شيكات فارغة يعد من التصرفات الغير قانونية وغير أخلاقية، حيث يمكن أن يؤدي إلى استغلال المستخدمين وتهديد حقوقهم المالية. يجب أن تلتزم الشركات بسياسات شفافة وواضحة للحفاظ على حقوق الموظفين وتجنب الممارسات الاحتيالية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- منع التوقيع على شيكات فارغة:</strong> يجب على الشركات التأكد من أن جميع المستندات المالية مكتملة وتحتوي على كافة المعلومات اللازمة قبل توقيع أي شخص عليها.</li>
      <li><strong>- التوعية والتدريب:</strong> توفير دورات تدريبية للموظفين حول حقوقهم المالية وكيفية حماية أنفسهم من أي ممارسات غير قانونية.</li>
      <li><strong>- وضع سياسات واضحة:</strong> يجب أن تضع الشركات سياسات صارمة لمنع أي نوع من الاستغلال، بما في ذلك التوقيع على شيكات فارغة.</li>
      <li><strong>- فرض عقوبات:</strong> فرض عقوبات قانونية على من يتورط في هذه الممارسات أو على الشركات التي لا تلتزم بالقوانين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>حافظ على حقوقك! لا توقع على شيكات فارغة لتجنب الوقوع في فخ الاحتيال المالي.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 145,
    behavior: "الهرڭاوي هو لي كيدير شركة للبناء و كيغش فالمواد.",
    solution: `
    <p>الغش في مواد البناء يمثل تهديدًا للأمان والاستدامة، وقد يؤدي إلى بناء مباني غير آمنة. يجب على الشركات في قطاع البناء الالتزام بمعايير الجودة والمواصفات المحددة من قبل السلطات المحلية لضمان سلامة المستهلكين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التدقيق في المواد:</strong> يجب على شركات البناء التأكد من جودة المواد المستخدمة من خلال فحص دوري والتأكد من مطابقتها للمعايير المعتمدة.</li>
      <li><strong>- العقوبات القانونية:</strong> فرض عقوبات على الشركات التي تثبت تورطها في الغش أو التلاعب في المواد، وتشمل هذه العقوبات غرامات مالية أو إلغاء الترخيص.</li>
      <li><strong>- تعزيز الشفافية:</strong> تشجيع المنافسة الشريفة في السوق من خلال توفير تقارير شفافة عن المواد والتكلفة المستخدمة في البناء.</li>
      <li><strong>- التوعية بالسلامة:</strong> توعية الشركات والعاملين في مجال البناء بضرورة الالتزام بممارسات البناء الآمن والنزيه للحفاظ على حياة الأشخاص والمجتمع.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الغش في مواد البناء ليس فقط غير أخلاقي، بل يشكل تهديدًا لصحة وأمان الأشخاص. حافظ على سلامة المجتمع من خلال الالتزام بالمعايير الصحيحة!</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 146,
    behavior: "الهرڭاوي هو لي كيبيع الأراضي بوثائق مزورة.",
    solution: `
    <p>بيع الأراضي بوثائق مزورة يعد جريمة احتيال تؤدي إلى ضياع حقوق الناس وتهديد الأمن العقاري في المجتمع. يجب على الجهات المختصة اتخاذ إجراءات صارمة لضبط مثل هذه الأنشطة غير القانونية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التحقيق والرقابة:</strong> تكثيف التحقيقات من قبل السلطات المختصة، مثل الشرطة والمحاكم، للكشف عن الأشخاص الذين يتورطون في بيع الأراضي بوثائق مزورة.</li>
      <li><strong>- التحقق من الوثائق:</strong> على الجهات العقارية تنفيذ آلية صارمة للتحقق من صحة الوثائق قبل إتمام أي صفقة عقارية، بما في ذلك التأكد من توقيعات وتصاريح الجهات المعنية.</li>
      <li><strong>- التوعية القانونية:</strong> نشر الوعي بين المواطنين حول أهمية التحقق من صحة الوثائق العقارية قبل شراء الأراضي أو الممتلكات، وتوعية المجتمع بعواقب التعامل مع الوثائق المزورة.</li>
      <li><strong>- عقوبات مشددة:</strong> فرض عقوبات قانونية صارمة على الأشخاص الذين يتم ضبطهم في مثل هذه الأعمال غير القانونية، مع تشديد العقوبات على الأشخاص الذين يتورطون في تزوير الوثائق.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>بيع الأراضي بوثائق مزورة ليس فقط جريمة احتيال، بل يضر بحقوق الآخرين ويعكر السلامة العقارية في المجتمع. احرص دائمًا على التحقق من صحة الوثائق لحماية حقوقك.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 147,
    behavior: "الهرڭاوي هو لي كيخلي العمال يخدمو فالمواد الكيماوية بدون حماية.",
    solution: `
    <p>تعريض العمال للعمل في بيئة تحتوي على مواد كيميائية دون اتخاذ التدابير الوقائية اللازمة يمكن أن يؤدي إلى إصابات صحية خطيرة. يجب اتخاذ إجراءات صارمة لضمان سلامة وصحة العمال.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التدريب والتوعية:</strong> توفير برامج تدريبية دورية للعاملين للتوعية بالخطوات والإجراءات الوقائية الواجب اتخاذها عند التعامل مع المواد الكيماوية، بما في ذلك استخدام معدات الوقاية الشخصية.</li>
      <li><strong>- المعدات الوقائية:</strong> توفير معدات الوقاية الشخصية المناسبة مثل القفازات والنظارات الواقية والملابس الواقية لضمان سلامة العاملين.</li>
      <li><strong>- الفحص الدوري:</strong> إجراء فحوصات دورية على العمال للتأكد من عدم تعرضهم لأية مواد كيميائية ضارة.</li>
      <li><strong>- الرقابة المستمرة:</strong> تشديد الرقابة من قبل الجهات المختصة مثل وزارة العمل والصحة لضمان الالتزام بتوفير الحماية اللازمة في بيئة العمل.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التعرض المستمر للمواد الكيماوية دون حماية قد يؤدي إلى مشاكل صحية خطيرة. يجب اتخاذ التدابير الوقائية اللازمة لضمان سلامة وصحة العاملين في بيئة العمل.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 148,
    behavior: "الهرڭاوي هو لي كيخلي الناس يشتغلو فالمناجم بلا أمان.",
    solution: `
    <p>العمل في المناجم يتطلب توفير بيئة عمل آمنة، حيث أن خطر الحوادث والإصابات في هذا القطاع مرتفع جداً إذا لم يتم اتباع إجراءات السلامة اللازمة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- توفير معدات الأمان:</strong> يجب على صاحب العمل توفير معدات أمان مثل الخوذ الواقية، والملابس الواقية، وأجهزة التنفس في المناجم التي تحتوي على غازات سامة أو ملوثة.</li>
      <li><strong>- التدريب المستمر:</strong> يجب تدريب جميع العاملين في المناجم على إجراءات السلامة، وكيفية التعامل مع المعدات بأمان، بالإضافة إلى الإسعافات الأولية في حال حدوث أي طارئ.</li>
      <li><strong>- إجراء فحوصات صحية دورية:</strong> ينبغي على العاملين الخضوع لفحوصات صحية دورية للكشف عن أي مشاكل صحية قد تنشأ بسبب ظروف العمل في المناجم.</li>
      <li><strong>- الرقابة على تنفيذ قواعد السلامة:</strong> يجب أن تكون هناك رقابة مستمرة لضمان أن جميع الإجراءات المتعلقة بالسلامة في المناجم يتم اتباعها بشكل دقيق.</li>
      <li><strong>- تأمين بيئة العمل:</strong> يجب إزالة أي مخاطر محتملة من مواقع العمل مثل التصدعات في الجدران أو الأرضيات أو وجود معدات تالفة قد تسبب الحوادث.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العمل في المناجم يشكل خطراً كبيراً على صحة وسلامة العاملين، لذا من الضروري توفير شروط الأمان اللازمة لحمايتهم من المخاطر.</p>
    `,
    category: ["social", "work", "health"],
  },
  {
    id: 149,
    behavior: "الهرڭاوي هو لي كيبيع شواهد طبية مزورة.",
    solution: `
    <p>بيع الشواهد الطبية المزورة هو عمل غير قانوني وله عواقب خطيرة على صحة المجتمع وسلامته. هذه الأفعال تعرض الأفراد للخطر وتعزز ثقافة التلاعب بالقوانين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تشديد العقوبات:</strong> يجب أن تكون هناك عقوبات صارمة ضد من يبيعون الشواهد الطبية المزورة، حيث يجب أن تشمل هذه العقوبات السجن وغرامات مالية كبيرة.</li>
      <li><strong>- مراقبة المشافي والعيادات:</strong> يجب على الجهات الصحية المختصة تكثيف مراقبتها للمرافق الطبية والتأكد من سلامة الشهادات الطبية الممنوحة.</li>
      <li><strong>- التوعية المجتمعية:</strong> ينبغي توعية الأفراد بمخاطر استخدام الشواهد المزورة، وكيف أن هذه الأفعال قد تؤدي إلى مشاكل صحية خطيرة.</li>
      <li><strong>- التعاون مع المؤسسات التعليمية:</strong> يجب على المدارس والجامعات التعاون مع السلطات الصحية لمراقبة الشواهد الطبية المقدمة من الطلاب أو الموظفين.</li>
      <li><strong>- تحفيز الاستخدام الصحيح للقوانين:</strong> ينبغي دعم المواطنين وتشجيعهم على الإبلاغ عن أي أنشطة مشبوهة، مثل بيع الشواهد الطبية المزورة، من خلال توفير وسائل آمنة للإبلاغ.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>استخدام الشواهد الطبية المزورة يمكن أن يضر بصحتك ويعرض الآخرين للمخاطر. من الضروري احترام القوانين واستخدام القنوات الرسمية للحصول على الخدمات الطبية.</p>
    `,
    category: ["social", "work", "school", "health"],
  },
  {
    id: 150,
    behavior: "الهرڭاوي هو لي كيخلي الناس يخدمو فالأعياد و العطل بلا تعويض.",
    solution: `
    <p>العمل في الأعياد والعطل بدون تعويض يعد انتهاكًا لحقوق العمال ويؤثر سلبًا على راحتهم ورفاهيتهم. يجب على أصحاب العمل احترام القوانين المتعلقة بالعمل في أيام العطل الرسمية وضمان دفع التعويضات المناسبة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التزام بأحكام العمل:</strong> يجب على أصحاب العمل الالتزام بالقوانين التي تحظر العمل في الأعياد والعطل الرسمية إلا إذا تم دفع تعويضات عادلة.</li>
      <li><strong>- فرض غرامات على المخالفين:</strong> ينبغي فرض غرامات مالية على الشركات التي لا تلتزم بدفع تعويضات العطل، وذلك لضمان العدالة في بيئة العمل.</li>
      <li><strong>- التوعية بحقوق العمال:</strong> يجب نشر التوعية بين العمال حول حقوقهم في الحصول على تعويضات عند العمل خلال العطل الرسمية. يجب عليهم معرفة كيفية المطالبة بهذه الحقوق إذا لزم الأمر.</li>
      <li><strong>- التشجيع على التفاوض:</strong> من المهم تشجيع العمال على التفاوض مع أرباب العمل للحصول على حقوقهم المالية والاجتماعية في حال تم طلب منهم العمل في العطل.</li>
      <li><strong>- دعم الدولة للعمال:</strong> يجب أن تقدم الحكومة برامج دعم للعمال الذين يعملون في الأعياد، بما في ذلك التأكيد على توجيه الشركات للامتثال للقوانين المتعلقة بالتعويضات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>يجب على الجميع احترام حقوق العمال في العطل الرسمية، بما في ذلك دفع التعويضات المناسبة لضمان الراحة والرفاهية. حقك في العطلة هو حقك في الراحة والعمل العادل.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 151,
    behavior: "الهرڭاوي هو لي كيدير محل ديال الحلاقة و كيستعمل موس واحد.",
    solution: `
    <p>استخدام أدوات الحلاقة بشكل غير صحي وغير معقم في محلات الحلاقة يمكن أن يؤدي إلى نقل الأمراض الجلدية أو التهابات خطيرة. يجب أن يتم تعقيم الأدوات بشكل دوري لمنع نقل العدوى.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التعقيم الدوري:</strong> يجب على أصحاب محلات الحلاقة تعقيم الأدوات بعد كل استخدام لتفادي انتقال الأمراض المعدية بين الزبائن.</li>
      <li><strong>- استخدام أدوات ذات استخدام واحد:</strong> يمكن استخدام شفرات حلاقة ذات استخدام واحد وتجنب استخدام الأدوات المتعددة الاستخدامات في حالة عدم إمكانية تعقيمها بشكل كامل.</li>
      <li><strong>- التوعية لأصحاب المحلات:</strong> من الضروري توعية أصحاب المحلات بكيفية التعقيم السليم والأدوات التي يجب استخدامها للحفاظ على صحة الزبائن.</li>
      <li><strong>- توفير معدات نظيفة لكل زبون:</strong> ينبغي التأكد من أن كل زبون يستخدم أدوات حلاقة جديدة أو معقمة في كل زيارة.</li>
      <li><strong>- التفتيش والرقابة الصحية:</strong> يجب على السلطات الصحية القيام بعمليات تفتيش دورية لمحلات الحلاقة للتأكد من التزامها بالمعايير الصحية والسلامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>صحة الزبائن أولوية! يجب على جميع محلات الحلاقة اتباع معايير التعقيم الجيدة لحماية الجميع من الأمراض المحتملة.</p>
    `,
    category: ["social", "public", "work", "health"],
  },
  {
    id: 152,
    behavior: "الهرڭاوي هو لي كيخلي العمال يرفعو وزن ثقيل بلا آلات.",
    solution: `
    <p>رفع الأوزان الثقيلة دون استخدام الآلات أو المعدات اللازمة يمكن أن يؤدي إلى إصابات جسدية خطيرة مثل تمزق العضلات أو مشاكل في الظهر. يجب توفير معدات السلامة المناسبة لضمان سلامة العمال.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- استخدام معدات السلامة:</strong> يجب على أصحاب العمل توفير الأدوات والآلات اللازمة لرفع الأوزان الثقيلة مثل الرافعات أو الحمالات الخاصة لتقليل المخاطر على العمال.</li>
      <li><strong>- التدريب على تقنيات الرفع السليم:</strong> ينبغي تدريب العمال على تقنيات رفع الأوزان بطريقة صحيحة لتفادي الإصابات، مثل الحفاظ على استقامة الظهر واستخدام العضلات السفلية بدلاً من العلوية.</li>
      <li><strong>- فحص الحالة الصحية للعمال:</strong> من الضروري إجراء فحوصات صحية دورية للعمال للتأكد من أنهم لا يعانون من مشاكل صحية قد تزيد من مخاطر الإصابة عند رفع الأوزان الثقيلة.</li>
      <li><strong>- تشجيع العمل الجماعي:</strong> في حالة الأوزان الثقيلة جداً، يجب تشجيع العمل الجماعي بحيث يقوم عدة عمال برفع الوزن معاً لتقليل الضغط على كل فرد.</li>
      <li><strong>- استخدام تقنيات الأتمتة:</strong> ينبغي على الشركات استخدام الآلات الحديثة والأتمتة لتقليل الحاجة للعمال في رفع الأوزان الثقيلة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>سلامة العمال مهمة! يجب أن يتم توفير الأدوات والتدريب اللازمين للحفاظ على صحة وسلامة العمال في بيئة العمل.</p>
    `,
    category: ["work", "health"],
  },
  {
    id: 153,
    behavior: "الهرڭاوي هو رجل التعليم لي مكيديرش خدمتو على أحسن وجه.",
    solution: `
    <p>عندما لا يؤدي المعلم وظيفته بشكل جيد، فإن ذلك يؤثر بشكل سلبي على جودة التعليم وتطور الطلاب. من المهم أن يعمل المعلمون على تحسين مهاراتهم باستمرار لضمان تقديم تعليم عالي الجودة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التطوير المهني المستمر:</strong> يجب على المعلمين المشاركة في دورات تدريبية وورش عمل منتظمة لتحسين مهاراتهم التعليمية والتربوية.</li>
      <li><strong>- التفاعل الإيجابي مع الطلاب:</strong> من الضروري أن يكون المعلم على اتصال دائم مع الطلاب، وأن يستمع إلى احتياجاتهم ومشاكلهم، مما يساهم في خلق بيئة تعليمية مشجعة.</li>
      <li><strong>- استخدام استراتيجيات تعليمية مبتكرة:</strong> ينبغي على المعلمين تنويع أساليب التدريس، مثل استخدام التقنيات الحديثة، والتعلم التعاوني، والأنشطة العملية لتحفيز الطلاب وزيادة التفاعل.</li>
      <li><strong>- التقييم المستمر لأداء الطلاب:</strong> من المهم تقييم الطلاب بانتظام لضمان تقدمهم وتقديم الدعم اللازم في حالة وجود صعوبات تعليمية.</li>
      <li><strong>- التحفيز والمكافآت:</strong> يجب تحفيز الطلاب على تحقيق أفضل أداء من خلال المكافآت والتشجيع، مما يزيد من حوافزهم ويشجعهم على الاجتهاد في الدراسة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>المعلم هو العامل الأساسي في نجاح التعليم! يجب عليه أن يكون ملتزماً بتقديم أفضل مستوى من التعليم من خلال التدريب المستمر والتفاعل الإيجابي مع الطلاب.</p>
    `,
    category: ["social", "work", "school", "children"],
  },
  {
    id: 154,
    behavior: "الهرڭاوي هو لي كيدير كلاكسون بلا سبب فوسط المدينة.",
    solution: `
    <p>استخدام الكلاكسون بشكل مفرط أو بلا سبب يسبب تلوثًا سمعيًا ويزعج السكان ويؤثر على الأجواء العامة في المدينة. هذا السلوك غير مسؤول ويجب الحد منه.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- الالتزام بقوانين السير:</strong> يجب على السائقين الالتزام بالقوانين التي تنظم استخدام الكلاكسون فقط في الحالات الطارئة أو عند الضرورة.</li>
      <li><strong>- التوعية بأهمية الهدوء:</strong> يمكن توعية السائقين بأضرار الضوضاء غير الضرورية وكيف تؤثر على الصحة العامة والبيئة.</li>
      <li><strong>- تطبيق العقوبات:</strong> يجب على السلطات المحلية تطبيق العقوبات على من يسيء استخدام الكلاكسون بشكل متكرر أو بدون مبرر.</li>
      <li><strong>- تعزيز ثقافة القيادة المسؤولة:</strong> يجب على السائقين فهم أن القيادة في المدينة تتطلب احترام الآخرين وعدم التسبب في إزعاج غير ضروري.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>كلما قلَّ استخدام الكلاكسون في المدينة، زادت الراحة والسلامة للسائقين والمشاة. يجب أن نكون جميعاً مسؤولين للحفاظ على هدوء مدينتنا!</p>
    `,
    category: ["social", "street", "public", "driving"],
  },
  {
    id: 155,
    behavior: "الهرڭاوي هو لي كيرمي النفايات من شرجم الطوموبيل.",
    solution: `
    <p>رمي النفايات من نافذة السيارة هو سلوك غير مسؤول ويساهم في تلوث البيئة وتشويه الأماكن العامة. هذا الفعل يتنافى مع احترام البيئة والمجتمع.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بأضرار التلوث:</strong> يجب توعية السائقين بأهمية الحفاظ على نظافة البيئة وعدم رمي النفايات في الأماكن العامة أو من داخل السيارة.</li>
      <li><strong>- تطبيق القوانين والجزاءات:</strong> يجب على السلطات المحلية فرض غرامات على من يتم ضبطهم وهم يرمون النفايات من السيارات، وفرض عقوبات صارمة لمنع تكرار هذه الأفعال.</li>
      <li><strong>- تشجيع استخدام صناديق القمامة:</strong> توفير أماكن مخصصة لرمى النفايات في الطرقات والمواقف لتحفيز الناس على استخدام صناديق القمامة بدلاً من إلقاء النفايات في الشارع.</li>
      <li><strong>- التحفيز على السلوك البيئي الجيد:</strong> يمكن تنظيم حملات توعوية لزيادة الوعي حول كيفية التخلص من النفايات بشكل مسؤول والحد من تلوث البيئة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على نظافة بيئتنا وأحيائنا من خلال التخلص الصحيح من النفايات. احترام البيئة يبدأ من سلوكنا الشخصي!</p>
    `,
    category: ["street", "public", "environment"],
  },
  {
    id: 156,
    behavior: "الهرڭاوي هو لي كيسد مدخل العمارة بالطوموبيل ديالو.",
    solution: `
    <p>سد مدخل العمارة بالسيارة يعتبر تصرفاً غير حضاري ويؤثر سلباً على راحة السكان الآخرين. يمكن أن يمنع هذا التصرف الأشخاص من الدخول أو الخروج من المبنى بشكل طبيعي.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بأهمية احترام الأماكن العامة:</strong> يجب توعية السائقين بخطورة تصرفات مثل هذه على حياة الناس وسلامتهم، والحث على ضرورة احترام قواعد المرور والأماكن العامة.</li>
      <li><strong>- إشارات مرورية وإجراءات وقائية:</strong> يمكن وضع لافتات أو إشارات مرورية في المنطقة لتوضيح أن وقوف السيارات في هذه الأماكن محظور وأنه يمكن أن يؤدي إلى عرقلة الحركة.</li>
      <li><strong>- الرقابة القانونية:</strong> ينبغي تطبيق غرامات أو جزاءات على الأشخاص الذين يقومون بسد مداخل العمارات بسياراتهم، وتحفيز الأشخاص على الالتزام بقوانين المرور.</li>
      <li><strong>- التعاون مع المجالس المحلية:</strong> العمل مع السلطات المحلية لوضع أنظمة مواقف آمنة وقانونية للسكان والزوار في المناطق السكنية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على راحة الآخرين ونلتزم بالقوانين التي تضمن سلامة وسهولة الحركة في الأماكن العامة. احترام الأماكن والممتلكات العامة هو أساس التعايش الجيد.</p>
    `,
    category: ["street", "public", "driving", "home"],
  },
  {
    id: 157,
    behavior: "الهرڭاوي هو لي كيهضر بصوت عالي فبلاصة عمومية.",
    solution: `
    <p>التحدث بصوت عالٍ في الأماكن العامة قد يسبب إزعاجاً للآخرين ويؤثر على راحتهم، خاصة في الأماكن التي تحتاج إلى الهدوء مثل المستشفيات أو المكتبات أو الأماكن العامة الأخرى.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بأهمية احترام الآخرين:</strong> يجب توعية الأفراد بأهمية احترام الهدوء في الأماكن العامة وكيف يمكن أن تؤثر أصواتهم العالية على الآخرين.</li>
      <li><strong>- إعادة تحديد الأماكن المناسبة للحديث:</strong> ينبغي أن يتفهم الناس أهمية اختيار الأماكن المناسبة للتحدث بصوت مرتفع، مثل الأماكن المفتوحة أو المقاهي.</li>
      <li><strong>- تعزيز الرقابة المجتمعية:</strong> يمكن للأفراد والمجتمعات المحلية أن يلعبوا دوراً في لفت الانتباه إلى هذه التصرفات ومحاولة تهدئة الوضع بطريقة محترمة.</li>
      <li><strong>- إجراءات قانونية:</strong> في بعض الحالات، قد يتم فرض قوانين تحد من الضوضاء في الأماكن العامة، مثل قوانين الضوضاء في المدن الكبرى، ويمكن تطبيق غرامات لمن يتسببون في الإزعاج.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الصوت العالي ليس فقط مزعجًا للآخرين، بل يعيق التعايش السلمي. فلنحرص جميعاً على استخدام صوتنا بطريقة محترمة ومناسبة للبيئة التي نتواجد فيها.</p>
    `,
    category: ["street", "social", "public", "work"],
  },
  {
    id: 158,
    behavior: "الهرڭاوي هو لي كيحط الزبل قدام دار الجار.",
    solution: `
    <p>رمي القمامة أمام منزل الجار يعد سلوكًا غير حضاري ويؤثر على البيئة والصحة العامة. هذا التصرف يسبب الإزعاج للجيران ويعرضهم للمخاطر الصحية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بالآثار السلبية:</strong> يجب توعية الأفراد بخطورة رمي القمامة في الأماكن العامة وأمام المنازل وكيف يؤثر ذلك على صحة البيئة وصحة الأفراد.</li>
      <li><strong>- تشجيع النظافة الشخصية والجماعية:</strong> يمكن تنظيم حملات توعية لحث الناس على الحفاظ على نظافة الأماكن المحيطة بهم وإبعاد القمامة عن المنازل والشوارع.</li>
      <li><strong>- فرض عقوبات قانونية:</strong> يمكن للسلطات المحلية تطبيق قوانين صارمة ضد من يرمون القمامة في الأماكن غير المخصصة لذلك، مثل فرض غرامات مالية أو إجراءات أخرى.</li>
      <li><strong>- توفير حاويات مخصصة للقمامة:</strong> من الضروري توفير حاويات ملائمة لفرز القمامة بالقرب من المناطق السكنية لتشجيع الناس على استخدامها بشكل صحيح.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>النظافة من الإيمان، فلنحرص على الحفاظ على بيئتنا نظيفة وصحية لمصلحة الجميع. تذكر أن ما نفعله في البيئة يؤثر على جيراننا والمجتمع بأسره.</p>
    `,
    category: ["street", "social", "public", "environment", "health"],
  },
  {
    id: 159,
    behavior: "الهرڭاوي هو لي كيسرق الكهرباء و الماء بطريقة غير قانونية.",
    solution: `
    <p>سرقة الكهرباء والماء بطريقة غير قانونية تعتبر جريمة تؤدي إلى العديد من الآثار السلبية على الاقتصاد والمجتمع. هذه التصرفات تؤدي إلى خسائر مالية كبيرة وتعرض الأشخاص للمسائلة القانونية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بالمخاطر القانونية:</strong> يجب نشر الوعي بين الناس حول المخاطر القانونية المترتبة على سرقة الكهرباء والماء، بما في ذلك الغرامات الكبيرة والسجن في بعض الحالات.</li>
      <li><strong>- تشجيع الاستخدام المسؤول:</strong> من المهم تعليم الأفراد كيفية استخدام الكهرباء والماء بطريقة مسؤولة لتجنب الحاجة إلى اللجوء إلى السرقة.</li>
      <li><strong>- تعزيز الرقابة والتفتيش:</strong> يمكن للسلطات المعنية تعزيز أنظمة الرقابة والتفتيش لضمان عدم حدوث سرقات في الشبكات وتطبيق عقوبات صارمة على المخالفين.</li>
      <li><strong>- إجراءات تحسين الأنظمة:</strong> يجب على الشركات المزودة للطاقة والمياه تطوير تقنيات للكشف المبكر عن السرقة، مثل استخدام أجهزة مراقبة حديثة للكشف عن التلاعب.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنساهم جميعًا في الحفاظ على الموارد العامة من أجل مصلحة الجميع. السرقة لا تؤدي إلا إلى الضرر للجميع، ولنكون جزءًا من الحل عبر الاستخدام المسؤول.</p>
    `,
    category: ["social", "home", "work"],
  },
  {
    id: 160,
    behavior:
      "الهرڭاوي هو لي كيبيع الخضرة فالتروطوار و كيجبر الناس يمشيو فالطريق.",
    solution: `
    <p>بيع الخضرة على الرصيف بشكل غير قانوني يسبب العديد من المشاكل في الأماكن العامة، حيث يعرقل حركة السير ويعرض السلامة العامة للخطر. هذه التصرفات تؤثر على بيئة المدينة وعلى راحة المواطنين.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تطبيق القوانين واللوائح المحلية:</strong> من المهم فرض القوانين الخاصة ببيع المنتجات في الأماكن العامة، حيث يجب أن تتم هذه الأنشطة في الأماكن المخصصة للبيع مثل الأسواق أو المحلات التجارية.</li>
      <li><strong>- توفير أماكن للبيع القانونية:</strong> يجب على السلطات المحلية توفير أسواق متخصصة ومرخصة للبيع في الأماكن العامة بحيث يمكن للبائعين ممارسة أعمالهم بشكل قانوني وآمن.</li>
      <li><strong>- حملات توعية:</strong> يجب تنظيم حملات توعية للمواطنين والبائعين حول أهمية احترام القوانين المتعلقة بالبائعين المتجولين وضرورة استخدام الأماكن المخصصة لذلك.</li>
      <li><strong>- تعزيز دور الشرطة المحلية:</strong> على الجهات المعنية تشديد الرقابة على هذه الأنشطة غير القانونية، وتطبيق العقوبات على المخالفين بطريقة فعالة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحافظ على النظام في شوارعنا ونحمي سلامة الناس من خلال احترام القوانين والتنظيمات العامة. البيع في الأماكن غير القانونية يعرض الجميع للمخاطر ويؤثر سلبًا على البيئة الحضرية.</p>
    `,
    category: ["street", "social", "public", "work"],
  },
  {
    id: 161,
    behavior: "الهرڭاوي هو لي كيحتل الملك العام.",
    solution: `
    <p>احتلال الملك العام هو تصرف غير قانوني يؤثر سلباً على النظام العام ويعرقل الحركة في الأماكن العامة. هذا التصرف يسبب اختناقاً مرورياً ويمنع المواطنين من الاستفادة من هذه الأماكن لأغراضهم اليومية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز الرقابة والمراقبة:</strong> يجب على السلطات المحلية تعزيز الرقابة على استخدام الملك العام والتأكد من أن الأنشطة تتم في الأماكن المخصصة لها فقط.</li>
      <li><strong>- تطبيق القوانين بصرامة:</strong> من الضروري تطبيق القوانين المتعلقة باحتلال الملك العام بشكل صارم وفرض عقوبات على المخالفين لضمان تنظيم استخدام المساحات العامة.</li>
      <li><strong>- إيجاد بدائل قانونية:</strong> يجب توفير أماكن مخصصة للاحتلال المؤقت للمساحات العامة مثل أسواق متنقلة أو محلات مؤقتة بدلاً من أن تتم هذه الأنشطة بشكل عشوائي.</li>
      <li><strong>- التوعية المجتمعية:</strong> من المهم زيادة الوعي لدى المواطنين بشأن أهمية احترام الملك العام وضرورة عدم التعدي عليه، وذلك من خلال حملات توعوية وحوافز للمخالفين للالتزام بالقوانين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>احتلال الملك العام يضر بالجميع. دعونا نحترم قوانيننا ونحافظ على نظامنا وراحتنا العامة من خلال التصرف وفقًا لما هو قانوني وآمن.</p>
    `,
    category: ["street", "social", "public", "work"],
  },
  {
    id: 162,
    behavior: "الهرڭاوي هو لي كيدفل فالأرض قدام الناس.",
    solution: `
    <p>القيام بأعمال تدني للأرض في الأماكن العامة هو سلوك غير لائق وغير حضاري، ويؤدي إلى تلوث البيئة ويشكل تهديدًا للصحة العامة. يجب أن يكون الحفاظ على النظافة العامة مسؤولية مشتركة بين الأفراد والمجتمع المحلي.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز التشريعات والمراقبة:</strong> من المهم أن تتخذ السلطات المحلية إجراءات قانونية واضحة ضد أي شخص يسيء استخدام الأماكن العامة من خلال الأفعال التي تضر بالبيئة.</li>
      <li><strong>- التوعية بأهمية الحفاظ على البيئة:</strong> حملات توعية من شأنها نشر الوعي بين المواطنين بأهمية الحفاظ على نظافة الأماكن العامة وتأثير التصرفات السلبية مثل الدفن في الأماكن العامة على الصحة والبيئة.</li>
      <li><strong>- توفير خدمات التنظيف والمرافق العامة:</strong> توفير حاويات القمامة في الأماكن العامة وضمان وجود خدمات تنظيف دورية يمكن أن يكون حلاً عملياً لتجنب تصرفات غير لائقة مثل الدفن في الأرض.</li>
      <li><strong>- فرض العقوبات:</strong> فرض غرامات على الأفراد الذين يقومون بتصرفات تؤذي البيئة وتهدد الصحة العامة لضمان التزامهم بالقوانين والأنظمة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>النظافة مسؤولية الجميع. لنحافظ على بيئتنا ونتصرف بشكل يضمن صحة وسلامة المجتمع.</p>
    `,
    category: ["street", "social", "public", "environment"],
  },
  {
    id: 163,
    behavior: "الهرڭاوي هو لي كيلصق العلكة تحت الطابلة فالمدرسة أو المطعم.",
    solution: `
    <p>تصرف لصق العلكة في الأماكن العامة مثل المدارس أو المطاعم هو سلوك غير لائق يعكر نظافة البيئة ويشكل مصدر إزعاج للآخرين. هذا السلوك قد يتسبب في صعوبة في تنظيف الأماكن العامة ويؤثر على جماليتها.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية والتوجيه:</strong> من المهم توعية الطلاب والمواطنين بشكل عام بخطورة هذا السلوك وتأثيره على البيئة من خلال برامج توعية مدرسية أو حملات في الأماكن العامة.</li>
      <li><strong>- تعزيز الأنظمة والمراقبة:</strong> يجب أن يكون هناك إشراف في الأماكن العامة مثل المدارس والمطاعم للحد من مثل هذه التصرفات من خلال الحضور المستمر للمشرفين والمراقبين.</li>
      <li><strong>- العقوبات الخفيفة:</strong> فرض غرامات بسيطة أو إجراءات تأديبية بسيطة في المدارس على الطلاب الذين يقومون بتصرفات غير لائقة مثل لصق العلكة في الأماكن العامة قد يكون حلاً فعالاً.</li>
      <li><strong>- توفير حاويات خاصة للعلكة:</strong> يجب أن تتوفر أماكن خاصة للتخلص من العلكة بشكل آمن ليتسنى للناس التخلص منها دون الإضرار بالمرافق العامة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>النظافة تبدأ من كل شخص. لنحافظ على بيئتنا ونساهم في جعل الأماكن العامة نظيفة وآمنة للجميع.</p>
    `,
    category: ["social", "public", "school", "health"],
  },
  {
    id: 164,
    behavior:
      "الهرڭاوي هو لي كيحط صاكو فالكرسي ديال الطوبيس باش مايڭلس حداه حتا واحد.",
    solution: `
    <p>وضع حقيبة أو أي شيء آخر على المقعد في وسائل النقل العامة من أجل منع الآخرين من الجلوس هو سلوك غير لائق وغير مريح. هذا التصرف يسبب الإحراج للركاب الآخرين ويؤدي إلى تقليل من استخدام المساحة المتاحة في وسائل النقل.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية والاحترام المتبادل:</strong> من الضروري نشر ثقافة الاحترام المتبادل في الأماكن العامة من خلال حملات توعية تشجع الناس على ترك مقاعدهم شاغرة للأشخاص المحتاجين.</li>
      <li><strong>- التعليم في المدارس والجامعات:</strong> يجب أن يتم تعليم الطلاب بشكل مبكر عن أهمية مراعاة الآخرين في الأماكن العامة بما في ذلك وسائل النقل.</li>
      <li><strong>- فرض القوانين والأنظمة:</strong> ينبغي أن تُفرض بعض الأنظمة أو القوانين التي تمنع هذا التصرف وتشجع الركاب على التفاعل بشكل إيجابي من خلال فرض غرامات على من يرتكب مثل هذه التصرفات.</li>
      <li><strong>- تشجيع الجلوس المشترك:</strong> تعزيز فكرة الجلوس المشترك في وسائل النقل العامة، مثل وضع إشارات أو لوحات توعوية تحث الناس على استخدام المقاعد بشكل منصف.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>في وسائل النقل العامة، كلنا شريك في توفير بيئة مريحة لجميع الركاب. دعونا نتعلم أن نكون أكثر احترامًا ومرونة.</p>
    `,
    category: ["social", "public"],
  },
  {
    id: 165,
    behavior: "الهرڭاوي هو لي كيولد الولاد و مكيربيهمش تربية حسنة.",
    solution: `
    <p>التربية السليمة للأطفال هي أساس بناء مجتمع قوي ومتوازن. الأطفال يحتاجون إلى رعاية واهتمام مناسبين من أجل أن يتطوروا في بيئة صحية نفسياً وعاطفياً. إذا كان الوالدين لا يقدمان تربية حسنة، فقد يتعرض الأطفال للعديد من المشاكل في حياتهم الاجتماعية والتعليمة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية الأسرية:</strong> من الضروري تنظيم حملات توعية للأسر حول أهمية التربية السليمة والدور الكبير الذي يلعبه الوالدان في تشكيل شخصية الطفل.</li>
      <li><strong>- الدعم النفسي:</strong> يجب توفير الدعم النفسي للأمهات والآباء، خاصة أولئك الذين يعانون من ضغوط الحياة اليومية، لضمان أنهم قادرون على تقديم بيئة آمنة وصحية للأطفال.</li>
      <li><strong>- التعليم التربوي:</strong> ينبغي تحسين التعليم التربوي في المدارس من خلال برامج تهدف إلى تعليم الآباء والمربين كيفية التعامل مع الأطفال بشكل صحيح وتوجيههم.</li>
      <li><strong>- البرامج الاجتماعية:</strong> من خلال تقديم الدعم الاجتماعي للأسر المعوزة أو التي تواجه صعوبات في تربية الأطفال، يمكن تعزيز التربية الجيدة في المجتمع.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التربية السليمة هي مفتاح المستقبل المشرق للأطفال. لنبدأ بتوفير بيئة دافئة ومحبة لهم، من أجل مجتمع قوي وأطفال قادرين على الإبداع والنمو بشكل صحيح.</p>
    `,
    category: ["social", "children", "school"],
  },
  {
    id: 166,
    behavior:
      "الهرڭاوي هو لي كيعطي التلفون للدراري الصغار باش يتهنا من صداعهم.",
    solution: `
    <p>من المهم أن يتعلم الأطفال كيف يعبئون أوقاتهم بشكل مفيد وآمن. إعطاء الهاتف للأطفال بشكل مستمر قد يؤدي إلى عدة مشاكل صحية وتربوية، مثل الإدمان على التكنولوجيا وفقدان المهارات الاجتماعية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تنظيم الوقت:</strong> يجب أن يتعلم الأطفال كيفية تنظيم وقتهم بين الأنشطة المختلفة مثل اللعب، والدراسة، والقراءة، والراحة. يجب تخصيص وقت معين لاستخدام التكنولوجيا بحيث لا يتعدى حدود المعقول.</li>
      <li><strong>- النشاطات البديلة:</strong> من الأفضل تقديم الأنشطة البديلة مثل الألعاب الإبداعية أو الأنشطة الرياضية التي تشغل الطفل وتحسن من نموه العقلي والجسدي.</li>
      <li><strong>- التوجيه الأسري:</strong> على الوالدين أن يكونوا قدوة في استخدام التكنولوجيا بشكل معتدل. يجب عليهم أن يحددوا قواعد واضحة بشأن وقت استخدام الهاتف ويشجعوا أطفالهم على الأنشطة الاجتماعية الحقيقية.</li>
      <li><strong>- التعليم التكنولوجي:</strong> إذا كان استخدام الهاتف لا مفر منه، فيجب أن يتعلم الأطفال كيفية استخدام التكنولوجيا بشكل إيجابي وآمن، مثل تعليمهم التطبيقات التعليمية أو الألعاب التي تحفز التفكير الإبداعي.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الهاتف ليس هو الحل لكل مشكلة يواجهها الطفل. من الضروري أن نعلم الأطفال كيفية التوازن بين استخدام التكنولوجيا والأنشطة الأخرى التي تساهم في نموهم الشخصي والاجتماعي.</p>
    `,
    category: ["home", "children"],
  },
  {
    id: 167,
    behavior: "الهرڭاوي هو لي كيمارس العنصرية على وسائل التواصل الإجتماعي.",
    solution: `
    <p>العنصرية على وسائل التواصل الاجتماعي تُعتبر مشكلة خطيرة تؤثر سلبًا على الأفراد والمجتمع ككل. من المهم أن نتعامل مع هذه المشكلة بجدية وأن نعمل على توعية الناس حول عواقب تصرفاتهم على الإنترنت.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية والتعليم:</strong> يجب أن يتم تعزيز ثقافة الاحترام والتسامح من خلال حملات توعية، سواء كانت في المدارس أو عبر الإنترنت. يجب أن نعلم الأفراد أن العنصرية تتسبب في أضرار اجتماعية ونفسية، وأنه لا مكان لها في المجتمع.</li>
      <li><strong>- تعزيز القوانين والمراقبة:</strong> يجب أن يكون هناك رقابة فعالة على منصات وسائل التواصل الاجتماعي لمكافحة خطابات الكراهية والعنصرية. يجب على الشركات المعنية بتوفير أدوات لتقارير الإساءات والتمييز.</li>
      <li><strong>- التعامل مع العنصرية بحزم:</strong> إذا تم اكتشاف أن شخصًا يمارس العنصرية على وسائل التواصل الاجتماعي، يجب اتخاذ الإجراءات القانونية المناسبة ضده. هذا يمكن أن يشمل حظر الحسابات أو اتخاذ إجراءات قانونية ضد الأفراد المسيئين.</li>
      <li><strong>- تشجيع الحوار الإيجابي:</strong> يجب تشجيع الأفراد على نشر محتوى إيجابي وتعليمي على وسائل التواصل الاجتماعي، مع التأكيد على أهمية التفاهم والتعاون بين مختلف الثقافات والعرقيات.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العنصرية على الإنترنت هي شكل من أشكال التمييز التي يمكن أن تؤذي الأفراد والمجتمع. من واجبنا جميعًا أن نكون جزءًا من الحل وأن نعمل معًا لخلق بيئة آمنة ومتسامحة عبر الإنترنت.</p>
    `,
    category: ["social", "digital"],
  },
  // {
  //   id: 168,
  //   behavior:
  //     "الهرڭاوي هو لي كيتحدث مع واحد آخر بلغة لي مكيفهماش الطرف الثالث.",
  //   solution: "---",
  //   category: ["social"],
  // },
  // {
  //   id: 169,
  //   behavior:
  //     "الهرڭاوي هو لي كتسيفط ليه ميساج كيشوفو و كيختار يجاوبك معطل بدون سبب غير باش يبان مهم.",
  //   solution: "---",
  //   category: ["social", "digital"],
  // },
  {
    id: 170,
    behavior: "الهرڭاوي هو لي كيمارس العنصرية ضد المهاجرين و كيعاملهم بدونية.",
    solution: `
    <p>العنصرية ضد المهاجرين تُعتبر من السلوكيات الضارة التي تؤدي إلى تفرقة اجتماعية وتدمير العلاقات الإنسانية. يجب مواجهة هذه الممارسات بشكل حازم والعمل على تعزيز قيم الاحترام والمساواة بين جميع الأفراد بغض النظر عن أصلهم أو خلفيتهم.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التثقيف والتوعية:</strong> يجب نشر الوعي بين الأفراد حول حقوق المهاجرين وأهمية احترام التنوع الثقافي. يمكن إجراء حملات توعية في المدارس، في وسائل الإعلام، والمجتمع لتعريف الناس بأهمية التعايش السلمي والمساواة.</li>
      <li><strong>- تشجيع القوانين المنصفة:</strong> ينبغي على الحكومات تطبيق وتطوير القوانين التي تحظر العنصرية ضد المهاجرين وتوفر لهم حقوقًا متساوية. يشمل ذلك الوصول إلى التعليم، الرعاية الصحية، والفرص الاقتصادية.</li>
      <li><strong>- تشجيع الحوار والتفاهم:</strong> من المهم فتح قنوات للحوار بين المجتمعات المحلية والمهاجرين لتعزيز التفاهم المتبادل والتعرف على الثقافات المختلفة. هذه المبادرات تساعد في التقليل من المشاعر السلبية وبناء مجتمع أكثر تنوعًا وتسامحًا.</li>
      <li><strong>- دعم المهاجرين في المجتمع:</strong> يجب دعم المهاجرين في عملية الاندماج في المجتمع من خلال توفير المساعدة القانونية، الاجتماعية، والتعليمية. هذا سيساعدهم في التكيف مع البيئة الجديدة والعيش بسلام دون تمييز.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>يجب أن نكون جميعًا جزءًا من الحل في محاربة العنصرية ضد المهاجرين. نحن بحاجة إلى مجتمع يحتفل بالتنوع ويعزز الاحترام المتبادل بين جميع أفراده.</p>
    `,
    category: ["social"],
  },
  {
    id: 171,
    behavior:
      "الهرڭاوي هو لي مكيراقبش الأطفال ديالو على مواقع التواصل الإجتماعي و كيخليهم يستهلكو أي حاجة.",
    solution: `
    <p>من الضروري أن يكون الآباء على دراية بما يستهلكه أطفالهم عبر الإنترنت، خاصة مع وجود العديد من المحتويات التي قد تكون غير مناسبة أو ضارة. الرقابة والتوجيه في هذا السياق هما السبيل لحماية الأطفال وضمان سلامتهم الرقمية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- مراقبة النشاط الرقمي:</strong> يجب على الآباء متابعة ما يفعله أطفالهم على مواقع التواصل الاجتماعي والإنترنت. يمكن استخدام أدوات التحكم الأبوي التي تسمح بتحديد أنواع المحتوى الذي يمكن الوصول إليه وكذلك مراقبة النشاطات الإلكترونية.</li>
      <li><strong>- التواصل المستمر:</strong> من المهم أن يتحدث الآباء مع أطفالهم بشكل مستمر عن تجربتهم على الإنترنت، وماذا يشاهدون أو يتفاعلون معه. هذا يساعد في بناء الثقة ويشجع الأطفال على التحدث عن أي محتوى قد يثير قلقهم.</li>
      <li><strong>- تعليم الأطفال السلامة الرقمية:</strong> يجب تعليم الأطفال أهمية حماية خصوصيتهم على الإنترنت، وكيفية التعامل مع الأشخاص والمحتويات غير المناسبة. يجب أن يعرفوا كيف يتصرفون في حال تعرضوا لمواقف غير مريحة أو خطرة عبر الإنترنت.</li>
      <li><strong>- وضع قواعد لاستخدام الإنترنت:</strong> تحديد أوقات معينة لاستخدام الإنترنت وتنظيم الأنشطة الرقمية يمكن أن يساعد في ضمان أن الأطفال لا يصبحون مفرطين في الاستخدام أو يتعرضون للمحتويات الضارة.</li>
      <li><strong>- تشجيع الأنشطة البديلة:</strong> يمكن للآباء تشجيع الأطفال على ممارسة الأنشطة البدنية أو الإبداعية بدلاً من قضاء وقت طويل على الإنترنت. هذا يساعد في تطوير مهاراتهم الاجتماعية والعقلية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الإنترنت عالم مليء بالفرص ولكن أيضًا بالمخاطر. دور الآباء هو توجيه أطفالهم نحو الاستخدام الآمن والصحي لهذه التكنولوجيا لضمان سلامتهم الرقمية وتطورهم الشخصي.</p>
    `,
    category: ["digital", "children", "home"],
  },
  // {
  //   id: 172,
  //   behavior: "الهرڭاوي هو لي كيضل النهار كامل فالقهوة حاضي عباد الله.",
  //   solution: "---",
  //   category: ["social"],
  // },
  {
    id: 173,
    behavior: "الهرڭاوي هو لي كيوصل الهضرة و كيكذب على الناس.",
    solution: `
    <p>الكذب هو سلوك غير أخلاقي يؤثر سلباً على العلاقات الإنسانية، سواء كانت في العمل أو في الحياة الاجتماعية. يخلق الكذب جواً من عدم الثقة ويعكر صفو التعاون والتفاهم بين الأشخاص. لذلك، يجب تجنب هذا السلوك والعمل على بناء علاقات قائمة على الصدق والمصداقية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوجيه والتوعية:</strong> من الضروري التوعية بأهمية الصدق في التعامل مع الآخرين. يمكن إجراء دورات تدريبية أو محاضرات تشجع على تعزيز الأخلاق الحميدة في بيئة العمل.</li>
      <li><strong>- التحدث بشفافية:</strong> يجب أن يسعى الشخص إلى التواصل بشكل صريح وصادق مع الآخرين. عند إبداء الرأي أو نقل المعلومات، ينبغي التأكد من صحتها وعدم نشر أي معلومات غير دقيقة.</li>
      <li><strong>- التحلي بالمسؤولية:</strong> عندما يتم التحدث أو نقل المعلومات، يجب أن يتحمل الشخص المسؤولية عن كلماته وتبعاتها. الكذب ليس فقط تضليلاً، بل يمكن أن يؤدي إلى فقدان الثقة والمصداقية.</li>
      <li><strong>- القدرة على الاعتراف بالخطأ:</strong> من الأفضل الاعتراف عندما يكون الشخص قد أخطأ أو لم يعرف شيئاً. الاعتراف بالأخطاء بدلاً من اختلاق الأكاذيب يساهم في تقوية العلاقات.</li>
      <li><strong>- التعامل مع الكذب بشفافية:</strong> في حالة اكتشاف الكذب، يجب التحدث مع الشخص المعني بشكل مباشر وفتح المجال لتوضيح الحقائق والالتزام بالتغييرات الإيجابية في المستقبل.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الصدق أساس العلاقات الناجحة، سواء في الحياة الشخصية أو المهنية. يجب أن يكون الكذب آخر خيار للتعامل مع المواقف، وعوضاً عنه يجب اختيار الصراحة والشفافية لبناء الثقة وتعزيز التعاون.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 174,
    behavior: "الهرڭاوي هو مول السناك لي مكيهتمش بالنظافة و كيبيع ماكلة موسخة.",
    solution: `
    <p>النظافة في محلات بيع الطعام ضرورية للحفاظ على صحة الزبائن ومنع انتشار الأمراض. الإهمال في هذا المجال يمكن أن يؤدي إلى تبعات صحية خطيرة، بالإضافة إلى التأثير على سمعة المحل. من المهم أن يتبع أصحاب المحلات معايير النظافة الصارمة لضمان سلامة العملاء.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التوعية بالمعايير الصحية:</strong> يجب على مالك السناك أن يكون على دراية بالقوانين المحلية المتعلقة بالنظافة والسلامة الغذائية. يمكن تقديم ورش عمل ودورات تدريبية للعاملين لتعليمهم كيفية الحفاظ على بيئة نظيفة وآمنة.</li>
      <li><strong>- إجراء فحوصات منتظمة:</strong> يجب القيام بزيارات تفتيشية دورية من قبل السلطات الصحية المحلية لضمان الامتثال للمعايير الصحية. هذه الفحوصات تضمن عدم وجود أي مخالفات تؤثر على صحة الزبائن.</li>
      <li><strong>- التخلص من الطعام الفاسد:</strong> يجب على مالك السناك أن يولي اهتماماً خاصاً للمواد الغذائية المنتهية الصلاحية أو غير المطابقة للمعايير. يجب التخلص من أي طعام يشتبه في أنه غير آمن للاستهلاك البشري.</li>
      <li><strong>- توفير بيئة عمل صحية:</strong> يجب أن يكون هناك نظام للتنظيف المنتظم لكافة الأدوات والمعدات في المحل، بما في ذلك الأسطح التي يتم التعامل معها بشكل متكرر، مثل الطاولات والمراحيض وأسطوانات الغاز والمواقد.</li>
      <li><strong>- التعامل مع الزبائن بنزاهة:</strong> يجب على مالك السناك أن يكون صريحاً مع الزبائن حول معايير السلامة المتبعة في محله، وأن يعمل على تحسين البيئة العامة وتوفير طعام آمن وجيد.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>النظافة ليست فقط من مسؤولية أصحاب المحلات، بل هي مسؤولية الجميع. يجب علينا جميعاً الالتزام بالمعايير الصحية لضمان بيئة صحية وآمنة للجميع.</p>
    `,
    category: ["social", "work", "environment", "health"],
  },
  {
    id: 175,
    behavior:
      "الهرڭاوي هو المسؤول لي موفرش الأرصفة فين يتمشاو الناس و كيخليهم يضطرو يتمشاو وسط الشانطي.",
    solution: `
    <p>الأرصفة هي الأماكن المخصصة للمشاة وهي جزء أساسي من البنية التحتية الحضرية لضمان سلامة المواطنين. عندما تكون الأرصفة غير متوفرة أو غير صالحة للاستخدام، يضطر الناس للمشي في الشوارع، مما يعرضهم للخطر بسبب مرور المركبات. من الضروري أن توفر السلطات المحلية أرصفة صالحة وآمنة للمشاة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تحسين البنية التحتية:</strong> يجب على المسؤولين المحليين العمل على توفير أرصفة واسعة وآمنة للمشاة في جميع الشوارع الرئيسية والفرعية. تحسين هذه البنية التحتية سيساهم في تقليل المخاطر على صحة وسلامة المواطنين.</li>
      <li><strong>- التفتيش والمراقبة:</strong> من المهم أن تقوم السلطات المحلية بمتابعة تنفيذ قوانين تخطيط المدن ومراقبة أعمال البناء لضمان توفر الأرصفة بشكل مستمر وفي الأماكن المناسبة.</li>
      <li><strong>- التعاون مع المجتمع المحلي:</strong> يجب تشجيع المجتمع المحلي على التواصل مع المسؤولين عند وجود أي مشاكل في الأرصفة أو أماكن المشاة. يمكن أيضاً تنظيم حملات توعية لأهمية الأرصفة وكيفية استخدامها بشكل صحيح.</li>
      <li><strong>- الاهتمام بتصميم المدن:</strong> عند التخطيط العمراني، يجب الأخذ بعين الاعتبار توفير مساحات للمشاة بعيداً عن حركة المرور. يمكن تصميم الأرصفة بشكل يسمح للناس بالمشي بشكل مريح وآمن.</li>
      <li><strong>- الاعتناء بصيانة الأرصفة:</strong> يجب ضمان الصيانة الدورية للأرصفة للتأكد من خلوها من العوائق أو الأضرار التي قد تؤدي إلى حوادث. الصيانة المستمرة تساهم في توفير بيئة آمنة للمشاة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التزام المسؤولين بتوفير أرصفة صالحة وآمنة هو جزء من مسؤوليتهم تجاه المجتمع. علينا جميعاً العمل سوياً لضمان بيئة آمنة وصحية للجميع.</p>
    `,
    category: ["street", "public", "driving"],
  },
  {
    id: 176,
    behavior: "الهرڭاوي هو لي كيخلي على الرصيف و كيتمشا وسط الشانطي.",
    solution: `
    <p>المشي على الرصيف هو حق أساسي للمشاة لضمان سلامتهم، ويجب أن يكون هذا المكان مخصصاً فقط للمشاة دون وجود أي عوائق. عندما يقوم الأفراد بالتمشي في الشوارع المخصصة للمركبات، فإنهم يعرضون أنفسهم وغيرهم من المارة للخطر. من الضروري أن تفرض السلطات المحلية القوانين والأنظمة لضمان تخصيص الأماكن المناسبة لكل نوع من أنواع التنقل.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز الوعي:</strong> يجب توعية المواطنين بأهمية احترام الأماكن المخصصة للمشاة. يمكن تنظيم حملات توعية تشمل المدارس والجامعات وأماكن العمل لتوضيح المخاطر التي قد تحدث عند المشي في الشوارع بدلاً من الأرصفة.</li>
      <li><strong>- فرض قوانين السير بصرامة:</strong> من الضروري تطبيق قوانين السير بشكل دقيق، مثل فرض غرامات على الأشخاص الذين لا يلتزمون بالمشي على الرصيف. هذا يساعد في تحسين السلامة العامة.</li>
      <li><strong>- تحسين جودة الأرصفة:</strong> يجب على السلطات المحلية ضمان توفير أرصفة في حالة جيدة وخالية من العوائق. إذا كانت الأرصفة ضيقة أو غير مريحة للمشي، قد يضطر البعض للتمشي في الشارع.</li>
      <li><strong>- التشديد على المسؤولين المحليين:</strong> يجب على المسؤولين المحليين تطوير خطط لتوسيع الأرصفة في الأماكن التي تشهد اكتظاظاً مروريًا، وتحسين الوصول إليها، وذلك لضمان أن يكون المشي في المناطق العامة أكثر أماناً وراحة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>المشي في الشوارع المخصصة للمركبات يشكل خطراً على سلامتنا. فلنعمل جميعاً على احترام الأماكن المخصصة للمشاة لتفادي الحوادث وضمان بيئة أكثر أماناً لجميع المواطنين.</p>
    `,
    category: ["street", "public", "driving"],
  },
  {
    id: 177,
    behavior: "الهرڭاوي هو لي مكيعاونش مالين الدار فأشغال البيت.",
    solution: `
    <p>التعاون داخل الأسرة هو أساس الحياة المشتركة والسعيدة. مشاركة الجميع في أشغال المنزل تساهم في تعزيز العلاقة بين أفراد الأسرة وتساعد على تقاسم المسؤوليات. عندما يرفض أحد أفراد الأسرة المساعدة في الأعمال المنزلية، فإنه يسبب عبئاً إضافياً على الآخرين، مما يؤدي إلى توترات قد تؤثر على جو الأسرة بشكل عام.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تعزيز ثقافة التعاون:</strong> يجب أن يتم غرس ثقافة التعاون في المنزل منذ سن مبكرة. إذا كان الأطفال يشاهدون الوالدين يشاركون في الأعمال المنزلية، سيتعلمون بدورهم أهمية التعاون.</li>
      <li><strong>- تقسيم المهام:</strong> من الأفضل تقسيم الأعمال المنزلية بشكل عادل بين جميع أفراد الأسرة. إذا كانت هناك مهام صعبة أو مرهقة، يجب أن يتم التعاون في أدائها لتخفيف العبء على شخص واحد.</li>
      <li><strong>- الحوار والتفاهم:</strong> يجب أن يتحدث أفراد الأسرة عن أهمية المشاركة في الأعمال المنزلية. قد يعتقد البعض أن المساعدة في المنزل ليست ضرورية أو لا تعود عليهم بفائدة، لكن الحوار يمكن أن يوضح لهم أهمية المساهمة في بناء بيئة منزلية صحية ومتوازنة.</li>
      <li><strong>- تحديد الأدوار بوضوح:</strong> يجب أن يكون هناك فهم واضح لدور كل فرد في الأسرة بالنسبة للأعمال المنزلية. يمكن تحديد مهام كل شخص بشكل منتظم بحيث يشعر الجميع بالمسؤولية.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>التعاون داخل الأسرة يقوي الروابط بين الأفراد ويخلق بيئة أكثر انسجاماً. فلنتعاون جميعاً في الأعمال المنزلية لنعيش معاً في جو من التفاهم والمحبة.</p>
    `,
    category: ["home"],
  },
  {
    id: 178,
    behavior: "الهرڭاوي هو الأب لي مكيهتمش بولادو و مكيعطيهومش الوقت الكافي.",
    solution: `
    <p>الوقت الذي يقضيه الأب مع أولاده له تأثير كبير على نموهم العاطفي والفكري. الأب الذي لا يهتم بأبنائه أو لا يقضي وقتاً كافياً معهم قد يتسبب في شعورهم بالإهمال وعدم الأمان، مما قد يؤدي إلى مشكلات نفسية أو سلوكية. من المهم أن يكون للأب دور فعال في حياة أبنائه من خلال تخصيص وقت لهم واستماع لمشاكلهم واهتماماتهم.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- تخصيص وقت يومي:</strong> يجب على الأب تخصيص وقت يومي أو أسبوعي للجلوس مع أولاده، سواء لممارسة أنشطة مشتركة مثل اللعب أو الحوار أو حتى الخروج معاً. هذا الوقت يعزز العلاقة بينهم ويشعرهم بالاهتمام والرعاية.</li>
      <li><strong>- التفاعل والمشاركة:</strong> التفاعل مع الأطفال لا يقتصر على تقديم المساعدة في الواجبات المدرسية فقط، بل يشمل التفاعل العاطفي مثل التعبير عن الحب والاهتمام بهم. هذا التفاعل يقوي من ثقته في نفسه ويعزز لديه شعور الانتماء.</li>
      <li><strong>- التواصل الفعّال:</strong> يجب أن يكون هناك تواصل مفتوح بين الأب وأبنائه. الاستماع لهم ومشاركة الآراء والأفكار يجعل الأطفال يشعرون بأنهم جزء من العائلة وأن رأيهم مهم.</li>
      <li><strong>- إعطاء الأولوية للأسرة:</strong> في ظل الحياة اليومية المزدحمة، من الضروري أن يولي الأب الأولوية للأسرة وأن يعزز من وجوده بجانب أولاده. قضاء وقت ممتع مع العائلة يساهم في تحسين العلاقات داخل الأسرة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>أن تكون أباً حاضراً في حياة أولادك ليس مجرد واجب، بل هو مسؤولية تساهم في بناء شخصية متوازنة ومستقرة للأطفال. فلنخصص وقتاً أكثر لأبنائنا ونعزز من علاقتنا بهم ليشعروا بالحب والدعم.</p>
    `,
    category: ["home", "children"],
  },
  {
    id: 179,
    behavior: "الهرڭاوي هو لي كيمضغ الماكلة و فمو محلول.",
    solution: `
    <p>تناول الطعام بطريقة غير لائقة قد يسبب انطباعاً سلبياً لدى الآخرين، كما قد يساهم في زيادة فرص انتشار الجراثيم أو الميكروبات من خلال الفم المفتوح أثناء الأكل. الحفاظ على آداب الطعام من أساسيات السلوك الاجتماعي التي تعكس الاحترام للآخرين وللبيئة المحيطة. تعتبر هذه العادة غير صحية وتستدعي الانتباه لضمان راحة الآخرين والحفاظ على صحتنا الشخصية.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- إغلاق الفم أثناء المضغ:</strong> من الضروري إغلاق الفم أثناء تناول الطعام لتجنب إظهار الطعام داخل الفم. هذه العادة تعكس الاحترام للآخرين وتجنب المواقف المحرجة.</li>
      <li><strong>- مضغ الطعام بشكل صحيح:</strong> من الأفضل مضغ الطعام ببطء وبشكل جيد. هذا لا يساعد فقط في الهضم، بل يقلل أيضاً من فرص التحدث أثناء الأكل والتسبب في عدم راحة الآخرين.</li>
      <li><strong>- التعليم والتوجيه للأطفال:</strong> إذا كان هذا السلوك يحدث من قبل الأطفال، فيجب أن يتم تعليمهم كيفية تناول الطعام بشكل لائق منذ الصغر. يجب أن يتعلم الأطفال أن إغلاق الفم أثناء المضغ هو من أساسيات آداب الطعام.</li>
      <li><strong>- ممارسة السلوك في الأماكن العامة:</strong> يجب أن نحرص على تطبيق آداب الطعام في الأماكن العامة مثل المطاعم أو أي مكان يتجمع فيه الناس. هذه الممارسات تعكس احترامنا للآخرين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>لنحرص على أن نكون قدوة حسنة للآخرين في كيفية تناول الطعام بشكل لائق وآمن. السلوك الجيد في تناول الطعام يعكس شخصيتنا ويعزز من راحتنا الاجتماعية مع الآخرين.</p>
    `,
    category: ["social", "public", "home"],
  },
  {
    id: 180,
    behavior: "الهرڭاوي هو لي كيستعمل الهاتف أثناء السياقة.",
    solution: `
    <p>استخدام الهاتف أثناء القيادة يعد من أخطر السلوكيات التي قد يتسبب في حوادث مرورية خطيرة. قد يؤدي التركيز على الهاتف بدلاً من الطريق إلى فقدان السيطرة على السيارة، مما يعرض حياة السائق والركاب والمشاة للخطر. لذلك من المهم جداً اتخاذ إجراءات للحد من هذه العادة.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- استخدام تقنيات الأمان:</strong> من الأفضل استخدام الأنظمة التي توفر القيادة بدون يديك مثل البلوتوث أو خاصية الصوت للتحكم بالهاتف. هذه الوسائل تساعد على الحفاظ على تركيز السائق على الطريق.</li>
      <li><strong>- التوقف في مكان آمن:</strong> إذا كان من الضروري استخدام الهاتف، يجب على السائق التوقف في مكان آمن بعيداً عن حركة المرور قبل إجراء المكالمات أو إرسال الرسائل.</li>
      <li><strong>- إخبار الركاب بأهمية التركيز:</strong> من المهم أن يتعاون جميع الركاب مع السائق لضمان أنه لا يتم تشتيت انتباهه. يمكن للركاب أيضاً المساعدة في الإجابة على المكالمات أو إرسال الرسائل إذا كان السائق في حاجة ماسة لذلك.</li>
      <li><strong>- التوعية والتثقيف:</strong> يجب نشر الوعي حول خطر استخدام الهاتف أثناء القيادة في المجتمع. يمكن تنظيم حملات توعية أو برامج تدريبية للسائقين حول كيفية الحفاظ على سلامتهم وسلامة الآخرين.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>القيادة الآمنة تبدأ بتركيز السائق على الطريق. دعونا نتجنب استخدام الهواتف أثناء القيادة من أجل سلامتنا وسلامة الجميع على الطريق.</p>
    `,
    category: ["driving"],
  },
  // {
  //   id: 181,
  //   behavior: "الهرڭاوي هو لي ديما مغوبش و مكيبتاسمش فوجوه الناس.",
  //   solution: "---",
  //   category: ["social"],
  // },
  {
    id: 182,
    behavior: "الهرڭاوي هو لي كيعنف ولادو.",
    solution: `
    <p>تعنيف الأطفال يعد من السلوكيات الضارة التي تترك آثارًا نفسية وجسدية سلبية على الأطفال قد تستمر طوال حياتهم. يمكن أن يؤدي العنف إلى تدمير الثقة بالنفس لدى الطفل ويزيد من احتمالية تعرضه لسلوكيات عدوانية أو مشاكل اجتماعية في المستقبل. من الأهمية بمكان أن يجد الأهل أساليب تربوية بديلة أكثر فاعلية وأمانًا.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التواصل الفعّال:</strong> يجب على الآباء والأمهات أن يتعلموا كيفية التعامل مع أطفالهم من خلال الحوار والنقاش بدلاً من العنف. عندما يظهر الطفل سلوكًا غير لائق، يمكن توجيههم بشكل إيجابي باستخدام كلمات هادئة.</li>
      <li><strong>- استخدام أساليب تربوية بديلة:</strong> يمكن اللجوء إلى أساليب مثل تحديد قواعد واضحة، ومكافأة السلوك الجيد، وفرض عقوبات معتدلة وغير مؤذية عند الضرورة.</li>
      <li><strong>- الاهتمام بالصحة النفسية للوالدين:</strong> أحيانًا يكون العنف ناتجًا عن ضغوط نفسية أو اجتماعية. يمكن للآباء والأمهات البحث عن طرق للتخفيف من التوتر مثل الاسترخاء أو استشارة مختص في الصحة النفسية إذا كان ذلك ضروريًا.</li>
      <li><strong>- التعليم والتدريب:</strong> يمكن للآباء المشاركة في ورش عمل أو دورات تدريبية حول التربية الإيجابية والتعامل مع تحديات الأبوة والأمومة بشكل صحي وآمن.</li>
      <li><strong>- التدخل الاجتماعي:</strong> في حال كانت المشكلة مستمرة أو خطيرة، يجب على الأهل طلب المساعدة من المؤسسات الاجتماعية أو المتخصصين للتعامل مع حالات العنف الأسري بشكل سريع وآمن.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العنف ليس هو الحل. التربية بالحب والاحترام تبني جيلًا سليمًا نفسيًا وعاطفيًا. لنكن قدوة حسنة لأطفالنا ونحترم حقوقهم في بيئة آمنة ومحبة.</p>
    `,
    category: ["home", "children"],
  },
  {
    id: 183,
    behavior: "الهرڭاوي هو لي كيدخل عليك للبيت بدون استئذان.",
    solution: `
    <p>الاحترام المتبادل في العلاقات الشخصية يتطلب أن نلتزم بقواعد الخصوصية، بما في ذلك احترام حدود الآخرين وطلب الإذن قبل الدخول إلى أماكنهم الخاصة. عند تجاهل هذه القواعد، قد يتسبب ذلك في شعور الآخر بالانزعاج أو التعدي على خصوصيته.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التأكيد على احترام الخصوصية:</strong> يجب أن يتعلم الجميع احترام خصوصية الآخرين وعدم الدخول إلى أماكنهم الخاصة بدون إذن مسبق. حتى في العلاقات المقربة، احترام الحدود هو أمر أساسي.</li>
      <li><strong>- التواصل الواضح:</strong> يمكن للأشخاص المعنيين أن يوضحوا بصدق واحترام شعورهم بعدم الراحة إذا كان هناك انتهاك للخصوصية. التواصل الواضح يمكن أن يساعد في منع أي سوء تفاهم.</li>
      <li><strong>- وضع قواعد واضحة:</strong> في حالة كان الأمر متعلقًا بمسكن مشترك أو مكان محدد، يمكن وضع قواعد واضحة تتعلق بالدخول والخروج دون الحاجة لطلب الإذن كل مرة.</li>
      <li><strong>- التعليم والتوعية:</strong> من المهم تعليم الأفراد (وخاصة الأطفال أو الشباب) ضرورة احترام خصوصية الآخرين وكيفية التعامل مع مواقف كهذه بأدب ولباقة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>الاحترام يبدأ من الحفاظ على حدود الآخرين وطلب الإذن. دعونا نتعامل مع خصوصيات بعضنا البعض بحذر وتقدير.</p>
    `,
    category: ["home"],
  },
  {
    id: 184,
    behavior: "الهرڭاوي هو لي كيدخل لإدارة عمومية و مكيطفيش التليفون.",
    solution: `
    <p>الاحترام في أماكن العمل والإدارات العمومية يشمل العديد من السلوكيات المهنية، وأحد هذه السلوكيات هو التأكد من عدم إزعاج الآخرين أثناء أدائهم لمهامهم. من الأمور غير المقبولة أن يتسبب شخص في إزعاج أو تعطيل سير العمل بسبب عدم إيقاف الهاتف.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التأكيد على أهمية الإلتزام بالآداب العامة:</strong> يجب على الموظفين والمواطنين في الإدارات العمومية مراعاة آداب العمل والتعامل مع الأجهزة الشخصية مثل الهواتف المحمولة بشكل مسؤول.</li>
      <li><strong>- إيقاف الهاتف:</strong> من الأفضل إيقاف الهاتف أو وضعه على الوضع الصامت عند الدخول إلى أماكن العمل أو الإدارات العمومية لتجنب أي انزعاج أو تشتت في العمل.</li>
      <li><strong>- التوعية بالإجراءات الصحيحة:</strong> يمكن للإدارات العامة توفير لافتات أو إشعارات تذكّر الأفراد بقواعد التصرف المناسبة في مكان العمل، مثل إيقاف الهاتف.</li>
      <li><strong>- التشجيع على العمل الجماعي:</strong> تعزيز بيئة العمل الجماعي من خلال احترام الآخرين والتركيز على العمل المشترك يساهم في تحسين الأداء العام.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>العمل الجماعي الفعّال يبدأ من احترام بيئة العمل والالتزام بالقوانين، مثل إيقاف الهواتف الشخصية في الأماكن العامة والمهنية.</p>
    `,
    category: ["public", "social", "work", "school"],
  },
  {
    id: 185,
    behavior: "الهرڭاوي هو لي مكيلتازمش بالمواعيد.",
    solution: `
    <p>عدم الالتزام بالمواعيد يعد من السلوكيات التي تؤثر سلبًا على سير العمل والعلاقات الاجتماعية. المواعيد هي عامل أساسي لضمان التعاون الجيد والاحترام المتبادل بين الأفراد في بيئات العمل والمجتمعات.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- إدراك أهمية الوقت:</strong> يجب على الجميع فهم أن احترام المواعيد هو عامل حاسم في تحسين الإنتاجية وتعزيز العلاقات الجيدة بين الأفراد.</li>
      <li><strong>- التخطيط المسبق:</strong> من الأفضل دائمًا التخطيط مسبقًا لضمان الوصول في الوقت المحدد، حتى إذا كان هناك ظروف غير متوقعة قد تؤخر الشخص.</li>
      <li><strong>- الاعتذار عند التأخير:</strong> إذا تأخر الشخص عن الموعد المحدد، يجب عليه الاعتذار بشكل صادق وواضح وإيجاد حل سريع للمشكلة.</li>
      <li><strong>- التنظيم:</strong> يمكن استخدام أدوات تنظيم الوقت مثل التقويمات أو التذكيرات عبر الهاتف لضمان التزام الجميع بالمواعيد المحددة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>احترام المواعيد هو مفتاح النجاح في العمل والحياة الاجتماعية. التعاون الفعّال يبدأ من التزام الجميع بالمواعيد المحددة.</p>
    `,
    category: ["social", "work"],
  },
  {
    id: 186,
    behavior:
      "الهرڭاوي هو لي كينشر الإشاعات و الأخبار الكاذبة على مواقع التواصل بدون مايتحقق من صحة الخبر.",
    solution: `
    <p>نشر الإشاعات والأخبار الكاذبة على مواقع التواصل الاجتماعي هو سلوك ضار يؤثر على مصداقية المعلومات ويخلق جوًا من الفوضى والبلبلة. في عصر المعلومات، من الضروري أن نكون حذرين ونحقق من صحة الأخبار قبل نشرها.</p>
    <br>
    <h3><strong>الحل:</strong></h3>
    <ul>
      <li><strong>- التأكد من مصادر الأخبار:</strong> يجب على الأشخاص التحقق من مصدر الخبر قبل نشره، سواء عبر المواقع الرسمية أو المصادر الموثوقة.</li>
      <li><strong>- التفكير النقدي:</strong> يجب أن يستخدم الشخص التفكير النقدي للتحقق من المعلومات قبل مشاركتها. إذا كان الخبر غير موثوق أو يثير الشكوك، يجب الامتناع عن نشره.</li>
      <li><strong>- استخدام منصات التحقق:</strong> يمكن الاستفادة من منصات التحقق من الأخبار على الإنترنت التي تقدم أدوات للتحقق من صحة الأخبار والمعلومات المنتشرة.</li>
      <li><strong>- التحقق من التاريخ:</strong> تأكد من أن الخبر ليس قديمًا أو تم تحريفه في وقت لاحق. الأخبار قد تُستخدم بشكل خاطئ لإحداث تأثيرات معينة.</li>
    </ul>
    <br>
    <h3><strong>الرسالة التوعوية:</strong></h3>
    <p>نشر الأخبار الكاذبة يضر بالجميع. لنحترم عقولنا ومجتمعاتنا من خلال التأكد من صحة المعلومات قبل أن نكون جزءًا من نشر الشائعات.</p>
    `,
    category: ["social", "digital"],
  },
  // {
  //   id: 187,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 188,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 189,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 190,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 191,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 192,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 193,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 194,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 195,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 196,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 197,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 198,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 199,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
  // {
  //   id: 200,
  //   behavior: "",
  //   solution: "---",
  //   category: [""],
  // },
];
